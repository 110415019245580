import {IProjectRepository} from "../../domain/project/ProjectRepository";
import {Project} from "../../domain/project/Project";
import {User} from "../../domain/user/User";
import {CurrentUser} from "../../usecase/auth/CurrentUser";

export class ProjectRepository implements IProjectRepository {
    currentUser: CurrentUser;

    constructor(currentUser: CurrentUser) {
        this.currentUser = currentUser;
    }


    list (): Promise<Project[]> {
        return Promise.resolve([]);
    };

    add(project: Project): Promise<string> {
        const user = this.currentUser.getCurrentUser();

        if (user) {
            const data = JSON.stringify({
                title: project.title,
                description: project.description,
                owner: project.owner.name
            });
            const url = "/api/project/create";

            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => response.json()).then(json => {
                return JSON.stringify(json);
            })
        }
        return Promise.resolve("failed to add a project");
    }

    getById(owner: User, projectId: number): Promise<Project | undefined> {
        const user = this.currentUser.getCurrentUser();

        if (user) {
            const data = JSON.stringify({
                owner: owner.name,
                project_id: projectId
            });
            const url = "/api/project/get_by_id";

            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + user.token,
                },
                body: data
            }).then(response => response.json()).then(json => {
                const p = json["project"];
                console.log(p);
                return new Project(p.project_id, p.title, owner, p.description);
            })
        }

        return Promise.resolve(undefined);
    }

    getByOwner(owner: User): Promise<Project[] | undefined> {
        const user = this.currentUser.getCurrentUser();

        if (user) {
            const data = JSON.stringify({
                owner: owner.name
            });
            const url = "/api/project/list_by_owner"

            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => response.json()).then(json => {
                const projectData = json["projects"];
                console.log(projectData);
                return projectData.map((p: any) => new Project(p.project_id, p.title, owner, p.description))
            })
        }
        return Promise.resolve([]);
    }
}