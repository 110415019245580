import {BinaryQuestion} from "../../../domain/blueprint/item/question/BinaryQuestion";
import React from "react";

export function BinaryQuestionCard ({title, options, body}: BinaryQuestion) {
    return (
        <>
            <div className="max-w-sm rounded overflow-hidden shadow-lg mb-4 justify-self-center">
                <div className="px-6 pt-4">
                    <div className="font-bold text-md mb-2">{title}</div>
                    {body ?
                        <p className="text-gray-700 text-md">
                            {body}
                        </p> : ''}
                </div>
                <div className="px-2 pt-4 pb-6">
                    <span className='px-4'>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                                focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-1
                                bg-gray-200 hover:bg-gray-300 text-white font-bold py-2 rounded shadow-sm
                                hover:shadow-lg transition duration-200"
                        >

                            <span className="text-sm font-semibold tracking-wide text-gray-500">
                                {options[0]}
                            </span>

                        </button>
                    </span>
                    <span>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                                focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-1
                                bg-gray-200 hover:bg-gray-300 text-white font-bold py-2 rounded shadow-sm
                                hover:shadow-lg transition duration-200"
                        >

                            <span className="text-sm font-semibold tracking-wide text-gray-500">
                                {options[1]}
                            </span>

                        </button>
                    </span>
                </div>
            </div>
        </>
    )
}