import React from "react";
import {BlueprintTypeId, BlueprintTypes, IBlueprintType} from "../../../../../domain/blueprint/Blueprint";

export interface IBlueprintTableRowProp {
    key: number;
    amtHITID: string;
    batchTitle: string;
    batchDescription: string;
    blueprintTitle: string;
    blueprintTypeId: BlueprintTypeId;
    createdBy: string;
    environment: string;
    link: string;
}

export function BatchTableRow ({amtHITID,batchTitle, batchDescription, blueprintTitle, blueprintTypeId, createdBy, environment, link}: IBlueprintTableRowProp) {
    const type: IBlueprintType | undefined = BlueprintTypes.find((o) => o.typeId === blueprintTypeId);

    return (
        <tr className='bg-gray-100 border-b'>
            <td className="text-left text-sm text-sky-600 underline decoration-solid font-light px-6 py-4 whitespace-nowrap">
                <a href={link} target="_blank" rel="noopener noreferrer">{amtHITID}</a>
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {batchTitle}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {batchDescription}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {blueprintTitle}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {type?.name!}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {createdBy}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {environment}
            </td>
            <td className="text-left text-sm text-sky-600 font-light px-6 py-4 whitespace-nowrap">
                <a href={link} target="_blank" rel="noopener noreferrer">Open the task interface</a>
            </td>
        </tr>
    )
}