import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {GlobalContext, IGlobalContext} from "../../../App";

export function ChangePasswd() {
    const {authenticationService, currentUser} = React.useContext(GlobalContext) as IGlobalContext;

    let navigate = useNavigate();

    const [current_pw, setCurrent_pw] = useState("");
    const [new_pw, setNew_pw] = useState("");
    const [retype_new_pw, setRetype_new_pw] = useState("");

    function onPasswdChangeClick (event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        //  Check if user entered properly.
        if (current_pw==="" && new_pw==="" && retype_new_pw==="") {
            alert("Please ensure current password and new password is entered!")
        }
        else {
            //  Verify if the password is indeed correct as user intended.
            if (new_pw===retype_new_pw) {
                //alert("New password and re-type password is same!")

                const promise = authenticationService.change_pw(current_pw, new_pw, currentUser);

                promise.then(response => {
                    console.log(response);
                    if (response["Status"] === 'Success') {
                        console.log("Success")
                        //  Divert to Signin Page after registering.
                        navigate('/signin')
                    } else {
                        console.log("Fail")
                        //  Divert back to sign up page.
                        navigate('/signup')
                    }
                })
            }
            else {
                alert("New password and re-type password is not the same!")
            }
        }
    }

    function onGoBackClick (event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        //  Go back to project list page.
        navigate('/projects')
    }

    return (
        <>
            <div className='bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-xl'>
                <section>
                    <h1 className='font-bold text-xl text-gray-600 pt-2'>Change Password</h1>
                </section>

                <section className='mt-10'>
                    <form className='flex flex-col text-left'>
                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="email">Current Password</label>
                            <input
                                type='password'
                                id='current_pw'
                                value={current_pw}
                                onChange={(e) => setCurrent_pw(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>

                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="email">New Password</label>
                            <input
                                type='password'
                                id='new_pw'
                                value={new_pw}
                                onChange={(e) => setNew_pw(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>

                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="password">Re-type New Password</label>
                            <input
                                type='password'
                                id='retype_new_pw'
                                value={retype_new_pw}
                                onChange={(e) => setRetype_new_pw(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>

                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                            type="submit"
                            onClick={onPasswdChangeClick}>
                            Change Password
                        </button>

                        <div className="px-1 py-1"></div>

                        <button
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                            type="submit"
                            onClick={onGoBackClick}>
                            Go Back
                        </button>
                    </form>
                </section>
            </div>
        </>
    )
}