import {HITBatchReport} from "../../domain/mturk/HITBatchReport";
import {deserializeHITBatch} from "./HITBatchAdapter";

export function deserializeHITBatchReport(hitBatchReportObject: any): HITBatchReport {
    const batch = deserializeHITBatch(hitBatchReportObject.hit_batch);

    const projectId = batch.blueprint.projectId;
    const batchId = batch.batchId;
    const blueprintId = batch.blueprint.id;
    const title = batch.taskAttributes.title;
    const summary = batch.taskAttributes.description;

    //  Original
    //return new HITBatchReport(projectId, batchId, blueprintId, title, summary, batch.hits[0].production);

    //  Check Hit Length: Just-in-case hits is zero.
    return new HITBatchReport(projectId, batchId, blueprintId, title, summary, batch.hits.length>0 ? (batch.hits[0].production) : false);

}