import React, {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";

export interface IEnterKeyModalProperties {
    title: string;
    handleSubmitClick: (accessKey: string, secretKey: string) => Promise<string>;
}

export function EnterKeyModal ({title, handleSubmitClick}: IEnterKeyModalProperties) {
    let [isOpen, setIsOpen] = useState(false);

    const [accessKey, setAccessKey] = useState<string>("");
    const [secretKey, setSecretKey] = useState<string>("");

    function openModal() {
        setIsOpen(true);
    }

    function closeModal () {
        setIsOpen(false);
    }

    function handleAccessKeyChange (e: React.ChangeEvent<HTMLInputElement>) {
        setAccessKey(e.target.value);
    }

    function handleSecretKeyChange (e: React.ChangeEvent<HTMLInputElement>) {
        setSecretKey(e.target.value);
    }

    async function clickSubmit () {
        await handleSubmitClick(accessKey, secretKey);
        closeModal();
    }

    return (
        <>
            <div className="inline">
                <button
                    type='button'
                    onClick={openModal}
                    className="inline px-6 rounded-md
                    {/*focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1*/}
                    bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                    hover:shadow-xl transition duration-200
                    "
                >
                    <span className="text-sm font-semibold tracking-wide">Sync</span>

                </button>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div'
                        className='fixed inset-0 z-10 overflow-y-auto'
                        open={isOpen}
                        onClose={closeModal}>
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child as={Fragment}
                                          enter='ease-out duration-300'
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0">
                            <Dialog.Overlay className='fixed inset-0 bg-black opacity-30'/>
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="h3"
                                              className="text-lg font-medium leading-6 text-gray-900">
                                    {title}
                                </Dialog.Title>
                                <div className="mt-2">
                                    <div className="text-sm text-gray-500">
                                        <form autoComplete="on">
                                            <div className='form-group mb-6'>
                                                <label htmlFor='amt-access-key'>Access Key</label>
                                                <input
                                                    type='username'
                                                    id='amt-access-key'
                                                    value={accessKey}
                                                    onChange={handleAccessKeyChange}
                                                    autoComplete="on"
                                                    className="form-control block w-full px-3 py-1.5 text-base
                                                        font-normal text-gray-700 bg-white bg-clip-padding
                                                        border border-solid border-gray-300 rounded
                                                        transition ease-in-out m-0 focus:text-gray-700
                                                        focus:bg-white focus:border-blue-600 focus:outline-none" />
                                            </div>
                                            <div className='form-group mb-6'>
                                                <label htmlFor='amt-secret-key'>Secret Key</label>
                                                <input
                                                    type='password'
                                                    id='amt-secret-key'
                                                    value={secretKey}
                                                    onChange={handleSecretKeyChange}
                                                    autoComplete="on"
                                                    className="form-control block w-full px-3 py-1.5 text-base
                                                        font-normal text-gray-700 bg-white bg-clip-padding
                                                        border border-solid border-gray-300 rounded
                                                        transition ease-in-out m-0 focus:text-gray-700
                                                        focus:bg-white focus:border-blue-600 focus:outline-none" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={clickSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}