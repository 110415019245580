import {Link} from "react-router-dom";
import React from "react";
import {GlobalContext, IGlobalContext} from "../../../App";

export interface IProjectTableRowProp {
    key: number;
    id: number;
    title: string;
    description: string;
    createdBy: string;
}

export function ProjectTableRow ({id, title, description, createdBy}: IProjectTableRowProp) {
    const {currentUser, projectRepository, setCurrentProject} = React.useContext(GlobalContext) as IGlobalContext;

    function handleOnClick (projectId: number) {
        const user = currentUser.getCurrentUser()
        if (user) {
            projectRepository.getById(user, projectId).then(response => {
                if (response) {
                    setCurrentProject(response)
                }
            })
        }
    }

    return (
        <tr className='bg-gray-100 border-b'>
            <td className="text-left text-sm text-sky-600 underline decoration-solid font-light px-6 py-4 whitespace-nowrap">
                <Link to={`/project/${id}`} key={id} onClick={() => {
                    handleOnClick(id)
                }}>{title}</Link>
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {description}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {createdBy}
            </td>
        </tr>
    )
}