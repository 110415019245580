import {ConsoleHeader} from "../ConsoleHeader";
import {Outlet} from "react-router-dom";
import React from "react";
import {BatchPrototype, IBatchPrototype} from "../../../../domain/batch/BatchPrototype";
import {ParticipantPopulationGroups} from "../../../../domain/participant/ParticipantPopulation";


export type IBatchPrototypeContext = {
    batchPrototype: BatchPrototype;
    setBatchPrototype: (prototype: BatchPrototype) => void;
};

export const BatchPrototypeContext = React.createContext<IBatchPrototypeContext | null>(null);

export function Batches () {
    const [batchPrototype, setBatchPrototype] = React.useState<IBatchPrototype>({
        blueprint: undefined,
        population: ParticipantPopulationGroups[0],
        qualifications: [],
        amt_hit_id: '',
        title: '',
        description: '',
        keywords: '',
        maxAssignments: 1,
        assignmentDuration_s: 60 * 60, // 1 hour
        timeToExpire_s: 60 * 60 * 72, // 72 hours,
        reward: 0.5,  // 0.5 USD
        autoApprovalDelay_s: 60 * 60 * 24, // 24 hours
        production: false,
    })

    return (
        <>
            <BatchPrototypeContext.Provider value={{batchPrototype, setBatchPrototype}}>
                <ConsoleHeader title='Task Batches' subtitle='' />
                <Outlet />
            </BatchPrototypeContext.Provider>
        </>
    )
}