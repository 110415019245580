import {IHITRepository} from "../../domain/mturk/HITRepository";
import {HIT} from "../../domain/mturk/HIT";

export class MockHITRepository implements IHITRepository {
    hits: HIT[];

    constructor(){
        this.hits = [];
    }

    add(hit: HIT): Promise<void> {
        this.hits.push(hit);
        return Promise.resolve(undefined);
    }

    getByHITId(hitId: string): Promise<HIT | undefined> {
        return Promise.resolve(
            this.hits.find((hit) => hit.hit_id === hitId)
        );
    }

    list(): Promise<HIT[]> {
        return Promise.resolve(
            this.hits
        );
    }
}