import React, {useEffect, useRef, useState} from "react";
import * as THREE from 'three'
import {BoxGeometry, Vector3} from 'three'
import {OrbitControls} from "./LocalOrbitControl"
import {TransformControls} from "three/examples/jsm/controls/TransformControls";
import {GlobalContext, IGlobalContext} from "../../../App";
import {AnnotatedObject} from "../../../domain/user_tasks/annotate_3d_room/AnnotatedObject";
import {AnnotatedObjectList} from "../../../domain/user_tasks/annotate_3d_room/AnnotatedObjectList";
import {TilesRenderer} from '3d-tiles-renderer';
import {PiCubeFocusLight} from 'react-icons/pi';
import {GrCursor} from 'react-icons/gr';
import {BiMove} from 'react-icons/bi';
import {Tb3DRotate, TbArrowDownLeft, TbArrowDownRight, TbArrowUpLeft, TbArrowUpRight, TbDeviceFloppy, TbPlanet} from 'react-icons/tb';
import {HiMagnifyingGlassMinus, HiMagnifyingGlassPlus} from 'react-icons/hi2';
import {MdRedo, MdUndo} from 'react-icons/md';
import {BsInfoCircle} from 'react-icons/bs';
import {Vertex} from "../../../domain/user_tasks/annotate_3d_room/Vertex";
import {Vertex4D} from "../../../domain/user_tasks/annotate_3d_room/Vertex4D";
import {ActionItem, PCDActionLogger} from "./PCDActionLogger";


/**
 * Point-Cloud Task Template
 * - Renders Point-Cloud Scene (Points with colour in 3D Space)
 * - Template uses ThreeJS for manipulation of 3D space + objects
 * - Once render, the user will add in [Annotation Cube] (green cube) which the user can move, resize, rotate, translate, etc.
 * - The user will position the [Annotation Cube] over a group of Point-cloud's points. E.g. Chair, tables, rubbish bin, projector, doors, etc.
 * - The volume/area encapsulated by the [Annotation Cube] will be the items to be annotated. E.g. conference table.
 * - The user will then give a meaningful annotation to the selected [Annotation Cube]. E.g. conference table
 * - The annotation will be added to the list.
 * - The user repeats until there is no more items to be annotated or the user deems enough.
 * - The user then moves on to the next Point-Cloud scene and repeats.
 * - The tasks is done when all the scenes are annotated.
 * - How it should work: Image and Polygon (if any) are downloaded via JSON, user manipulates the polygon, re-uploads back to backend.
 * - What is Missing/
 * -    TODO: Current simple backend does not have user identification (Current version doesn't discriminate between users)
 * -    TODO: Captured Points from backend can be stored in better data structure like KD-Tree/etc to speed up the colouring of the Points inside the Annotation Cube.
 * -    TODO: Convert the project to use React hook: useRef() for the ThreeJS parts, so that we can save items not needed for rendering. (However, this requires massive time and effort).
 *
 * Notes
 * - This implementation has added simple backend to download of tasks and upload of annotated objects.
 * - This uses 3DTiles instead of PLY.
 *
 * Newbie Notes
 * To read and understand this code, you will need to know:
 *  1. Typescript: Code is written in Typescript.
 *  2. React: Project uses the React Framework (https://react.dev)
 *  3. ThreeJS: Project uses this Javascript Library for 3D Point-Cloud Rendering (https://threejs.org)
 *  4. 3DTilesRenderer: Project uses this to lazy-load Point-Cloud (https://github.com/NASA-AMMOS/3DTilesRendererJS)
 *  5. Backend: Project interfaces with Python-based Backend. In folder: api/requi/tasks.py
 *  6. Point-Cloud file formats: PLY, etc. Also see Python library: py3dtiles on how to convert from PLY files to Lazy-load JSON format.
 *  7. Code onLoad routines are in useEffect (see React). Note that this project does not use React useState hooks, as it will trigger re-renders that breaks ThreeJS
 *  8. UseEffect routines obtain the backend data, setup the 3D scene and renders the Point-cloud.
 *
 * @author William Tan 2022-Dec-22.
 * Modifications:
 * -    2023 Feb 22:    (William) Added backend for download of tasks and upload of annotated objects.
 * -    2023 Mar 17:    (William) Deprecated PLYLoader for ThreeJS Scene. Replaced with 3DTiles, allow us to lazy load the scene in (so that user don't stare at a blank scene)
 * -    2023 Jul 16:    (Riley) Revamped of UI interface
 * -    2023 Aug 25:    (William) Merge of code and fix up Riley missing code and replace hardcoded stuff to dynamically load content.
 * -    2023 Oct 09:    (William) Merge Riley UI code and implement all missing parts.
 * -    2023 Nov 17:    (William) Coloured the Point-Cloud that is encapsulated within the annotation cube(s)
 */
export function PCDViewAndSegment() {
    /**
     * Constants: String URL to YouTube Tutorial Playlist
     */
    const URL_YOUTUBE_TUTORIAL_PLAYLIST: string = "https://www.youtube.com/playlist?list=PLsghKf1yyQIsfFmxnmko9xImGNqAr7w1i";

    /**
     * Constants: String Unlabelled Cube title
     */
    const STR_UNLABELED_CUBE: string = "--- Untitled Cube ---";

    /**
     * Handle to Backend to download user tasks, upload annotated objects, etc
     */
    const {userTasksService} = React.useContext(GlobalContext) as IGlobalContext;

    /**
     * Holds the Room Data the participant would be annotating.
     */
    interface MyEachPCDSet {
        /**
         * Room ID: The unique number associated with this room.
         */
        rm_id: number,

        /**
         * Room Name: The Room name. E.g. Meeting Room 4-3, etc.
         */
        rm_name: string,

        /**
         * The URL to the Point Cloud Data of the room.
         */
        rm_pcd_url: string,

        /**
         * The Task Description. E.g. "Annotate the objects in this room"
         */
        rm_task_description: string,
    }

    /**
     * Interface for Array of Point-Cloud Set
     */
    interface MySetPCDSet extends Array<MyEachPCDSet> {
    }

    /**
     * Holds the Point-Cloud Data from JSON
     */
    let [setOfPCD] = useState<MySetPCDSet>();

    /**
     * Holds the Reference Blueprint-ID (to the associated Fairwork Blueprint this task is associated with)
     */
        //let [refBlueprintID, setRefBlueprintID] = useState<number>(0);
    let refBlueprintID: number = 0;

    /**
     * The current index pointing to the image.
     */
        //let [currentQnIndex, setCurrentQnIndex] = useState<number>(0);
    let currentQnIndex: number = 0;

    /**
     * Holds the Progress Bar status
     */
        //let [progressState, setProgressState] = useState<number>(0);
    let progressState: number = 0;


    //  ==============================================
    //  ThreeJS: Scene, Camera, Point-Cloud & Controls
    //  ==============================================

    /**
     * ThreeJS Canvas: Holds all the objects/points/etc
     */
        //let [canvas, setCanvas] = useState<any>();
    let canvas: any = 0;

    /**
     * ThreeJS Camera: The user view of the ThreeJS scene
     */
    let camera: any;

    /**
     * ThreeJS Scene: Holds the scene
     */
        //let [scene, setScene] = useState<any>();
    let scene: any = 0;

    /**
     * ThreeJS Renderer
     */
        //let [renderer, setRenderer] = useState<any>();
    let renderer: any = 0;

    /**
     * ThreeJS Cube Control: Control the view/orient cube
     */
    let selected_cubeControl: TransformControls;
    //let cubeControl: TransformControls;

    /**
     * ThreeJS Cube Control Mode Tracking Between Rotate Mode VS Translate Mode.
     */
    let flag_cube_in_translate_mode: boolean = true;

    /**
     * ThreeJS Orbit Control: Control the view of the space
     */
    let orbitControl: OrbitControls;

    /**
     * [Annotation Cube]
     * The string the participant has type in to annotate the Selected Cube
     * Notes: The scene now holds and show multiple cubes at one time.
     */
        //let [cubeAnnotation, setCubeAnnotation] = useState("");
    let cubeAnnotation: string = "";

    /**
     * [Annotation Cube]
     * The string that is held in memory (by the selected cube) before anything is changed.
     * String is used when user clicks on Annotation List to change the text in the properties or double-clicking on Annotation List
     * Notes: The scene now holds and show multiple cubes at one time, hence this string is related to the selected cube (cube-in-interest)
     */
    let selected_old_annotation: string = "";

    //  Selected Cube Size
    /**
     * [Annotation Cube]
     * The cube that represents what the participant has selected in the ThreeJS scene.
     * (This is also the cube the encompasses the point-cloud's point that the participant is selecting to be annotated)
     * Notes: The scene now holds and show multiple cubes at one time.
     */
    let selectedCube: THREE.Mesh;
    //let cube: THREE.Mesh;

    /**
     * [Annotation Cube]
     * Gives a unique ID to each Cube when the user clicks on Add Cube button.
     * Counter will increase everytime a cube is added.
     */
    let cube_counter: number = 0;

    /**
     * Deprecated: Not used anymore with multi-cube scene.
     * [Annotation Cube]
     * The Cube Position in Vector (X,Y,Z) coordinates
     */
    //let [cubePosition, setCube] = useState<THREE.Vector3>();

    /**
     * [Annotation Cube]
     * The Cube's Size in 3D: Width of the cube
     */
        //let [cubeWidth, setCubeWidth] = useState(1);
    let cubeWidth: number = 1;

    /**
     * [Annotation Cube]
     * The Cube's Size in 3D: Height of the cube
     */
        //let [cubeHeight, setCubeHeight] = useState(1);
    let cubeHeight: number = 1;

    /**
     * [Annotation Cube]
     * The Cube's Size in 3D: Depth of the cube
     */
        //let [cubeDepth, setCubeDepth] = useState(1);
    let cubeDepth: number = 1;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 1
     */
    let cubeCorner1: THREE.Vector3;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 2
     */
    let cubeCorner2: THREE.Vector3;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 3
     */
    let cubeCorner3: THREE.Vector3;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 4
     */
    let cubeCorner4: THREE.Vector3;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 5
     */
    let cubeCorner5: THREE.Vector3;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 6
     */
    let cubeCorner6: THREE.Vector3;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 7
     */
    let cubeCorner7: THREE.Vector3;

    /**
     * [Annotation Cube]
     * Each cube has 8 corners. Each corner's coordinates is store in Vector (X,Y,Z)-axis.
     * Cube Corner 8
     */
    let cubeCorner8: THREE.Vector3;

    /**
     * [Document handle]
     * References the HTML handle to List <ul> refers to cubeAnnotatedList
     * Allow us to handle the list of annotated objects (cubes) in given room.
     * e.g. Reload the previous created cube on screen.
     * (Annotation List)
     * Ref: https://www.geeksforgeeks.org/how-to-creating-html-list-from-javascript-array/
     */
    let cubeAnnotationList: any;

    /**
     * [Document handle]
     * References the HTML handle to divListOfTasks (below)
     * Tracks if the dropdown list is open (True) or close (False)
     */
    let flagDropdown_listOfTasks: boolean = false;

    /**
     * [Document handle]
     * References the HTML handle to List <ul> refers to cubeAnnotatedList
     * Allow us to handle the list of tasks the user has to do. Click on Task to switch to the new task
     */
    let listOfTasks: any;

    /**
     * [Document handle]
     * References the HTML handle to div_of_listOfTasks
     * Holds the dynamic list of items
     */
    let divListOfTasks: any;

    /**
     * Relates to [Annotation Cube]
     * Holds the list of [Annotation Cube]
     *
     * Once a user has annotated an object, this list holds the string to that object.
     * E.g. Annotated object: projector, table, chairs, etc.
     *
     * Type: Array of cubeAnnotation.
     */
    let listData: any[] = [];

    /**
     * Stores Each Annotated Object the participant has created in the scene.
     *
     * When? This object is only created after the user has placed the [Annotation Cube] at the desired location, entered a string and click on add annotation button.
     *
     * Each Annotated Object would have a roomID, the user typed string,
     * the Three-JS cube Mesh, the Point-Cloud Points within the Annotated Cube and 8 corners of the cube.
     *
     * Once created, each item is store in array until the user finish annotating the room.
     * In which, the whole list will be uploaded to the backend.
     */
    interface MyAnnotationListItem {
        /**
         * Room ID: The unique number associated with this room.
         */
        rm_id: number,

        /**
         * The annotated string the participant has given.
         */
        annotation: string,

        /**
         * The ThreeJS Mesh Data of the [Annotation Cube]
         * This holds the cube's data in 3D space, the size, the location, etc.
         */
        cube: THREE.Mesh,

        /**
         * The Point-Cloud's Points within the [Annotation Cube]
         * Store in ThreeJS Vector holding the coordinates of each point in (X,Y,Z) axis
         */
        annotated_points: THREE.Vector3[],

        /**
         * The Corners of the [Annotation Cube]
         * The 8 corner points of the cube.
         * Store in ThreeJS Vector holding the coordinates of each point in (X,Y,Z) axis
         */
        cube_8_corners: THREE.Vector3[],
    }

    /**
     * Holds the actual list of MyAnnotationListItem that the user has annotated in the scene.
     * (see MyAnnotationListItem)
     */
        //let [annotatedPointList, setAnnotatedPointList] = useState<MyAnnotationListItem[]>();
    let [annotatedPointList] = useState<MyAnnotationListItem[]>();

    /**
     * The Key-Value Pair/Dictionary holding the MyAnnotationListItem List (annotatedPointList)
     * This holds all the annotatedPointList for all the given task.
     * This selected item will:
     *  - Be populated once the user annotated the Task.
     *  - If the user did not annotate any items in the given task, the dictionary will not hold them
     *  Key is the RoomID pair with the list. E.g. RmID - annotatedPointList
     *
     */
    let annotatedPointDict: Map<number, MyAnnotationListItem[]>;

    /**
     * For Double-Clicking On Annotation List
     * Holds the NEW string data that is going to change the existing data that is being double-clicked on.
     */
    let annotation_change_holder: string = "";

    /**
     * For 3DTiles Rendering
     * Purpose: Replaces the PLYLoader, so that data can be lazy-loaded into the scene (i.e. Point-Cloud gets loaded in bit by bit)
     * See https://github.com/NASA-AMMOS/3DTilesRendererJS
     */
    let tilesRenderer: TilesRenderer;

    /**
     * For holding the 3DTiles Points Set
     * As the Point-cloud is lazy-loaded in, data is in array, as data arrives, it is appended to it.
     */
    let downloadPointsSet: THREE.Points[] = [];

    /**
     * For Moving the camera Orbit point: Allow user to orbit at any point in the point-cloud
     * Toggle the Orbit Point (Represented as a yellow cone)
     */
        //let [orbitCone, setOrbitCone] = useState(false);
    let orbitCone: boolean = false;

    /**
     * For Moving the camera Orbit point: Allow user to orbit at any point in the point-cloud
     * Save the last position of the OrbitCone
     */
    let orbitConePosition: THREE.Vector3 = new Vector3(0, 0, 0);

    /**
     * Action Logger to logging actions of the user action in the scene
     */
    let actionLogger: PCDActionLogger = new PCDActionLogger();

    const MATERIAL_OPACITY: number = 0.2;
    /**
     * ThreeJS Renderer: For rendering the screen.
     * Please see ThreeJS documentation
     */
    renderer = new THREE.WebGLRenderer({antialias: true});

    //  =========================================================================
    //  =========================================================================
    //  =========================================================================

    //  ====
    //  Init
    //  ====

    //  Log the init boot
    actionLogger.push(PCDActionLogger.ACTION_INIT, "INIT", null);

    //  Set up the ThreeJS output window
    renderer.setPixelRatio(window.devicePixelRatio);
    //renderer.setSize(720, 480);
    //renderer.setSize(1280, 720);
    //renderer.setSize(1600, 900);

    //let container = document.getElementById('container');
    let cal_height: number = window.innerHeight - 150;
    let cal_width: number = (16 / 9) * cal_height;
    renderer.setSize(cal_width, cal_height);

    //  =========================================================================
    //  =========================================================================
    //  =========================================================================

    /**
     * Load-Once-On-Start UseEffect
     * This useEffect only runs on start and:
     * 1. Process and Loads the Sample JSON
     * 2. Init and Render the Point-Cloud Scene with the 1st Task.
     *
     * This version uses basic access methods and not using the REACT Hooks, this is due to the hook would re-render the whole page.
     * This will cause the Point-Cloud scene to be reinitialised and loose it previous settings. Hence, no REACT Hooks are used in this version.
     */
    useEffect(() => {

        //  Min: Aspect Ratio 1.6x1
        //  Detect the Min Resolution size
        //  Notes:  The app can go below these specified threshold, however:
        //  1. Scene area become to small to work in.
        //  2. Left and Right menus buttons are either out of screen or chopped off to be effective.
        //  Hence, the need for a minimum threshold.
        //   if(window.outerWidth<1585 || window.outerHeight<977) {
        let aspect_ratio: number = window.outerWidth / window.outerHeight;
        //console.log("Aspect: " + aspect_ratio);

        if (aspect_ratio < 1.6) {
            //  Less than required, display the error screen.
            //console.log("window.outerWidth less than!!!");
            //console.log(window.outerWidth);
            //console.log(window.outerHeight);
            //alert("Web App Requires at least resolution of 1585x977 to display properly! Your Resolution is: " + window.outerWidth + "x" + window.outerHeight);

            let handleMainContentDiv = document.getElementById("MainContentDiv");
            if (handleMainContentDiv) {
                handleMainContentDiv.style.visibility = 'hidden';
                handleMainContentDiv.remove();
            }

            let handleErrorContentDiv = document.getElementById("ErrorContentDiv");
            if (handleErrorContentDiv)
                handleErrorContentDiv.style.visibility = 'visible';

        } else {
            //  Within threshold, remove the error screen.
            let handleErrorContentDiv = document.getElementById("ErrorContentDiv");
            if (handleErrorContentDiv) {
                handleErrorContentDiv.style.visibility = 'hidden';
                handleErrorContentDiv.remove();
            }
        }

        //  Obtain the handle to document to output text.
        canvas = document.getElementById("three-canvas");
        cubeAnnotationList = document.getElementById("cubeAnnotatedList");
        divListOfTasks = document.getElementById("div_of_listoftasks");
        listOfTasks = document.getElementById("listoftasks");

        if (divListOfTasks)
            divListOfTasks.style.visibility = 'hidden';

        // console.log("listOfTasks");
        // console.log(listOfTasks);

        //  Hide Cube Context Menu (Not used: Can be enabled)
        // let cubeContextMenu = document.getElementById('cubeContextMenu')
        // if (cubeContextMenu)
        //     cubeContextMenu.style.visibility = 'hidden';

        //  Get from the Backend, process and Load the JSON: (The tasks)
        processJSON().then(() => {

            //  Load any backend data
            if (setOfPCD) {
                loadBackendData(setOfPCD).then(() => {
                    render_data_if_any();
                });
            }

            //  Init and Render
            init();
            render();
        });


        /**
         * Init: Once we have the Point-Cloud Data, start the render to screen.
         */
        function init() {

            if (!renderer)
                return;

            //  Setup and add camera
            canvas!.appendChild(renderer.domElement);

            scene = new THREE.Scene();

            camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 40);
            camera.position.set(0, 0, 10);
            scene.add(camera);

            //  Orbit Controls: Camera with the Scene: https://threejs.org/docs/#examples/en/controls/OrbitControls
            const localOrbitControl = new OrbitControls(camera, renderer.domElement);
            localOrbitControl.enablePan = true;
            localOrbitControl.addEventListener('change', render); // use if there is no animation loop
            localOrbitControl.maxDistance = 10;
            orbitControl = localOrbitControl;

            //  Load the Point-Cloud (Lazy-Loading using 3DTiles)
            if (setOfPCD) {

                tilesRenderer = new TilesRenderer(setOfPCD[currentQnIndex].rm_pcd_url);
                tilesRenderer.fetchOptions.mode = 'cors';
                tilesRenderer.lruCache.minSize = 900;
                tilesRenderer.lruCache.maxSize = 1300;
                tilesRenderer.errorTarget = 12;
                tilesRenderer.setCamera(camera);
                tilesRenderer.setResolutionFromRenderer(camera, renderer);

                tilesRenderer.group.name = "roomPCD";
                scene.add(tilesRenderer.group);

                //  Capture the lazy-downloaded Points
                downloadPointsSet = [];
                tilesRenderer.onLoadModel = (scene: THREE.Object3D<THREE.Event>) => {

                    //  As the model loads, it traverses through the data, hence capture it as and when it arrives.
                    scene.traverse(c => {
                        //console.log(c);
                        let e: THREE.Points = (c as THREE.Points);

                        downloadPointsSet.push(e);
                    });
                };

                render_init();
            }

            //  Add Window Controls
            window.addEventListener('resize', onWindowResize);

            //  Add keyboard listener
            //  Notes: Not every key input is needed. Some functionality is commented out (not needed but preserve for future use if needed)
            window.addEventListener('keydown', tjs_handle_keyboard);
        }


        /**
         * Handle the Resize of the Window (when the user resize the browser)
         */
        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            if (renderer) {
                //renderer.setSize(window.innerWidth, window.innerHeight);

                let cal_height: number = window.innerHeight - 150;
                let cal_width: number = (16 / 9) * cal_height;
                renderer.setSize(cal_width, cal_height);
            }
        }

        /**
         * Render to screen
         */
        function render() {

            if (renderer)
                renderer.render(scene, camera);
        }

        /**
         * Render Any Server-Side Annotation Data (if any)
         *
         * If the backend has any previously annotated data, stored, this will render the annotation cubes onto the scene.
         */
        function render_data_if_any() {
            //console.log("render_data_if_any");

            if (annotatedPointDict && setOfPCD) {
                //console.log("render_data_if_any: annotatedPointDict && setOfPCD");
                //  Step 3A: Reset ThreeJS container and load the selected task('s point-cloud)
                //resetScene();
                //loadNextPCD(currentQnIndex);
                //console.log("Loaded");

                //  Step 3B: Recalculate the Progress (since we can now jump around)
                let qnText = document.getElementById("quesText");
                if (qnText)
                    qnText.innerText = "Task " + setOfPCD[currentQnIndex].rm_id + ": " + setOfPCD[currentQnIndex].rm_task_description;

                //  Get the percentage of the progress, convert to Integer
                let tempNo: number = (annotatedPointDict.size / setOfPCD.length) * 100;
                let tempNoStr: string = "" + tempNo;
                progressState = parseInt(tempNoStr);

                let pgText = document.getElementById("progressText");
                if (pgText)
                    pgText.innerText = "Status of all Tasks: " + progressState + "% Completed.";

                //  Step 3C: Load any previously saved Annotation (local)
                let currentAnnotatedPointList = annotatedPointDict.get(setOfPCD[currentQnIndex].rm_id);
                if (currentAnnotatedPointList) {
                    //console.log("render_data_if_any: annotatedPointDict && setOfPCD: currentAnnotatedPointList");
                    //  Load it into memory
                    annotatedPointList = currentAnnotatedPointList;

                    //  Load into HTML list
                    let updatedListData: any[] = [];
                    currentAnnotatedPointList.forEach((item) => {
                        updatedListData.push(item.annotation);
                    });
                    listData = updatedListData;

                    //  Load Cubes (if any)
                    addAllCubes();

                    //  Reload list
                    showAnnotatedItemsInList(listData);
                } else {
                    //console.log("render_data_if_any: annotatedPointDict && setOfPCD: Clear");
                    //  Clear the list
                    annotatedPointList = undefined;
                    listData = [];
                    let myList = document.getElementById('cubeAnnotatedList');
                    if (myList)
                        myList.innerHTML = '';
                }

                //  Hide Cube Context Menu (Not used: Can be enabled)
                // let cubeContextMenu = document.getElementById('cubeContextMenu')
                // if (cubeContextMenu)
                //     cubeContextMenu.style.visibility = 'hidden';
            }
        }

        /**
         * Render (Initialization Phase)
         * Only called during initial startup.
         *
         * Meant up for setting up the 3DTiles
         */
        function render_init() {

            if (renderer) {
                requestAnimationFrame(render_init);

                // The camera matrix is expected to be up-to-date
                // before calling tilesRenderer.update
                camera.updateMatrixWorld();
                tilesRenderer.update();
                renderer.render(scene, camera);
            }
            //console.log("render");
        }
    }, [])


    /**
     * Weizhen (Riley)'s Addition. For Secondary UseEffect.
     */
    const dropdownRef = useRef(null);

    /**
     * Secondary UseEffect by Weizhen (Riley)
     * This loads the UI component during startup
     */
    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (!dropdownRef.current) {
                return
            }

            // @ts-ignore
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                //setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);


    /**
     * Get All Points In Given Cube
     * 1. This obtains the Point-Cloud's points within the annotation cube.
     * 2. This function only works if the annotation selection cube is added.
     * 3. This works by first getting a handle on the cube, so that when we iterate through the point-cloud's points,
     *    we can check if the point is within the cube.
     * 4. Iterate through the Points (in the Point-Cloud)
     * 5. Check if the point is within the cube.
     * 6. If is in the cube, save the Point's data (coordinates as well as colour)
     * 7. If is not, do not include the Point-Cloud points into a separate holder. (This would show the Scene minus away the area underneath the cube)
     *
     * Translate the Cube to Box: To access function to check if a point is inside the cube.
     * https://stackoverflow.com/questions/50111230/verifying-if-a-point-is-inside-a-cube-in-three-js
     *
     * William Notes: However, if you use the above URL, it will not work once the cube is transformed: Moved, Scaled or Rotated.
     * In such instance, we need to transform the modified cube back to the world scene to check if a point is under the cube.
     * If you don't transform and use the original boxCubeHandle, the THREE.Box3() will get the Cube's geometry, but it will not get its Rotation.
     * Hence, if when checking the points, it will not be correct if the point is inside the cube after it is rotated, so we have to use the transformed handle.
     * See solution: https://stackoverflow.com/questions/46322914/how-to-determine-if-point-is-inside-box-three-js
     * @param cubeName The given cube's name (in the scene)
     * @param roomPoints The captured Point-Cloud points
     * @param bvPosition The Bounding-Volume Position of the 3D-Tiles Point-cloud. (Note! As the Point-Cloud is loaded in by the 3DTilesRenderer,
     *                   the Point-Cloud is bounded by its bounding-volume's position. The Point-Cloud is not centered at 0,0,0 but rather centered by the bvPosition)
     */
    function getAllPointsInCube(cubeName: string, roomPoints: THREE.Points, bvPosition: THREE.Vector3): [number[], number[]] {

        let pointsInBox: number[] = [];
        let pointColorInBox: number[] = [];

        //  Get the Cube Handle
        let cubeObj: THREE.Mesh = scene.getObjectByName(cubeName).clone();
        if (!isNaN(cubeObj.position.x) && !isNaN(cubeObj.position.y) && !isNaN(cubeObj.position.z)) {
            cubeObj.position.set(cubeObj.position.x - bvPosition.x, cubeObj.position.y - bvPosition.y, cubeObj.position.z - bvPosition.z);

            //  Translate the Cube to Box: To access function to check if a point is inside the cube.
            //  https://stackoverflow.com/questions/50111230/verifying-if-a-point-is-inside-a-cube-in-three-js
            //  William Notes: However, if you use the above URL, it will not work once the cube is transformed: Moved, Scaled or Rotated.
            //  In such instance, we need to transform the modified cube back to the world scene to check if a point is under the cube.
            //  If you don't transform and use the original boxCubeHandle, the THREE.Box3() will get the Cube's geometry, but it will not get its Rotation.
            //  See solution: https://stackoverflow.com/questions/46322914/how-to-determine-if-point-is-inside-box-three-js
            cubeObj.geometry.computeBoundingBox(); // This is only necessary if not already computed
            cubeObj.updateMatrixWorld(true); // This might be necessary if box is moved

            //  Transform the modified cube
            let boxMatrixInverse = cubeObj.matrixWorld.invert();

            //  Get a clone handle that transformed it back to the world.
            let inverseBox = cubeObj.clone();
            inverseBox.applyMatrix4(boxMatrixInverse);

            //  Finally get the boxCubeHandle for Point checking
            let boxCubeHandle = new THREE.Box3(); // for re-use
            boxCubeHandle.setFromObject(inverseBox);

            //  For reseeding the PCD scene.
            let pointsOutsideBox = [];
            let pointColorOutsideBox = [];

            //  Holders for the point-cloud's point: Coordinates and Color (RGB)
            let split3Counter: number = 0;
            let holder_x: number = 0.0;
            let holder_y: number = 0.0;
            let holder_z: number = 0.0;

            let holder_R: number = 0.0;
            let holder_G: number = 0.0;
            let holder_B: number = 0.0;

            //  William Note: As we iterate through the array: We are capturing each Point's position (X,Y,Z) as well as each Point's RGB data
            //  We are using a short-cut trick: As the color array and Points array are the same size using a single loop.
            //  i.e. roomPoints.geometry.attributes.position.array.length == roomPoints.geometry.attributes.color.array.length (Both are 3-Vector size)
            //  So we are using 1 loop trick to condense the Point's location and color data.
            //  Note that if the format is in different, i.e. if the color has ARGB (4-vector data), this loop will not work, You have to write a separate loop to capture the 4-color data.
            //  https://stackoverflow.com/questions/67855269/how-do-you-iterate-through-the-vertices-of-a-geometry-in-threejs-v128
            //
            //  Also note: This is not a optimised approach, will have penalty if the Point-Cloud is large. TODO: Use KD-Tree?
            //console.log("roomPoints");
            //console.log(roomPoints);
            for (let i: number = 0; i < roomPoints.geometry.attributes.position.array.length; i++) {
                //console.log(roomPoints.geometry.attributes.position.array[i]);
                if (split3Counter === 0) {
                    holder_x = roomPoints.geometry.attributes.position.array[i];
                    holder_R = roomPoints.geometry.attributes.color.array[i];
                    split3Counter++;
                } else if (split3Counter === 1) {
                    holder_y = roomPoints.geometry.attributes.position.array[i];
                    holder_G = roomPoints.geometry.attributes.color.array[i];
                    split3Counter++;
                } else {
                    holder_z = roomPoints.geometry.attributes.position.array[i];
                    holder_B = roomPoints.geometry.attributes.color.array[i];
                    split3Counter++;
                }

                if (split3Counter === 3) {
                    //console.log("X: " + holder_x + ", Y: " + holder_y + ", Z: " + holder_z);
                    //  Get the XYZ and put it into a Point. Invert it, why?
                    //  See here above or here: https://stackoverflow.com/questions/46322914/how-to-determine-if-point-is-inside-box-three-js
                    let pointA = new THREE.Vector3(holder_x, holder_y, holder_z);
                    pointA.applyMatrix4(boxMatrixInverse);

                    //  Check point is inside cube or not.
                    //  If it is, store it.
                    if (boxCubeHandle.containsPoint(pointA)) {

                        //  Point is inside the cube. Store in so that it can be paired with Annotation uploaded.
                        //console.log("X: " + holder_x + ", Y: " + holder_y + ", Z: " + holder_z);
                        //removed_points.push(pointA);

                        //  Store removed Points for recreating the Mesh
                        pointsInBox.push(holder_x + bvPosition.x);
                        pointsInBox.push(holder_y + bvPosition.y);
                        pointsInBox.push(holder_z + bvPosition.z);

                        pointColorInBox.push(holder_R);
                        pointColorInBox.push(holder_G);
                        pointColorInBox.push(holder_B);

                    } else {

                        //  Store the Remaining Points NOT inside the Cube.
                        //  This effectively "minus" away the Points inside the cube (void) in the scene
                        //  This ELSE may be removed, this is to showcase that we are capturing the points under the cube.
                        pointsOutsideBox.push(holder_x);
                        pointsOutsideBox.push(holder_y);
                        pointsOutsideBox.push(holder_z);

                        pointColorOutsideBox.push(holder_R);
                        pointColorOutsideBox.push(holder_G);
                        pointColorOutsideBox.push(holder_B);
                    }
                    split3Counter = 0;
                }
            }
        }

        return [pointsInBox, pointColorInBox];
    }


    /**
     * Handle Keyboard Event for ThreeJS
     * @param event The keyboard event
     */
    function tjs_handle_keyboard(event: KeyboardEvent) {

        //  Handle the keyboard event
        handle_keyboard_event(event.keyCode);
    }

    /**
     * Similar to tjs_handle_keyboard() function: This handles the keyboard event.
     * However, work directly with the extracted keyboard code.
     * @param event_no (Number). The keyboard that represents the press key. E.g. Pressing Q result in code 81.
     */
    function ui_handle_keyboard(event_no: number) {

        //  Handle the keyboard event
        handle_keyboard_event(event_no);
    }

    /**
     * Given the keyboard code, process each keypress based on the assign roles
     * Roles: (TODO: fill up when free)
     * Q - Toggle Cube Translation to the world space or cube space
     * E - Toggle Cube Rotation
     * @param event_no (Number). The keyboard that represents the press key. E.g. Pressing Q result in code 81.
     */
    function handle_keyboard_event(event_no: number) {

        switch (event_no) {

            case 81: // Q Cube Set Space
                if (selected_cubeControl)
                    selected_cubeControl.setSpace(selected_cubeControl.space === 'local' ? 'world' : 'local');
                else
                    alert("No Cube Selected!");
                break;

            case 69: // E Rotate Cube
                if (flag_cube_in_translate_mode) {
                    //  Rotate Mode
                    if (selected_cubeControl) {
                        selected_cubeControl.setMode('rotate');
                        selected_cubeControl.showX = false;
                        selected_cubeControl.showY = true;
                        selected_cubeControl.showZ = false;

                        //  Set the toggle
                        flag_cube_in_translate_mode = false;
                    } else
                        alert("No Cube Selected!");
                } else {
                    //  Translate Mode
                    if (selected_cubeControl) {
                        selected_cubeControl.setMode('translate');
                        selected_cubeControl.showX = true;
                        selected_cubeControl.showY = true;
                        selected_cubeControl.showZ = true;

                        //  Set the toggle
                        flag_cube_in_translate_mode = true;
                    } else
                        alert("No Cube Selected!");
                }
                break;

            case 27: // Esc
                if (selected_cubeControl)
                    selected_cubeControl.reset();
                else
                    alert("No Cube Selected!");
                break;

            //  William Camera Orbit Point Move (Key S,X: For Z-Axis, Key Z,C: For X-Axis).
            case 86: // V

                //  Toggle Orbit Cone
                orbitCone = !orbitCone;
                //console.log("V triggered!");
                //console.log(orbitCone);
                //showOrbitCone();
                toggleOrbitCone();
                break;
            case 83: // S
                //  Move (Add) in Z-Axis
                if (orbitControl) {
                    let dPos_z = orbitControl.target.z;
                    dPos_z = dPos_z + 0.5;
                    orbitControl.target.set(orbitControl.target.x, orbitControl.target.y, dPos_z);
                    // orbitControl.enableRotate=false;
                    // orbitControl.enablePan=false;
                    orbitControl.update();
                    adjustCone(orbitControl.target.x, orbitControl.target.y, dPos_z);
                    //console.log(controls.target);
                    scene_render();
                }
                break;

            case 88: // X
                //  Move (Subtract) in Z-Axis
                if (orbitControl) {
                    let apos_z = orbitControl.target.z;
                    apos_z = apos_z - 0.5;
                    orbitControl.target.set(orbitControl.target.x, orbitControl.target.y, apos_z);
                    // orbitControl.enableRotate=false;
                    // orbitControl.enablePan=false;
                    orbitControl.update();
                    adjustCone(orbitControl.target.x, orbitControl.target.y, apos_z);
                    scene_render();
                }
                break;

            case 90: // Z
                //  Move (Subtract) in X-Axis
                if (orbitControl) {
                    let apos_x = orbitControl.target.x;
                    apos_x = apos_x - 0.5;
                    orbitControl.target.set(apos_x, orbitControl.target.y, orbitControl.target.z);
                    // orbitControl.enableRotate=false;
                    // orbitControl.enablePan=false;
                    orbitControl.update();
                    adjustCone(apos_x, orbitControl.target.y, orbitControl.target.z);
                    scene_render();
                }
                break;

            case 67: // C
                //  Move (Add) in Z-Axis
                if (orbitControl) {
                    let dPos_x = orbitControl.target.x;
                    dPos_x = dPos_x + 0.5;
                    orbitControl.target.set(dPos_x, orbitControl.target.y, orbitControl.target.z);
                    // orbitControl.enableRotate=false;
                    // orbitControl.enablePan=false;
                    orbitControl.update();
                    adjustCone(dPos_x, orbitControl.target.y, orbitControl.target.z);
                    scene_render();
                }
                break;
            // default:
            //     console.log("Keycode");
            //     console.log(event.keyCode);
        }
    }

    /**
     * Handle Keyboard Keypress Type: Q
     */
    function ui_handle_keyboard_q() {
        ui_handle_keyboard(81);
    }

    /**
     * Handle Keyboard Keypress Type: E
     */
    function ui_handle_keyboard_e() {
        ui_handle_keyboard(69);
    }

    /**
     * Handle Keyboard Keypress Type: V
     */
    function ui_handle_keyboard_v() {
        ui_handle_keyboard(86);
    }

    /**
     * Handle Keyboard Keypress Type: S
     */
    function ui_handle_keyboard_s() {
        ui_handle_keyboard(83);
    }

    /**
     * Handle Keyboard Keypress Type: X
     */
    function ui_handle_keyboard_x() {
        ui_handle_keyboard(88);
    }

    /**
     * Handle Keyboard Keypress Type: Z
     */
    function ui_handle_keyboard_z() {
        ui_handle_keyboard(90);
    }

    /**
     * Handle Keyboard Keypress Type: C
     */
    function ui_handle_keyboard_c() {
        ui_handle_keyboard(67);
    }

    /**
     * Render the Scene
     */
    function scene_render() {

        requestAnimationFrame(scene_render);
        if (renderer) {
            camera.updateMatrixWorld();
            tilesRenderer.update();
            renderer.render(scene, camera);
        }
    }

    /**
     * Toggle the Orbit Cone
     * Checks the orbitCone Flag: Turn on/Off the Orbit Cone in scene.
     *
     * The orbit cone shows the point the camera is orbiting around.
     * Hence, by moving the Orbit Cone to another the point on the point-cloud, we move to another area in the Point-Cloud space.
     *
     */
    function toggleOrbitCone() {

        if (orbitCone) {
            //  The code is ported from https://threejs.org/docs/#api/en/geometries/ConeGeometry
            const geometry = new THREE.CylinderGeometry(0.05, 0.05, 30, 32);
            const material = new THREE.MeshBasicMaterial({color: 0xffff00});
            const cone = new THREE.Mesh(geometry, material);
            cone.position.set(orbitConePosition.x, orbitConePosition.y, orbitConePosition.z);
            cone.name = "orbitcone";
            scene.add(cone);
            //console.log("x: " + orbitConePosition.x + ", y: " + orbitConePosition.y + ", z: " + orbitConePosition.z);
        } else {
            const cone = scene.getObjectByName('orbitcone');
            orbitConePosition = cone.position;
            scene.remove(cone);
        }
    }

    /**
     * Adjust the Orbit Cone location.
     * Sets the Orbit Cone to the define location in 3D space( X, Y and Z axis)
     * @param x A Point on the X-Axis
     * @param y A Point on the Y-Axis
     * @param z A Point on the Z-Axis
     */
    function adjustCone(x: number, y: number, z: number) {
        const cone = scene.getObjectByName('orbitcone');
        if (cone) {
            cone.position.set(x, y, z);
            orbitConePosition.set(x, y, z);
        }
    }

    /**
     * Downloads the JSON from backend as per Blueprint/Batch selection of the user define.
     * TODO: Missing the user identity and blueprint ID portion. i.e. Unclear how participants get to this page with their userID + BlueprintID
     * TODO: i.e. The portal does not have a login page, etc. Hence, the current task page does not discriminate between different users.
     * TODO: This todo is left for future modification.
     *
     * On Download:
     * 1. JSON is parsed.
     * 2. Progress and First task question is loaded
     * 3. Task Point-Cloud path is loaded into the variable which is used later during the boot up.
     */
    async function processJSON() {

        const response = await userTasksService.task_3d_rm_annotate_list_rooms(1);
        //console.log("response");
        //console.log(response);

        //  Parse the load the image set
        const data = JSON.parse(response);
        let mySetPCDData: MySetPCDSet = data["value"][0]["list_of_rooms"]["room_set"];
        setOfPCD = mySetPCDData;
        // console.log("setOfPCD");
        // console.log(setOfPCD);
        refBlueprintID = data["value"][0]["associated_blueprint_id"];

        //  Reset the index to 0, reset the progress, loads the first image
        currentQnIndex = 0;
        //setCurrentQnIndex(0);
        progressState = 0;
        //setProgressState(0);

        let qnText = document.getElementById("quesText");
        if (qnText)
            qnText.innerText = "Task " + mySetPCDData[0].rm_id + ": " + mySetPCDData[0].rm_task_description;

        let pgText = document.getElementById("progressText");
        if (pgText)
            pgText.innerText = "Status: " + progressState + "% Completed.";

        //  Add into the list
        if (listOfTasks) {
            setOfPCD.forEach((item) => {
                let li = document.createElement("li");
                li.innerText = item.rm_name;
                li.classList.add('hover:bg-[#FFFFFF]');
                listOfTasks.appendChild(li);
            });
        }

        // console.log("listOfTasks");
        // console.log(listOfTasks);

        //  Init the holder
        annotatedPointDict = new Map<number, MyAnnotationListItem[]>();
    }

    /**
     * OnClick Event: On Clicking on item in the Task List
     * What it does:
     * - Saves the current annotation and uploads it.
     * - Load the task (item) the user clicks on.
     * - This includes any previously annotated items in that task (look into the Annotation Dictionary)
     * - Reset the interface for this task.
     * - Reset the ActionLogger
     * @param event The captured attributes to the event (text data, etc.)
     */
    function onClickTaskList(event: any) {
        //console.log(event.target.innerText);

        if (setOfPCD) {

            //  Step 1: Update the state of the cube before update to backend
            if (annotatedPointList && setOfPCD) {

                let localPointList: MyAnnotationListItem[] = [];
                //  Load the selected cube annotation
                for (let i = 0; i < annotatedPointList.length; i++) {

                    //console.log("Pre: " + annotatedPointList[i].rm_id + ", " + annotatedPointList[i].annotation);
                    if (setOfPCD[currentQnIndex].rm_id == annotatedPointList[i].rm_id) {
                        //  Get all 8 points of a cube
                        getAll8PointsCornerOfCube(annotatedPointList[i].cube.name);

                        //  Store all 8 points to holder.
                        const cube_8_corners: THREE.Vector3[] = [];
                        cube_8_corners.push(cubeCorner1);
                        cube_8_corners.push(cubeCorner2);
                        cube_8_corners.push(cubeCorner3);
                        cube_8_corners.push(cubeCorner4);
                        cube_8_corners.push(cubeCorner5);
                        cube_8_corners.push(cubeCorner6);
                        cube_8_corners.push(cubeCorner7);
                        cube_8_corners.push(cubeCorner8);

                        annotatedPointList[i].cube_8_corners = cube_8_corners;

                        localPointList.push(annotatedPointList[i]);
                    }
                }
                annotatedPointList = localPointList;
                addAnnotationDict(setOfPCD[currentQnIndex].rm_id, annotatedPointList);
            }

            //  Step 2: Save current & upload
            upload_annotated_dict().then(() => {

                //  Do the following after the callback
                //  Check the data is available.
                if (setOfPCD) {
                    //  Step 3: Load selected task
                    for (let i = 0; i < setOfPCD.length; i++) {
                        if (setOfPCD[i].rm_name === event.target.innerText) {

                            //  Load any backend data
                            //loadBackendData(setOfPCD[i].rm_id).then(r => {});

                            //  Step 3A: Reset ThreeJS container and load the selected task('s point-cloud)
                            resetScene();
                            currentQnIndex = i;
                            loadNextPCD(currentQnIndex);
                            //console.log("Loaded");

                            //  Step 3B: Recalculate the Progress (since we can now jump around)
                            let qnText = document.getElementById("quesText");
                            if (qnText)
                                qnText.innerText = "Task " + setOfPCD[currentQnIndex].rm_id + ": " + setOfPCD[currentQnIndex].rm_task_description;

                            progressState = (annotatedPointDict.size / setOfPCD.length) * 100;
                            let pgText = document.getElementById("progressText");
                            if (pgText)
                                pgText.innerText = "Status of all Tasks: " + progressState + "% Completed.";

                            //  Step 3C: Load any previously saved Annotation (local)
                            let currentAnnotatedPointList = annotatedPointDict.get(setOfPCD[currentQnIndex].rm_id);
                            if (currentAnnotatedPointList) {
                                //  Load it into memory
                                annotatedPointList = currentAnnotatedPointList;

                                //  Load into HTML list
                                let updatedListData: any[] = [];
                                currentAnnotatedPointList.forEach((item) => {
                                    updatedListData.push(item.annotation);
                                });
                                listData = updatedListData;

                                //  Load Cubes (if any)
                                addAllCubes();

                                //  Reload list
                                showAnnotatedItemsInList(listData);
                            } else {
                                //  Clear the list
                                annotatedPointList = undefined;
                                listData = [];
                                let myList = document.getElementById('cubeAnnotatedList');
                                if (myList)
                                    myList.innerHTML = '';
                            }

                            //  Hide Cube Context Menu (Not used: Can be enabled)
                            // let cubeContextMenu = document.getElementById('cubeContextMenu')
                            // if (cubeContextMenu)
                            //     cubeContextMenu.style.visibility = 'hidden';

                            break;
                        }
                    }
                }
            });
        }
    }

    /**
     * OnClick Event: On Clicking on Save Button
     * What it does:
     * - Saves the current annotation and uploads it.
     */
    function onClickSaveScene() {
        //console.log(event.target.innerText);

        if (setOfPCD) {

            //  Step 1: Update the state of the cube before update to backend
            if (annotatedPointList && setOfPCD) {

                let localPointList: MyAnnotationListItem[] = [];
                //  Load the selected cube annotation
                for (let i = 0; i < annotatedPointList.length; i++) {

                    //console.log("Pre: " + annotatedPointList[i].rm_id + ", " + annotatedPointList[i].annotation);
                    if (setOfPCD[currentQnIndex].rm_id == annotatedPointList[i].rm_id) {
                        //  Get all 8 points of a cube
                        getAll8PointsCornerOfCube(annotatedPointList[i].cube.name);

                        //  Store all 8 points to holder.
                        const cube_8_corners: THREE.Vector3[] = [];
                        cube_8_corners.push(cubeCorner1);
                        cube_8_corners.push(cubeCorner2);
                        cube_8_corners.push(cubeCorner3);
                        cube_8_corners.push(cubeCorner4);
                        cube_8_corners.push(cubeCorner5);
                        cube_8_corners.push(cubeCorner6);
                        cube_8_corners.push(cubeCorner7);
                        cube_8_corners.push(cubeCorner8);

                        annotatedPointList[i].cube_8_corners = cube_8_corners;

                        //  Update the cube
                        let cubeObj: THREE.Mesh = scene.getObjectByName(annotatedPointList[i].cube.name);
                        annotatedPointList[i].cube = cubeObj.clone();

                        localPointList.push(annotatedPointList[i]);
                    }
                }

                //  Once all item in list is updated, update the memory handle
                annotatedPointList = localPointList;
                addAnnotationDict(setOfPCD[currentQnIndex].rm_id, annotatedPointList);
            }

            //  Step 2: Save current & upload
            upload_annotated_dict().then(() => {
                alert("Scene Saved!");
            });
        }
    }

    /**
     * Load Room's Annotation (Cubes) From the Backend Server (if any)
     *
     * Obtain from backend all the annotation (if available) from each room and reload the local dataset
     * Hence, the user will get to see what was previously annotated in the database.
     *
     * @param setOfPCD THe list of tasks from the backend (loaded on start), this will be used to query the backend.
     */
    async function loadBackendData(setOfPCD: MySetPCDSet) {

        for (let i = 0; i < setOfPCD.length; i++) {

            //  Current Room id
            let roomID = setOfPCD[i].rm_id;

            //  William Build
            const response = await userTasksService.task_3d_rm_get_annotation(1, roomID);
            //console.log("response");
            //console.log(response);

            //  Parse the load the image set
            const data = JSON.parse(response);
            //console.log(data);

            if (data["value"].length > 0) {
                if (annotatedPointDict) {

                    if (annotatedPointDict.has(roomID))
                        annotatedPointDict.delete(roomID);

                    //console.log("Has: " + roomID);

                    let annotatedList: MyAnnotationListItem[] = [];

                    for (let i = 0; i < data["value"].length; i++) {

                        //  Store all 8 points to holder.
                        const cube_8_corners: THREE.Vector3[] = [];
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_1"]["x"]), parseFloat(data["value"][i]["cube_corner_1"]["y"]), parseFloat(data["value"][i]["cube_corner_1"]["z"])));
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_2"]["x"]), parseFloat(data["value"][i]["cube_corner_2"]["y"]), parseFloat(data["value"][i]["cube_corner_2"]["z"])));
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_3"]["x"]), parseFloat(data["value"][i]["cube_corner_3"]["y"]), parseFloat(data["value"][i]["cube_corner_3"]["z"])));
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_4"]["x"]), parseFloat(data["value"][i]["cube_corner_4"]["y"]), parseFloat(data["value"][i]["cube_corner_4"]["z"])));
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_5"]["x"]), parseFloat(data["value"][i]["cube_corner_5"]["y"]), parseFloat(data["value"][i]["cube_corner_5"]["z"])));
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_6"]["x"]), parseFloat(data["value"][i]["cube_corner_6"]["y"]), parseFloat(data["value"][i]["cube_corner_6"]["z"])));
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_7"]["x"]), parseFloat(data["value"][i]["cube_corner_7"]["y"]), parseFloat(data["value"][i]["cube_corner_7"]["z"])));
                        cube_8_corners.push(new THREE.Vector3(parseFloat(data["value"][i]["cube_corner_8"]["x"]), parseFloat(data["value"][i]["cube_corner_8"]["y"]), parseFloat(data["value"][i]["cube_corner_8"]["z"])));

                        //  Prep the annotation entry to be saved
                        let annotatedEntry: MyAnnotationListItem = {
                            rm_id: roomID,
                            annotation: data["value"][i]["object_name"],
                            cube: createCube(
                                roomID,
                                data["value"][i]["object_name"],
                                parseFloat(data["value"][i]["cube_dimension"]["width"]),
                                parseFloat(data["value"][i]["cube_dimension"]["height"]),
                                parseFloat(data["value"][i]["cube_dimension"]["depth"]),
                                parseFloat(data["value"][i]["cube_position"]["x"]),
                                parseFloat(data["value"][i]["cube_position"]["y"]),
                                parseFloat(data["value"][i]["cube_position"]["z"]),
                                parseFloat(data["value"][i]["cube_quaternion"]["x"]),
                                parseFloat(data["value"][i]["cube_quaternion"]["y"]),
                                parseFloat(data["value"][i]["cube_quaternion"]["z"]),
                                parseFloat(data["value"][i]["cube_quaternion"]["w"])
                            ),
                            annotated_points: [],
                            cube_8_corners: cube_8_corners
                        };

                        annotatedList.push(annotatedEntry);
                    }

                    annotatedPointDict.set(roomID, annotatedList);
                }
            }
        }
    }

    /**
     * Creates Cube (Part of loadBackendData)
     *
     * This creates the ThreeJS 3D model for the cube (that represents the annotated area)
     * Given the following numeric data stored on the server, we use ThreeJS to recreate the 3D model in the scene.
     *
     * @param _room_id The Room ID
     * @param cube_name The Cube's Name
     * @param cube_width  Cube's Width
     * @param cube_height Cube's Height
     * @param cube_depth Cube's Depth
     * @param cube_pos_x Cube Position in X-Axis
     * @param cube_pos_y Cube Position in Y-Axis
     * @param cube_pos_z Cube Position in Z-Axis
     * @param cube_quant_x The Rotation data in X
     * @param cube_quant_y The Rotation data in Y
     * @param cube_quant_z The Rotation data in Z
     * @param cube_quant_w The Rotation data in W
     */
    function createCube(_room_id: number, cube_name: string, cube_width: number, cube_height: number, cube_depth: number, cube_pos_x: number, cube_pos_y: number, cube_pos_z: number, cube_quant_x: number, cube_quant_y: number, cube_quant_z: number, cube_quant_w: number) {
        const geometry = new THREE.BoxGeometry(cube_width, cube_height, cube_depth);
        geometry.center();

        const material = new THREE.MeshBasicMaterial({color: 0x00ff00, opacity: MATERIAL_OPACITY, transparent: true});
        //const cube = new THREE.Mesh( geometry, material );
        //cube = new THREE.Mesh(geometry, material);
        const projected_cube = new THREE.Mesh(geometry, material);
        //projected_cube.name="cube_" + room_id + cube_counter;
        projected_cube.name = cube_name;
        cube_counter++;

        //  Insert Cube in front of the camera. Ref: https://stackoverflow.com/questions/17218054/how-to-put-an-object-in-front-of-camera-in-three-js
        projected_cube.position.copy(new THREE.Vector3(cube_pos_x, cube_pos_y, cube_pos_z));
        projected_cube.applyQuaternion(new THREE.Quaternion(cube_quant_x, cube_quant_y, cube_quant_z, cube_quant_w));
        projected_cube.updateMatrix();

        return projected_cube;
    }


    /**
     * Uploads the Annotated data of the current Task to the backend.
     * Based on the current Index (indicating which task we are on)
     * Load the Annotated list and uploads it.
     * Includes the RoomID, Annotation and the 8 corners of the cube.
     */
    async function upload_annotated_dict() {

        //  Check if dataset is initialised.
        if (annotatedPointDict && setOfPCD) {

            //  Get the current annotation (selected room)
            let currentAnnotatedPointList = annotatedPointDict.get(setOfPCD[currentQnIndex].rm_id);
            if (currentAnnotatedPointList) {

                //  Translate the objects to upload data set
                let annotatedList: AnnotatedObject[] = [];
                //  Find the item in Annotation List and reload
                for (let i = 0; i < currentAnnotatedPointList.length; i++) {

                    //  Build the annotated object (selected by the cube)
                    let annotated_object = new AnnotatedObject(
                        refBlueprintID,
                        currentAnnotatedPointList[i].rm_id,
                        currentAnnotatedPointList[i].annotation,
                        new Vertex(currentAnnotatedPointList[i].cube.position.x, currentAnnotatedPointList[i].cube.position.y, currentAnnotatedPointList[i].cube.position.z),
                        new Vertex((currentAnnotatedPointList[i].cube.geometry as BoxGeometry).parameters.width, (currentAnnotatedPointList[i].cube.geometry as BoxGeometry).parameters.height, (currentAnnotatedPointList[i].cube.geometry as BoxGeometry).parameters.depth),
                        new Vertex4D(currentAnnotatedPointList[i].cube.quaternion.x, currentAnnotatedPointList[i].cube.quaternion.y, currentAnnotatedPointList[i].cube.quaternion.z, currentAnnotatedPointList[i].cube.quaternion.w),
                        currentAnnotatedPointList[i].cube_8_corners[0],
                        currentAnnotatedPointList[i].cube_8_corners[1],
                        currentAnnotatedPointList[i].cube_8_corners[2],
                        currentAnnotatedPointList[i].cube_8_corners[3],
                        currentAnnotatedPointList[i].cube_8_corners[4],
                        currentAnnotatedPointList[i].cube_8_corners[5],
                        currentAnnotatedPointList[i].cube_8_corners[6],
                        currentAnnotatedPointList[i].cube_8_corners[7]
                    );

                    annotatedList.push(annotated_object);
                    //console.log("Uploaded: " + annotated_object.rm_id + ", " + annotated_object.object_name);
                }

                //  Upload the data
                let annotatedObjectList = new AnnotatedObjectList(annotatedList);
                //const response = await userTasksService.task_3d_rm_upload_annotation(refBlueprintID, annotatedObjectList);
                await userTasksService.task_3d_rm_upload_annotation(refBlueprintID, annotatedObjectList);
            }
        }
    }

    /**
     * Reset Scene
     *
     * Reset the point-cloud scene when the user finishes annotation task.
     * 1. Removes the Cube (if any)
     * 2. Removes the existing Point-Cloud Scene of the current task
     */
    function resetScene() {

        //console.log("Reset Scene Triggered!");

        //  Reset orbit cone
        orbitControl.target.set(0.0, 0.0, 0.0);
        adjustCone(0.0, 0.0, 0.0);

        //  Remove any existing cube on screen
        deleteAllCubes();

        //  Remove the loaded Point-Cloud
        const object = scene.getObjectByName('roomPCD');
        scene.remove(object);

        //  Redraw the ThreeJS screen
        renderer.render(scene, camera);

        //  Resets action log
        if (actionLogger) {
            actionLogger.reset();
            actionLogger.push(PCDActionLogger.ACTION_INIT, "INIT", null);
        }
    }

    /**
     * Load The Next Point-Cloud Scene
     * 1. Given the current Task Index (Which task the user is going to next)
     * 2. Load the Point-Cloud data path from the data (loaded from JSON)
     * 3. Resets and render the scene.
     *
     * @param pcdIndex number The index of the task the user is going to next. (In Sequence from 0,1,2,3...)
     */
    function loadNextPCD(pcdIndex: number) {

        //  Load the Point-Cloud
        if (setOfPCD) {

            tilesRenderer = new TilesRenderer(setOfPCD[pcdIndex].rm_pcd_url);
            //tilesRenderer = new DebugTilesRenderer(setOfPCD[pcdIndex].rm_pcd_url);
            tilesRenderer.fetchOptions.mode = 'cors';
            tilesRenderer.lruCache.minSize = 900;
            tilesRenderer.lruCache.maxSize = 1300;
            tilesRenderer.errorTarget = 12;
            tilesRenderer.setCamera(camera);
            tilesRenderer.setResolutionFromRenderer(camera, renderer);

            tilesRenderer.group.name = "roomPCD";
            scene.add(tilesRenderer.group);

            //  Capture the lazy-downloaded Points
            downloadPointsSet = [];
            tilesRenderer.onLoadModel = (scene: THREE.Object3D<THREE.Event>) => {
                //console.log("onLoadModel");
                scene.traverse(c => {

                    //console.log(c);
                    let e: THREE.Points = (c as THREE.Points);
                    downloadPointsSet.push(e);
                });
            };

            if (renderer) {
                //requestAnimationFrame( render_init );

                // The camera matrix is expected to be up-to-date
                // before calling tilesRenderer.update
                camera.updateMatrixWorld();
                tilesRenderer.update();
                renderer.render(scene, camera);
            }
        }
    }

    /**
     * Add The Annotation Cube to the scene
     * 1. This adds the annotation cube to the scene.
     * 2. This cube will bound all the point-cloud's point within in.
     * 3. This will represent the selection of point-cloud that the user wants.
     * 4. The user will then give an annotation text to this selection.
     * 5. When adding the cube, cube controls are added to allow cube manipulation in the scene (move/resize/rotate/translate)
     */
    async function onClickAddCube() {
        //console.log(camera);

        if (canvas) {
            //  Check if previous Annotation is completed yet before adding new one in.
            if (listData && listData.includes(STR_UNLABELED_CUBE)) {
                alert("Please complete previous annotation before adding a new cube!")
                return;
            }

            //  Display the Cube Context Menu
            //  Hide Cube Context Menu (Not used: Can be enabled)
            // let cubeContextMenu = document.getElementById('cubeContextMenu')
            // if (cubeContextMenu)
            //     cubeContextMenu.style.visibility = 'visible';

            let cubeSizeWidthText: any = document.getElementById("cubeSizeWidth");
            cubeSizeWidthText.value = "1.0";
            cubeWidth = 1.0;

            let cubeSizeHeightText: any = document.getElementById("cubeSizeHeight");
            cubeSizeHeightText.value = "1.0";
            cubeHeight = 1.0;

            let cubeSizeDepthText: any = document.getElementById("cubeSizeDepth");
            cubeSizeDepthText.value = "1.0";
            cubeDepth = 1.0;

            //  Remove any existing cube on screen
            // deleteCube();

            //  Add cube
            addCube();

            //  Clear Annotation Text Input
            let inputCubeAnnotationHandle = document.getElementById("in_cubeAnnotation") as HTMLInputElement;
            if (inputCubeAnnotationHandle)
                inputCubeAnnotationHandle.value = STR_UNLABELED_CUBE;
            cubeAnnotation = STR_UNLABELED_CUBE;
            selected_old_annotation = STR_UNLABELED_CUBE;

            //  Set up the Blank cube
            setup_blank_cube();
        }
    }

    /**
     * Show the Annotated Items in the List (UI)
     *
     * Given a list of annotation, do the following:
     * 1. Add them to a list
     * 2. Add a CheckBox and Delete button
     * 3. Package in onClick, Double Click, CheckBox click and delete button click
     *
     * OnClick - Selects the annotation item in the list and shows the properties menu
     * OnDoubleClick - Activates inline edit function to change the annotation text
     * CheckBox Click - Shows/Hide the cube in the scene
     * Delete Click - Deletes the cube in the scene.
     *
     * @param locaListData Array List of Data to be shown in the list
     */
    function showAnnotatedItemsInList(locaListData: any[]) {

        if (locaListData) {
            //  Clear the list
            let myList = document.getElementById('cubeAnnotatedList');
            if (myList)
                myList.innerHTML = '';

            //  Add into the list
            locaListData.forEach((item) => {
                let li = document.createElement("li");
                li.addEventListener('click', onClickAnnotationList);
                li.addEventListener('dblclick', onDoubleClickAnnotationList);
                li.innerText = item;
                li.classList.add('hover:bg-[#FFFFFF]');

                //  Create the Div=CheckBox + DeleteButton
                let {itemDiv, checkbox, deleteButton} = createDivAndButton();

                //  Append it to Div, then to the list
                itemDiv.append(checkbox, li, deleteButton);
                cubeAnnotationList.appendChild(itemDiv);

                //  Setup Checkbox OnClick
                checkbox.addEventListener('click', event => {
                    showHideCube(event);
                });

                //  Setup Delete Button OnClick
                deleteButton.addEventListener('click', event => {
                        li.removeEventListener('dblclick', onDoubleClickAnnotationList);

                        deleteListItem(event);
                    },
                    {once: true}
                );
            });
        }
    }

    /**
     * Double Click: On Annotation List
     *
     * This allows the user to edit the text item of the list. To change the value.
     *
     * @param event The double Click event
     */
    function onDoubleClickAnnotationList(event: any) {
        //  Double Click to edit

        //  Turn off keyboard listener: Why? Because the scene is also listening to the rotation/translation keypress. If you don't turn off, the scene will get triggered.
        window.removeEventListener("keydown", tjs_handle_keyboard);

        //  Get the value that is in the selected item (of the annotated list), create an input box, replace it in the list.
        //  This allows the user to now change the text, then append the saveItem function in (for click and keypress) to save the new text when the user is done.
        let item = event.target.innerHTML;
        let itemInput = document.createElement('input');
        itemInput.type = 'text';
        itemInput.value = item;
        itemInput.classList.add('edit');
        annotation_change_holder = item;
        itemInput.addEventListener('keypress', saveItem);
        itemInput.addEventListener('click', saveItem);
        event.target.parentNode.prepend(itemInput);
        event.target.remove();
        itemInput.select();
    }

    /**
     * Save Item (For onDoubleClickAnnotationList())
     *
     * This callback function saves the changed text that the user typed in.
     * Code Updates on:
     * - New Cube Untitled Text changed
     * - Existing text change
     * - No changes
     *
     * Notes: Because the callback is also hooked to keypress: The function gets triggered everytime key is pressed, hence, the need to check for keycodes.
     *
     * @param event The Event passed in onClick on Keypress
     */
    function saveItem(event: any) {
        //console.log("saveItem");
        //console.log(event);
        //console.log(annotation_change_holder);

        //  Checked if the mouse is clicked or the user has press the "Enter" key.
        if (event.target.value.length > 0 && (event.keyCode === 13 || event.type === 'click')) {

            //  Update the UI
            let li = document.createElement('li');
            //li.addEventListener('click', toggleDone);
            li.addEventListener('dblclick', onDoubleClickAnnotationList);
            li.textContent = event.target.value;
            event.target.parentNode.prepend(li);
            event.target.remove();

            //  Update the state if it is a new cube or existing cube
            if (annotation_change_holder.includes(STR_UNLABELED_CUBE) && !event.target.value.includes(STR_UNLABELED_CUBE)) {
                //console.log("saveItem 1");

                //  New Annotation
                cubeAnnotation = event.target.value;
                //console.log(cubeAnnotation);

                let inputCubeAnnotationHandle = document.getElementById("in_cubeAnnotation") as HTMLInputElement;
                if (inputCubeAnnotationHandle)
                    inputCubeAnnotationHandle.value = cubeAnnotation;

                selected_old_annotation = STR_UNLABELED_CUBE;

                //console.log(cubeAnnotation);
                //console.log(selected_old_annotation);

                onClickCompleteAnnotation();

            } else if (!annotation_change_holder.includes(STR_UNLABELED_CUBE) && event.target.value !== annotation_change_holder) {
                //  Change in Annotation
                //console.log("saveItem 2");
                //console.log(event.target.innerText);
                //console.log(annotation_change_holder);

                if (annotatedPointList) {
                    for (let i = 0; i < annotatedPointList.length; i++) {
                        if (annotatedPointList[i].annotation === annotation_change_holder) {
                            annotatedPointList[i].annotation = event.target.value;
                            break;
                        }
                    }

                    //  Push text to screen
                    let localListData: any[] = [];
                    for (let i = 0; i < annotatedPointList.length; i++) {
                        localListData.push(annotatedPointList[i].annotation);
                    }
                    listData = localListData;
                    showAnnotatedItemsInList(listData);
                }
            } else {
                //  Reset the List if anything else
                if (annotatedPointList) {

                    //  Push text to screen
                    let localListData: any[] = [];
                    for (let i = 0; i < annotatedPointList.length; i++) {
                        localListData.push(annotatedPointList[i].annotation);
                    }
                    listData = localListData;
                    showAnnotatedItemsInList(listData);
                }
            }

            //  Turn on keyboard listener
            window.addEventListener("keydown", tjs_handle_keyboard);

        } else if (event.target.value.length === 0 && (event.keyCode === 13 || event.type === 'click')) {
            //  When there is no changes.

            let li = document.createElement('li');
            //li.addEventListener('click', toggleDone);
            li.addEventListener('dblclick', onDoubleClickAnnotationList);
            li.textContent = annotation_change_holder;
            event.target.parentNode.prepend(li);
            event.target.remove();

            if (annotatedPointList) {
                //  Push text to screen
                let localListData: any[] = [];
                for (let i = 0; i < annotatedPointList.length; i++) {
                    localListData.push(annotatedPointList[i].annotation);
                }
                listData = localListData;
                showAnnotatedItemsInList(listData);
            }

            //  Turn on keyboard listener
            window.addEventListener("keydown", tjs_handle_keyboard);
        }
    }

    /**
     * Show/Hide Cube Callback (for showAnnotatedItemsInList())
     *
     * When user clicks on the checkbox, this callback function shows or hide the cube in the scene.
     * The cube selected is based on what the annotated text is being clicked on.
     *
     * @param event Event fired from onClick on checkbox
     */
    function showHideCube(event: any) {
        //console.log("showHideCube");
        //console.log(event);
        //console.log(event.target.checked);
        //console.log(event.target.parentElement.children[1].firstChild.nodeValue);

        if (annotatedPointList) {

            //  Iterate through the annotated list and compare the selected cube (based on the annotated text given in the event)
            for (let i = 0; i < annotatedPointList.length; i++) {
                //console.log("Index: " + i);
                //console.log(annotatedPointList[i].annotation);
                //console.log(event.target.parentElement.children[1].firstChild.nodeValue);

                //  If found, check the checked setting, render or hide the cube.
                if (annotatedPointList[i].annotation.includes(event.target.parentElement.children[1].firstChild.nodeValue)) {
                    //console.log("inside");

                    if (event.target.checked) {
                        //console.log("checked");

                        const localCube = annotatedPointList[i].cube;

                        //let localCubeControl: TransformControls= new TransformControls(camera, renderer.domElement);
                        let localCubeControl = getCubeControl(annotatedPointList[i].cube);

                        selected_cubeControl = localCubeControl;

                        scene.add(localCube);
                        scene.add(localCubeControl);

                        renderer.render(scene, camera);
                    } else {
                        //console.log("unchecked");

                        //  Remove any existing cube on screen
                        let cubeHandle = scene.getObjectByName(annotatedPointList[i].cube.name);
                        if (cubeHandle) {
                            const cubeControlHandle = scene.getObjectByName(annotatedPointList[i].cube.name + "_control");
                            scene.remove(cubeHandle);
                            scene.remove(cubeControlHandle);

                            //  Redraw the ThreeJS screen
                            renderer.render(scene, camera);
                        }
                    }
                }
            }
        }
    }

    /**
     * Delete the Item in Annotation List Callback (for showAnnotatedItemsInList())
     *
     * When user clicks on the Red-X, deletes the selected item (text) from the list as well as the corresponding cube in the scene.
     *
     * @param event Event fired from onClick on Red-X
     */
    function deleteListItem(event: any) {
        //console.log("deleteListItem");
        //console.log(event);
        //console.log(event.target.parentElement.children[1].firstChild.nodeValue);

        //  Remove the element
        event.target.parentElement.remove();

        //  If it is still untitled, remove as per cancel operations
        if (event.target.parentElement.children[1].firstChild.nodeValue.includes(STR_UNLABELED_CUBE)) {
            onClickCancelAnnotation();
        } else {
            //  If not
            if (annotatedPointList) {
                //  Remove any existing cube on screen (that matches the selected text)
                for (let i = 0; i < annotatedPointList.length; i++) {
                    if (annotatedPointList[i].annotation === event.target.parentElement.children[1].firstChild.nodeValue) {
                        deleteCube(annotatedPointList[i].cube.name);
                        break;
                    }
                }

                //  Purge from in-memory list
                let newList: MyAnnotationListItem[] = [];
                for (let i = 0; i < annotatedPointList.length; i++) {
                    if (annotatedPointList[i].annotation !== event.target.parentElement.children[1].firstChild.nodeValue)
                        newList.push(annotatedPointList[i]);
                }
                annotatedPointList = newList;

                //  Update and Show the list
                let updatedListData: any[] = [];
                annotatedPointList.forEach((item) => {
                    updatedListData.push(item.annotation);
                });
                listData = updatedListData;
                showAnnotatedItemsInList(listData);
            }
        }
    }

    /**
     * Creates the HTML Div and Button Element
     * Called under showAnnotatedItemsInList()
     *
     * Generates the Div, Checkbox and Delete button used for each Annotation Item in the Annotation List.
     */
    function createDivAndButton() {
        let itemDiv = document.createElement('div');
        itemDiv.classList.add('flex', 'justify-between',);

        let checkbox: HTMLInputElement = document.createElement("INPUT") as HTMLInputElement;
        checkbox.setAttribute("type", "checkbox");
        checkbox.defaultChecked = true;

        let deleteButton = document.createElement('button');
        deleteButton.innerHTML = 'x';
        deleteButton.classList.add('bg-red-500', 'hover:bg-red-700', 'text-white', 'font-bold');

        return {
            itemDiv,
            checkbox,
            deleteButton
        };
    }


    /**
     * Add the Annotation Cube into the Scene
     *
     * Process:
     * 1. Creates Geometry (Dimension of the cube)
     * 2. Creates a default Material for the cube (Color, opacity, etc.)
     * 3. Adjust the location in front of the viewing camera
     * 4. Render to screen.
     * Gives a generic name "cube01" (which is renamed later)
     */
    function addCube() {

        const geometry = new THREE.BoxGeometry(1, 1, 1);
        geometry.center();

        const material = new THREE.MeshBasicMaterial({color: 0x00ff00, opacity: MATERIAL_OPACITY, transparent: true});
        //const cube = new THREE.Mesh( geometry, material );
        //cube = new THREE.Mesh(geometry, material);
        const projected_cube = new THREE.Mesh(geometry, material);
        projected_cube.name = "pcub";

        //  Insert Cube in front of the camera. Ref: https://stackoverflow.com/questions/17218054/how-to-put-an-object-in-front-of-camera-in-three-js
        projected_cube.position.copy(camera.position);
        projected_cube.rotation.copy(camera.rotation);
        projected_cube.updateMatrix();
        projected_cube.translateZ(-2);

        //  Capture the position (so that cube is level with the X-Z plane
        const localCube = new THREE.Mesh(geometry, material);
        localCube.name = "cube01";
        localCube.position.copy(projected_cube.position);

        selectedCube = localCube;
        //setCube(localCube);

        //Show the cube position
        let cubePosXText: any = document.getElementById("cubePos_X");
        cubePosXText.value = Math.round(localCube.position.x * 1000) / 1000;

        let cubePosYText: any = document.getElementById("cubePos_Y");
        cubePosYText.value = Math.round(localCube.position.y * 1000) / 1000;

        let cubePosZText: any = document.getElementById("cubePos_Z");
        cubePosZText.value = Math.round(localCube.position.z * 1000) / 1000;

        let localCubeControl = getCubeControl(localCube);

        selected_cubeControl = localCubeControl;

        scene.add(localCube);
        scene.add(localCubeControl);

        renderer.render(scene, camera);
    }

    /**
     * Given a Cube (Mesh) creates a Cube Controls (TransformControls) for it.
     *
     * This function generates new TransformControls for a cube (to allow the cube to be controlled/moved)
     * In addition, listeners for the controls:
     * - Change: On Change, re-renders the scene
     * - Dragging: On cube dragged, disable orbit control and refresh the captured 8 points of that cube.
     * - OnMouseDown: Selects the cube: Change color to yellow, select the correct annotation in the list and dimension menu
     *
     * @param target_cube Cube in THREE.Mesh type.
     */
    function getCubeControl(target_cube: THREE.Mesh) {

        let localCubeControl = new TransformControls(camera, renderer.domElement);
        localCubeControl.name = target_cube.name + "_control";

        localCubeControl.addEventListener('change', function () {
            renderer.render(scene, camera);
        });
        localCubeControl.addEventListener('dragging-changed', function (event) {
            //  This detects dragging of the cube, to prevent the screen from moving around when you are manipulating the cube
            // console.log("dragging-changed event");
            //console.log(event);
            orbitControl.enabled = !event.value;

            //cubePosition = cube.position;
            //console.log(cubePosition);

            if (orbitControl.enabled) {
                //console.log("dragging-changed event start");
                //  Update the state of the cube
                let cubeHandle = scene.getObjectByName(target_cube.name);
                if (cubeHandle) {
                    // console.log("Drag Update State of cube");
                    // console.log(cubeHandle);
                    updateStateOfCube(cubeHandle);

                    //  Store the cube state, Updating Undo/Redo Log
                    //actionLogger.printLog();

                    actionLogger.push(PCDActionLogger.ACTION_MOVE_CUBE, target_cube.name, cubeHandle.clone());
                }

                // Update Color Points
                colourPointsInCube(target_cube.name);
            }
        });
        localCubeControl.addEventListener('mouseDown', function () {
            // console.log("mouseDown");
            // console.log(localCubeControl.name);
            // console.log(target_cube.name);
            // console.log(flag_cube_in_translate_mode);

            if (flag_cube_in_translate_mode) {
                let localCube = scene.getObjectByName(target_cube.name);
                if (localCube) {
                    selectedCube = localCube;
                    // console.log(localCube);
                }
                let localCubeControl = scene.getObjectByName(target_cube.name + "_control");
                if (localCubeControl)
                    selected_cubeControl = localCubeControl;

                if (annotatedPointList) {
                    //  Load the selected cube annotation
                    let selected_item: string = "";
                    for (let i = 0; i < annotatedPointList.length; i++) {
                        if (annotatedPointList[i].cube.name === target_cube.name) {
                            selected_item = annotatedPointList[i].annotation;
                            break;
                        }
                    }

                    if (selected_item !== "")
                        loadItemInAnnotationList(selected_item);

                    //  Reset CubeControl & Color
                    resetCubeControlAndColor();

                    //  Display the Cube Context Menu
                    //  Hide Cube Context Menu (Not used: Can be enabled)
                    // let cubeContextMenu = document.getElementById('cubeContextMenu')
                    // if (cubeContextMenu)
                    //     cubeContextMenu.style.visibility = 'visible';

                    //  Set up the Cube Space parameters
                    loadCubeDimensionIntoUI();
                }

                //  Change cube color to selected
                if (localCube) {
                    selectedCube.material=new THREE.MeshBasicMaterial({color: 0xFFFF00, opacity: MATERIAL_OPACITY, transparent: true});

                    //(selectedCube.material as THREE.MeshBasicMaterial).color.setHex(0xFFFF00);

                    renderer.render(scene, camera);
                }
            }
        });

        //  Attach Cube Control to the cube
        localCubeControl.attach(target_cube);

        return localCubeControl;
    }

    /**
     * Given the cube + the downloaded Points
     * 1. Find the points inside the cube.
     * 2. Color the Points to highlight them
     *
     * @param cubeName The cube name.
     */
    function colourPointsInCube(cubeName: string) {

        //  Find the Points in the cube
        let pointsInBoxSet: number[] = [];
        let pointColorInBoxSet: number[] = [];
        downloadPointsSet.forEach(function (eachPointSet: THREE.Points<THREE.BufferGeometry>) {
            //console.log(eachPointSet);
            let boundingVolumePosition = new THREE.Vector3(eachPointSet.position.x, eachPointSet.position.y, eachPointSet.position.z);
            let [pointsInBox, pointColorInBox] = getAllPointsInCube(cubeName, eachPointSet, boundingVolumePosition);

            if (pointsInBox.length > 0) {
                pointsInBoxSet = pointsInBoxSet.concat(pointsInBox);
                pointColorInBoxSet = pointColorInBoxSet.concat(pointColorInBox);
            }
        });

        //  Remove any colored Points in existing Scene
        let oldPointsIfAvailable: THREE.Mesh = scene.getObjectByName("pointsInBox_" + cubeName)
        if (oldPointsIfAvailable)
            scene.remove(oldPointsIfAvailable)

        //  Set up the Points + Material
        const rPoints = new Float32Array(pointsInBoxSet);
        const rColor = new Float32Array(pointColorInBoxSet);
        //console.log(rPoints);
        //console.log(rColor);

        //  Reseed the scene
        let geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(rPoints, 3));
        geometry.setAttribute('color', new THREE.BufferAttribute(rColor, 3));

        const material = new THREE.PointsMaterial({color: 0x4B0082, size:0.01, opacity: 0.5, transparent: false});
        //const material = new THREE.MeshBasicMaterial({color: 0xFF00FF, opacity: 1.0, transparent: false});
        //const material = new THREE.MeshBasicMaterial({color: 0xFFFFFF, opacity: 1.0, transparent: false});
        //const material = new THREE.MeshBasicMaterial({color: 0xFF00FF});
        let pointsInBoxThreeJS = new THREE.Points(geometry, material);
        pointsInBoxThreeJS.name = "pointsInBox_" + cubeName;

        //  Add the coloured point in
        scene.add(pointsInBoxThreeJS);

        //  Re-render the scene
        scene_render();
    }


    /**
     * Update the status of the cube
     * 1. In memory holder.
     * 2. As well as the GUI component
     *
     * @param target_cube The THREE.Mesh of the given cube.
     */
    function updateStateOfCube(target_cube: THREE.Mesh) {

        //  Update the state of the cube
        if (annotatedPointList && setOfPCD) {
            //  Get all 8 points of a cube
            getAll8PointsCornerOfCube(target_cube.name);

            //  Store all 8 points to holder.
            const cube_8_corners: THREE.Vector3[] = [];
            cube_8_corners.push(cubeCorner1);
            cube_8_corners.push(cubeCorner2);
            cube_8_corners.push(cubeCorner3);
            cube_8_corners.push(cubeCorner4);
            cube_8_corners.push(cubeCorner5);
            cube_8_corners.push(cubeCorner6);
            cube_8_corners.push(cubeCorner7);
            cube_8_corners.push(cubeCorner8);

            //  Load the selected cube annotation
            for (let i = 0; i < annotatedPointList.length; i++) {
                if (annotatedPointList[i].cube.name === target_cube.name) {
                    annotatedPointList[i].cube_8_corners = cube_8_corners;
                    //console.log(annotatedPointList[i]);
                    break;
                }
            }
            addAnnotationDict(setOfPCD[currentQnIndex].rm_id, annotatedPointList);

            //Show the cube position
            let cubePosXText: any = document.getElementById("cubePos_X");
            if (cubePosXText)
                cubePosXText.value = Math.round(selectedCube.position.x * 1000) / 1000;

            let cubePosYText: any = document.getElementById("cubePos_Y");
            if (cubePosYText)
                cubePosYText.value = Math.round(selectedCube.position.y * 1000) / 1000;

            let cubePosZText: any = document.getElementById("cubePos_Z");
            if (cubePosZText)
                cubePosZText.value = Math.round(selectedCube.position.z * 1000) / 1000;
        }
    }

    /**
     * Load Cube Dimensions into UI TextFields
     * (Part of function getCubeControl())
     *
     * Given the selected cube, extract the cube's Width, Height and Depth
     * Then shows these values into the respected text field.
     *
     * Notes:
     * 1. As the user now can click on different cubes on the screen, on selection, the appropriate values of the selected cube must be loaded.
     */
    function loadCubeDimensionIntoUI() {

        //  Set up the Cube Space parameters
        //  Update the text
        let cubeSizeWidthText: any = document.getElementById("cubeSizeWidth");
        cubeSizeWidthText.value = (selectedCube.geometry as BoxGeometry).parameters.width;

        let cubeSizeHeightText: any = document.getElementById("cubeSizeHeight");
        cubeSizeHeightText.value = (selectedCube.geometry as BoxGeometry).parameters.height;

        let cubeSizeDepthText: any = document.getElementById("cubeSizeDepth");
        cubeSizeDepthText.value = (selectedCube.geometry as BoxGeometry).parameters.depth;

        //Show the cube position
        let cubePosXText: any = document.getElementById("cubePos_X");
        cubePosXText.value = Math.round(selectedCube.position.x * 1000) / 1000;

        let cubePosYText: any = document.getElementById("cubePos_Y");
        cubePosYText.value = Math.round(selectedCube.position.y * 1000) / 1000;

        let cubePosZText: any = document.getElementById("cubePos_Z");
        cubePosZText.value = Math.round(selectedCube.position.z * 1000) / 1000;
    }

    /**
     * Reset Cube-Control and the Color
     *
     * As the user can now click on different cubes, once the user jumps to a different cube, we have to reset all the cubes.
     * So as not to confuse between the toggles and cube selection.
     */
    function resetCubeControlAndColor() {
        //console.log("resetCubeControlAndColor");

        //  Reset Cube Control
        if (annotatedPointList) {

            for (let i = 0; i < annotatedPointList.length; i++) {

                //console.log("resetCubeControlAndColor Cube: " + annotatedPointList[i].cube.name);

                let loop_cubeControl = scene.getObjectByName(annotatedPointList[i].cube.name + "_control");
                if (loop_cubeControl) {
                    //console.log("resetCubeControlAndColor in loop_cubeControl");

                    loop_cubeControl.setMode('translate');
                    loop_cubeControl.showX = true;
                    loop_cubeControl.showY = true;
                    loop_cubeControl.showZ = true;
                    //loop_cubeControl.setSpace('world');

                    if (selected_cubeControl.space === 'local')
                        loop_cubeControl.setSpace('local');
                    else
                        loop_cubeControl.setSpace('world');
                }
                // flag_cube_in_translate_mode=false;
                // ui_handle_keyboard(69);

                let localCube = scene.getObjectByName(annotatedPointList[i].cube.name);
                if (localCube) {
                    //console.log("resetCubeControlAndColor in localCube");

                    // (annotatedPointList[i].cube.material as THREE.MeshBasicMaterial).color.setHex(0x00ff00);
                    //(localCube.material as THREE.MeshBasicMaterial).color.setHex(0x00ff00);
                    localCube.material=new THREE.MeshBasicMaterial({color: 0x00ff00, opacity: MATERIAL_OPACITY, transparent: true});

                    //  Redraw the ThreeJS screen
                    renderer.render(scene, camera);
                }
            }
        }
    }

    /**
     * Delete the Annotation Cube from the Scene.
     *
     * @param strCubeName The Cube name to be deleted. Default name cube01
     */
    function deleteCube(strCubeName: String = 'cube01') {

        //  Remove any existing cube on screen
        const cubeHandle = scene.getObjectByName(strCubeName);
        if (cubeHandle) {
            const cubeControlHandle = scene.getObjectByName(strCubeName + "_control");
            scene.remove(cubeHandle);
            scene.remove(cubeControlHandle);

            //  Remove any colored Points in existing Scene
            let oldPointsIfAvailable: THREE.Mesh = scene.getObjectByName("pointsInBox_" + strCubeName);
            if (oldPointsIfAvailable)
                scene.remove(oldPointsIfAvailable)

            //  Redraw the ThreeJS screen
            renderer.render(scene, camera);
        }
    }

    /**
     * Delete All Annotation Cube from the Scene.
     *
     * Notes: Delete cubes from the scene, does not delete data.
     */
    function deleteAllCubes() {

        if (annotatedPointList) {
            for (let i = 0; i < annotatedPointList.length; i++) {
                //  Remove any existing cube on screen
                const cubeHandle = scene.getObjectByName(annotatedPointList[i].cube.name);
                if (cubeHandle) {
                    const cubeControlHandle = scene.getObjectByName(annotatedPointList[i].cube.name + "_control");
                    scene.remove(cubeHandle);
                    scene.remove(cubeControlHandle);

                    //  Remove any colored Points in existing Scene
                    let oldPointsIfAvailable: THREE.Mesh = scene.getObjectByName("pointsInBox_" + annotatedPointList[i].cube.name)
                    if (oldPointsIfAvailable)
                        scene.remove(oldPointsIfAvailable)
                }
            }

            //  Redraw the ThreeJS screen
            renderer.render(scene, camera);
        }
    }

    /**
     * Add All Cubes back into scene
     */
    function addAllCubes() {
        //console.log("addAllCubes");

        if (annotatedPointList) {
            //console.log("addAllCubes - annotatedPointList");

            for (let i = 0; i < annotatedPointList.length; i++) {

                let localCube = annotatedPointList[i].cube.clone();
                if (localCube) {
                    selectedCube = localCube;
                    scene.add(localCube);

                    let localCubeControl = getCubeControl(localCube);
                    if (localCubeControl) {
                        selected_cubeControl = localCubeControl;
                        scene.add(localCubeControl);
                    }
                }

                //  Redraw the ThreeJS screen
                renderer.render(scene, camera);
            }
        }
    }

    /**
     * General Cube Resize Function
     * 1. Generalized function to cover all the resize, as it is essentially the same code
     * 2. Give the Axis (width, height or depth).
     * 3. Increase/Decrease the cube size in that axis by 0.1
     * 4. Clear the cube and re-renders it on screen again.
     * 5. Also updates the text box
     *
     * @param axis (width, height or depth)
     * @param localSizeHandle The document handle
     */
    function onResizeCube(axis: string, localSizeHandle: any) {

        if (canvas) {
            //console.log(cube)
            if (selectedCube) {

                if (localSizeHandle > 0.0) {

                    if (axis === "width")
                        selectedCube.geometry = new THREE.BoxGeometry(localSizeHandle, cubeHeight, cubeDepth);
                    else if (axis === "height")
                        selectedCube.geometry = new THREE.BoxGeometry(cubeWidth, localSizeHandle, cubeDepth);
                    else
                        selectedCube.geometry = new THREE.BoxGeometry(cubeWidth, cubeHeight, localSizeHandle);

                    renderer.render(scene, camera);

                    if (axis === "width")
                        cubeWidth = localSizeHandle;
                    else if (axis === "height")
                        cubeHeight = localSizeHandle;
                    else
                        cubeDepth = localSizeHandle;

                    //  Update the text
                    if (axis === "width") {
                        let cubeSizeWidthText: any = document.getElementById("cubeSizeWidth");
                        cubeSizeWidthText.value = cubeWidth;
                    } else if (axis === "height") {
                        let cubeSizeHeightText: any = document.getElementById("cubeSizeHeight");
                        cubeSizeHeightText.value = cubeHeight;
                    } else {
                        let cubeSizeDepthText: any = document.getElementById("cubeSizeDepth");
                        cubeSizeDepthText.value = cubeDepth;
                    }

                    actionLogger.push(PCDActionLogger.ACTION_SIZE_CUBE, selectedCube.name, selectedCube.clone());

                    // Update Color Points
                    colourPointsInCube(selectedCube.name);
                }
            }
        }
    }

    /**
     * Reset the Cube Dimension Labels
     *
     * Notes: Reset the Width, Height and Depth to 1.0
     */
    function onResizeResetCube() {

        if (canvas) {
            //console.log(cube)
            cubeWidth = 1.0;
            let cubeSizeWidthText: any = document.getElementById("cubeSizeWidth");
            cubeSizeWidthText.value = cubeWidth;

            cubeHeight = 1.0;
            let cubeSizeHeightText: any = document.getElementById("cubeSizeHeight");
            cubeSizeHeightText.value = cubeHeight;

            cubeDepth = 1.0;
            let cubeSizeDepthText: any = document.getElementById("cubeSizeDepth");
            cubeSizeDepthText.value = cubeDepth;
        }
    }

    /**
     * OnChange When (Selected) Cube's Size Changed: Width
     *
     * This is triggered when the user click on the cube size: Width
     * This function is then called the common onResizeCube()
     *
     */
    function onChangeCubeWidth() {
        //console.log(e);
        let docHandle: any = document.getElementById("cubeSizeWidth");
        //console.log(cubeSizeHeightText.value);

        onResizeCube("width", docHandle.value)
    }

    /**
     * OnChange When (Selected) Cube's Size Changed: Height
     *
     * This is triggered when the user click on the cube size: Height
     * This function is then called the common onResizeCube()
     *
     */
    function onChangeCubeHeight() {
        //console.log(e);
        let docHandle: any = document.getElementById("cubeSizeHeight");
        //console.log(cubeSizeHeightText.value);

        onResizeCube("height", docHandle.value)
    }

    /**
     * OnChange When (Selected) Cube's Size Changed: Depth
     *
     * This is triggered when the user click on the cube size: Depth
     * This function is then called the common onResizeCube()
     *
     */
    function onChangeCubeDepth() {
        //console.log(e);
        let docHandle: any = document.getElementById("cubeSizeDepth");
        //console.log(cubeSizeHeightText.value);

        onResizeCube("depth", docHandle.value)
    }

    /**
     * OnChange When (Selected) Cube's Position Changed
     *
     * This is triggered when the user click on the movement in the X,Y,Z axis.
     * This function is a common function called by either the click on the X,Y,Z position text boxes (cubePos_X, cubePos_Y, cubePos_Z)
     *
     */
    function onChangeCubePosition() {
        //console.log(e);
        let docHandleX: any = document.getElementById("cubePos_X");
        let docHandleY: any = document.getElementById("cubePos_Y");
        let docHandleZ: any = document.getElementById("cubePos_Z");
        //console.log(cubeSizeHeightText.value);

        //  Check which item is triggered, check against its previous stored value.
        //  Update its stored value if changed.
        let final_x: number = selectedCube.position.x;
        if (docHandleX)
            if (final_x != docHandleX.value)
                final_x = Number(docHandleX.value);

        let final_y: number = selectedCube.position.y;
        if (docHandleY)
            if (final_y != docHandleY.value)
                final_y = Number(docHandleY.value);

        let final_z: number = selectedCube.position.z;
        if (docHandleZ)
            if (final_z != docHandleZ.value)
                final_z = Number(docHandleZ.value);

        // console.log("onChangeCubePosition");
        // console.log(final_x);
        // console.log(final_y);
        // console.log(final_z);

        //  Update the cube's position
        selectedCube.position.copy(new THREE.Vector3(final_x, final_y, final_z));

        //  Update the cube's state (in memory)
        updateStateOfCube(selectedCube);

        //  Store the cube state, Updating Undo/Redo Log
        actionLogger.push(PCDActionLogger.ACTION_MOVE_CUBE, selectedCube.name, selectedCube.clone());

        //  Render on screen
        renderer.render(scene, camera);

        // Update Color Points (encompassed in the cube)
        colourPointsInCube(selectedCube.name);
    }

    /**
     * Once the Selection Cube is set in the 3D Scene, get the 8 points of the cube
     *
     * William Notes:
     * 1. As of dev(Jan 2023), there is no API call get 8 points of cube in threeJS.
     * 2. The Points have to be calculated by in this function.
     * 3. We have to use the Cube's Width, Height and Depth to calculate the 8 points.
     * 4. WARNING: This calculated 8 points is based on location of cube in X,Y,Z axis without the rotation transform.
     * 5. Once the 8 points is calculated, we have to apply quaternion to the 8 points to calculate the rotated transform.
     * 6. Note also, the transform rotates the points, the center of the cube may be shifted.
     * 7. Hence, after rotation, we have to check if the center is shifted, if yes, shifted the rotated 8 points back to its original location.
     */
    function getAll8PointsCornerOfCube(cube_name: string) {

        let cubeObj: THREE.Mesh = scene.getObjectByName(cube_name);
        //cube.updateMatrixWorld(true); // This might be necessary if box is moved
        cubeObj.updateMatrixWorld(true); // This might be necessary if box is moved

        //console.log("Cube Position");
        let newPos = cubeObj.position;
        //console.log(cubeObj.position);
        //let wPos = new THREE.Vector3();
        //cubeObj.worldToLocal(newPos);
        //cubeObj.getWorldPosition(wPos)
        //console.log(wPos);
        //pointCloud.worldToLocal(newPos)
        //console.log(newPos);

        //  Get the 8 corners of the cube
        const corner1 = new THREE.Vector3((newPos.x - cubeWidth / 2), (newPos.y - cubeHeight / 2), (newPos.z - cubeDepth / 2));
        const corner2 = new THREE.Vector3((newPos.x + cubeWidth / 2), (newPos.y - cubeHeight / 2), (newPos.z - cubeDepth / 2));
        const corner3 = new THREE.Vector3((newPos.x - cubeWidth / 2), (newPos.y + cubeHeight / 2), (newPos.z - cubeDepth / 2));
        const corner4 = new THREE.Vector3((newPos.x + cubeWidth / 2), (newPos.y + cubeHeight / 2), (newPos.z - cubeDepth / 2));

        const corner5 = new THREE.Vector3((newPos.x - cubeWidth / 2), (newPos.y - cubeHeight / 2), (newPos.z + cubeDepth / 2));
        const corner6 = new THREE.Vector3((newPos.x + cubeWidth / 2), (newPos.y - cubeHeight / 2), (newPos.z + cubeDepth / 2));
        const corner7 = new THREE.Vector3((newPos.x - cubeWidth / 2), (newPos.y + cubeHeight / 2), (newPos.z + cubeDepth / 2));
        const corner8 = new THREE.Vector3((newPos.x + cubeWidth / 2), (newPos.y + cubeHeight / 2), (newPos.z + cubeDepth / 2));

        //  Does not work
        // corner1.applyMatrix4(cube.matrixWorld);
        // corner2.applyMatrix4(cube.matrixWorld);
        // corner3.applyMatrix4(cube.matrixWorld);
        // corner4.applyMatrix4(cube.matrixWorld);
        //
        // corner5.applyMatrix4(cube.matrixWorld);
        // corner6.applyMatrix4(cube.matrixWorld);
        // corner7.applyMatrix4(cube.matrixWorld);
        // corner8.applyMatrix4(cube.matrixWorld);

        //  Work but position may have offset
        corner1.applyQuaternion(cubeObj.quaternion);
        corner2.applyQuaternion(cubeObj.quaternion);
        corner3.applyQuaternion(cubeObj.quaternion);
        corner4.applyQuaternion(cubeObj.quaternion);

        corner5.applyQuaternion(cubeObj.quaternion);
        corner6.applyQuaternion(cubeObj.quaternion);
        corner7.applyQuaternion(cubeObj.quaternion);
        corner8.applyQuaternion(cubeObj.quaternion);

        //  Store to Global
        cubeCorner1 = corner1;
        cubeCorner2 = corner2;
        cubeCorner3 = corner3;
        cubeCorner4 = corner4;
        cubeCorner5 = corner5;
        cubeCorner6 = corner6;
        cubeCorner7 = corner7;
        cubeCorner8 = corner8;

        //  Rotated 8 corners
        // console.log("[" + corner1.x + "," +   corner1.y + "," +   corner1.z + "]," +
        //     "[" + corner2.x + "," +    corner2.y + "," +   corner2.z + "]," +
        //     "[" + corner3.x + "," +     corner3.y + "," +  corner3.z + "]," +
        //     "[" + corner4.x + "," +    corner4.y + "," +  corner4.z + "]," +
        //     "[" + corner5.x + "," +     corner5.y + "," +   corner5.z + "]," +
        //     "[" + corner6.x + "," +    corner6.y + "," +   corner6.z + "]," +
        //     "[" + corner7.x + "," +     corner7.y + "," +  corner7.z + "]," +
        //     "[" + corner8.x + "," +    corner8.y + "," +  corner8.z + "],");

        //  Calculate the rotated Mid-Point
        let mid_x = (corner1.x + corner2.x + corner3.x + corner4.x + corner5.x + corner6.x + corner7.x + corner8.x) / 8;
        let mid_y = (corner1.y + corner2.y + corner3.y + corner4.y + corner5.y + corner6.y + corner7.y + corner8.y) / 8;
        let mid_z = (corner1.z + corner2.z + corner3.z + corner4.z + corner5.z + corner6.z + corner7.z + corner8.z) / 8;
        //console.log("Mid1: " + mid_x + ", " + mid_y + ", " + mid_z);

        //  Check if rotated mid-point matches up with the original mid-point, if not move all 4 corners back to original point.
        //  Not sure why three-js rotation matrix will mess up the mid-point
        let dif_x = mid_x - newPos.x;
        let dif_y = mid_y - newPos.y;
        let dif_z = mid_z - newPos.z;
        if (dif_x === 0.0 && dif_y === 0.0 && dif_z === 0.0) {
            console.log("No translation needed.");
        } else {
            //  Mid-point shifted
            //console.log("Dif: " + dif_x + ", " + dif_y + ", " + dif_z);
            const diff_vec = new THREE.Vector3(dif_x, dif_y, dif_z)

            corner1.sub(diff_vec);
            corner2.sub(diff_vec);
            corner3.sub(diff_vec);
            corner4.sub(diff_vec);
            corner5.sub(diff_vec);
            corner6.sub(diff_vec);
            corner7.sub(diff_vec);
            corner8.sub(diff_vec);
        }

        //  Final rotated corners
        // console.log("[" + corner1.x + "," +   corner1.y + "," +   corner1.z + "]," +
        //     "[" + corner2.x + "," +    corner2.y + "," +   corner2.z + "]," +
        //     "[" + corner3.x + "," +     corner3.y + "," +  corner3.z + "]," +
        //     "[" + corner4.x + "," +    corner4.y + "," +  corner4.z + "]," +
        //     "[" + corner5.x + "," +     corner5.y + "," +   corner5.z + "]," +
        //     "[" + corner6.x + "," +    corner6.y + "," +   corner6.z + "]," +
        //     "[" + corner7.x + "," +     corner7.y + "," +  corner7.z + "]," +
        //     "[" + corner8.x + "," +    corner8.y + "," +  corner8.z + "],");
    }

    /**
     * OnChange Annotation
     * 1. Activates when the user enters text into the annotation textbox
     * 2. Store what the user types into the variable.
     * @param e
     */
    function onChangeAnnotation(e: any) {
        let inputCubeAnnotationHandle = document.getElementById("in_cubeAnnotation");

        cubeAnnotation = e.target.value;
        //console.log(cubeAnnotation);
        //console.log(e);

        if (inputCubeAnnotationHandle)
            inputCubeAnnotationHandle.textContent = cubeAnnotation;
    }

    function onKeypressAnnotation(e: any) {

        //console.log(e);

        if (e.code === 'Enter') {
            // console.log('do validate');
            // console.log("1." + STR_UNLABELED_CUBE);
            // console.log("2." + cubeAnnotation);
            // console.log("3." + selected_old_annotation);

            onClickCompleteAnnotation();
        }
    }

    /**
     * OnClick: Input Annotation (Properties Menu)
     *
     * Disables the THREE-JS Keyboard listener to prevent unwanted toggling when doing keypress.
     */
    function onClickAnnotation() {

        window.removeEventListener("keydown", tjs_handle_keyboard);
    }

    /**
     * OnClick: Completed Annotation
     * 1. Activates when the user clicks on the button to complete the annotation
     * 2. Gets all the Point-Cloud points under the cube, the user's annotation text and the cube's data (coordinates, size & transform)
     * 3. Stores the items in 2 list: A in-memory list and the other is the UI List on the page.
     * 4. Once stored, remove the cube on screen.
     * 5. Renders the list on screen.
     *
     * William Notes: Why is there an In-Memory List? This list is for you to upload to the backend. This template does not have a backend.
     * Once the user completes a task and moves on to the next. The interface should upload the user's annotation to the backend, hence, the in-memory list is for the that purpose.
     */
    function onClickCompleteAnnotation() {

        if (canvas && setOfPCD) {
            //console.log("canvas && setOfPCD");

            if (selected_old_annotation) {
                //console.log("selected_old_annotation");
                //console.log(selected_old_annotation);

                let selectedAnnotation: any;
                if (annotatedPointList) {
                    for (let i = 0; i < annotatedPointList.length; i++) {
                        //console.log(annotatedPointList[i]);
                        if (annotatedPointList[i].annotation.includes(selected_old_annotation)) {
                            selectedAnnotation = annotatedPointList[i];
                            break;
                        }
                    }
                }

                if (selectedAnnotation) {
                    //console.log("selectedAnnotation");

                    const cubeHandle = scene.getObjectByName(selectedAnnotation.cube.name);
                    if (cubeHandle) {
                        //console.log("cubeHandle");

                        //  Hide Cube Context Menu (Not used: Can be enabled)
                        // let cubeContextMenu = document.getElementById('cubeContextMenu')
                        // if (cubeContextMenu)
                        //     cubeContextMenu.style.visibility = 'hidden';

                        let inputCubeAnnotationHandle = document.getElementById("in_cubeAnnotation") as HTMLInputElement;
                        if (inputCubeAnnotationHandle)
                            cubeAnnotation = inputCubeAnnotationHandle.value;

                        //console.log("cubeAnnotation: " + cubeAnnotation);

                        window.addEventListener("keydown", tjs_handle_keyboard);

                        //  Get all Points within the cube
                        //getAllPointsInCube();

                        //  Get all 8 points of a cube
                        getAll8PointsCornerOfCube(selectedAnnotation.cube.name);

                        //  Store all 8 points to holder.
                        const cube_8_corners: THREE.Vector3[] = [];
                        cube_8_corners.push(cubeCorner1);
                        cube_8_corners.push(cubeCorner2);
                        cube_8_corners.push(cubeCorner3);
                        cube_8_corners.push(cubeCorner4);
                        cube_8_corners.push(cubeCorner5);
                        cube_8_corners.push(cubeCorner6);
                        cube_8_corners.push(cubeCorner7);
                        cube_8_corners.push(cubeCorner8);

                        //  Re-assign new name to cube
                        let newCube = cubeHandle.clone();
                        newCube.name = cubeAnnotation;

                        //  Prep the annotation entry to be saved
                        let annotatedEntry: MyAnnotationListItem = {
                            rm_id: setOfPCD[currentQnIndex].rm_id,
                            annotation: cubeAnnotation,
                            cube: newCube,
                            annotated_points: [],
                            cube_8_corners: cube_8_corners
                        };

                        //  ReDraw the cube with the new name
                        reDrawCube(cubeHandle, newCube);

                        //  Retool to use dict instead. (Store into inMemory List)
                        if (annotatedPointList === undefined) {
                            let localList: MyAnnotationListItem[] = [];
                            localList.push(annotatedEntry);
                            annotatedPointList = localList;
                            //setAnnotatedPointList(localList);
                        } else {

                            let localList: MyAnnotationListItem[] = [];
                            for (let i = 0; i < annotatedPointList.length; i++) {
                                //console.log(i + ": " + annotatedPointList[i].annotation + ".");
                                //console.log(i + ": " + selected_old_annotation + ".");
                                if (annotatedPointList[i].annotation === selected_old_annotation) {
                                    localList.push(annotatedEntry);
                                } else {
                                    localList.push(annotatedPointList[i]);
                                }
                            }
                            annotatedPointList = localList;

                        }
                        addAnnotationDict(setOfPCD[currentQnIndex].rm_id, annotatedPointList);

                        //  Push text to screen
                        let localListData: any[] = [];
                        for (let i = 0; i < annotatedPointList.length; i++) {
                            localListData.push(annotatedPointList[i].annotation);
                        }
                        listData = localListData;
                        showAnnotatedItemsInList(listData);

                        //  Update ActionLog of the name change in the
                        actionLogger.rename_cube_name(selectedAnnotation.cube.name, cubeAnnotation);
                    }
                }
            }
        }
    }

    /**
     * Setup Blank Cube
     *
     * Auxiliary function for OnClickAddCube()
     *
     * Once the cube is added (with defaults cube01), do a proper operation
     * 1. Rename the cube + control
     * 2. Get all corners of the cube and save it
     * 3. Save the entry into the memory store
     * 4. Display the default into the UI elements.
     */
    function setup_blank_cube() {

        if (canvas && setOfPCD) {

            const cubeHandle = scene.getObjectByName('cube01');
            if (cubeHandle) {

                let inputCubeAnnotationHandle = document.getElementById("in_cubeAnnotation") as HTMLInputElement;
                if (inputCubeAnnotationHandle)
                    cubeAnnotation = inputCubeAnnotationHandle.value;

                window.addEventListener("keydown", tjs_handle_keyboard);

                //  Get all Points within the cube
                //getAllPointsInCube();

                //  Get all 8 points of a cube
                getAll8PointsCornerOfCube('cube01');

                //  Store all 8 points to holder.
                const cube_8_corners: THREE.Vector3[] = [];
                cube_8_corners.push(cubeCorner1);
                cube_8_corners.push(cubeCorner2);
                cube_8_corners.push(cubeCorner3);
                cube_8_corners.push(cubeCorner4);
                cube_8_corners.push(cubeCorner5);
                cube_8_corners.push(cubeCorner6);
                cube_8_corners.push(cubeCorner7);
                cube_8_corners.push(cubeCorner8);

                //  With Annotation Properly Name the Cube
                cubeHandle.name = "cube_" + setOfPCD[currentQnIndex].rm_id + cube_counter;
                cube_counter++;

                //  Rename the Cube Control
                let localCubeControl = scene.getObjectByName("cube01_control");
                localCubeControl.name = cubeHandle.name + "_control";

                //  Set Selected Cube
                selectedCube = cubeHandle;
                selected_cubeControl = localCubeControl;

                //  Prep the annotation entry to be saved
                let annotatedEntry: MyAnnotationListItem = {
                    rm_id: setOfPCD[currentQnIndex].rm_id,
                    annotation: cubeAnnotation,
                    cube: cubeHandle.clone(),
                    annotated_points: [],
                    cube_8_corners: cube_8_corners
                };

                //  Retool to use dict instead. (Store into inMemory List)
                if (annotatedPointList === undefined) {
                    let localList: MyAnnotationListItem[] = [];
                    localList.push(annotatedEntry);
                    annotatedPointList = localList;
                    //setAnnotatedPointList(localList);
                } else {

                    let foundFlag: boolean = false;
                    for (let i = 0; i < annotatedPointList.length; i++) {
                        if (annotatedPointList[i].annotation === annotatedEntry.annotation)
                            foundFlag = true;
                    }

                    if (!foundFlag)
                        annotatedPointList.push(annotatedEntry);
                }
                addAnnotationDict(setOfPCD[currentQnIndex].rm_id, annotatedPointList);

                //  Push text to screen
                listData.push(cubeAnnotation);
                showAnnotatedItemsInList(listData);

                //  Updating Undo/Redo Log
                actionLogger.push(PCDActionLogger.ACTION_ADD_CUBE, cubeHandle.name, cubeHandle.clone());
            }
        }
    }

    /**
     * Add to the Annotation Key-Value Map/Dictionary
     * @param rm_id The numeric Room ID
     * @param annotatedList The Annotated List Where each item contains the annotation + the cube's location (in ThreeJS scene)
     */
    function addAnnotationDict(rm_id: number, annotatedList: MyAnnotationListItem[]) {

        if (annotatedPointDict.has(rm_id)) {
            annotatedPointDict.delete(rm_id);
            annotatedPointDict.set(rm_id, annotatedList);
        } else {
            annotatedPointDict.set(rm_id, annotatedList);
        }
    }

    /**
     * Cancel the Annotation
     * Allow the user to cancel the current annotation.
     */
    function onClickCancelAnnotation() {

        //  Hide Cube Context Menu (Not used: Can be enabled)
        // let cubeContextMenu = document.getElementById('cubeContextMenu')
        // if (cubeContextMenu)
        //     cubeContextMenu.style.visibility = 'hidden';

        window.removeEventListener("keydown", tjs_handle_keyboard);
        window.addEventListener("keydown", tjs_handle_keyboard);

        //inputCubeAnnotationHandle.value="";

        //  Delete the cube
        deleteCube();

        //  Reset Cube Dimensions
        onResizeResetCube();

        //  Remove the temp text
        if (listData) {
            let updatedListData: any[] = [];
            listData.forEach((item) => {
                //console.log(String(item));
                if (String(item) !== STR_UNLABELED_CUBE)
                    updatedListData.push(item);
            });

            //  Update the list
            listData = updatedListData;

            //  Show the list
            showAnnotatedItemsInList(listData);
        }
    }

    /**
     * OnClick: Annotation List
     * 1. Activates when the user clicks on the Annotation List
     * 2. Purpose: Allow the user to see what was annotated: Re-rendering the cube's position on scene.
     * 3. Based on which item in the list the user clicks on, loads the cube and renders the cube on screen.
     * @param event
     */
    function onClickAnnotationList(event: any) {
        //console.log(event);

        selected_old_annotation = event.target.innerHTML;

        loadItemInAnnotationList(selected_old_annotation);
    }

    /**
     * Loads and render the Annotation Item into the UI Elements
     *
     * @param selected_item The selected item (from the Annotation List)
     */
    function loadItemInAnnotationList(selected_item: string) {

        //  Loads the i item
        let inputCubeAnnotationHandle = document.getElementById("in_cubeAnnotation") as HTMLInputElement;
        if (inputCubeAnnotationHandle)
            inputCubeAnnotationHandle.value = selected_item;

        //  Display the Cube Context Menu
        //  Hide Cube Context Menu (Not used: Can be enabled)
        // let cubeContextMenu = document.getElementById('cubeContextMenu')
        // if (cubeContextMenu)
        //     cubeContextMenu.style.visibility = 'visible';

        if (selected_item.includes(STR_UNLABELED_CUBE)) {
            //  If the selected is the Untitled Cube text, this indicates cancel
        } else {
            if (annotatedPointList) {

                //  Remove any existing cube on screen
                //deleteCube();

                //  Reset Cube Control and color
                resetCubeControlAndColor();

                //  Find the item in Annotation List, deletes and redraw into the scene
                for (let i = 0; i < annotatedPointList.length; i++) {

                    if (annotatedPointList[i].annotation === selected_item) {

                        const cubeHandle = scene.getObjectByName(annotatedPointList[i].cube.name);
                        if (cubeHandle) {
                            const cubeControlHandle = scene.getObjectByName(annotatedPointList[i].cube.name + "_control");
                            if (cubeControlHandle)
                                scene.remove(cubeControlHandle);
                            scene.remove(cubeHandle);

                            //  Redraw the ThreeJS screen
                            renderer.render(scene, camera);
                        }

                        //  Assign to selected
                        let localCube = annotatedPointList[i].cube;
                        if (localCube) {
                            selectedCube = localCube;

                            //  Change cube color to selected (Yellow)
                            //(localCube.material as THREE.MeshBasicMaterial).color.setHex(0xFFFF00);
                            localCube.material=new THREE.MeshBasicMaterial({color: 0xFFFF00, opacity: MATERIAL_OPACITY, transparent: true});

                            scene.add(localCube);

                            let localCubeControl = getCubeControl(annotatedPointList[i].cube);
                            if (localCubeControl) {
                                selected_cubeControl = localCubeControl;
                                scene.add(localCubeControl);
                            }
                        }

                        //  Set up the Cube Space parameters into UI elements
                        loadCubeDimensionIntoUI();

                        renderer.render(scene, camera);
                        break;
                    }
                }
            }
        }
    }

    /**
     * Toggle the div_of_listofTasks
     * Shows the content of the list of tasks to be done.
     */
    const toggleDropdownForListOfTasks = () => {
        //setIsOpen(!isOpen);

        if (divListOfTasks) {
            if (flagDropdown_listOfTasks) {
                divListOfTasks.style.visibility = 'hidden';
                flagDropdown_listOfTasks = false;
            } else {
                divListOfTasks.style.visibility = 'visible';
                flagDropdown_listOfTasks = true;
            }
        }
    };

    /**
     * Hook for Zoom In with UI-Button
     */
    function onZoomIn() {

        //  Using our own orbitControl which exposes dollyIn/Out functions as public
        orbitControl.dollyOut(orbitControl.getZoomScale());
        orbitControl.update();
    }

    /**
     * Hook for Zoom Out with UI-Button
     */
    function onZoomOut() {

        //  Using our own orbitControl which exposes dollyIn/Out functions as public
        orbitControl.dollyIn(orbitControl.getZoomScale());
        orbitControl.update();
    }

    /**
     * Performs the Redo operation using the ActionLogger
     *
     * ActionLogger logs all the user's action on the content in the scene.
     * Redo the next action of the user in the history. Does nothing if it is as the last item in history.
     */
    function onRedo() {
        //console.log(actionLogger);

        let historySize: number = actionLogger.getHistorySize();
        let browseIndex: number = actionLogger.getBrowseIndex();
        // console.log("Before");
        // console.log(historySize);
        // console.log(browseIndex);
        // actionLogger.printLog();

        //  Make sure there is content in the history before redo can be executed.
        if (historySize > 0 && browseIndex >= 0 && browseIndex < historySize) {

            if (browseIndex >= 0) {
                //  BrowseI Index is within range, redo accordingly
                let returnResult = actionLogger.get(browseIndex + 1);
                if (returnResult) {
                    //console.log(returnResult);
                    redoBasedOnAction(returnResult[1]);
                }

                //  Browse Index go to next item
                actionLogger.setBrowseIndex(browseIndex + 1);
            } else if (browseIndex == (historySize - 1)) {
                //  Last Item
                actionLogger.setBrowseIndex(browseIndex);
            }
        }

        // console.log("After");
        // console.log(historySize);
        // console.log(browseIndex);
        // actionLogger.printLog();
    }

    /**
     * OnClick Go to Tutorial Page
     * Opens tab to YouTube Tutorial Playlist
     */
    function onClickGoTutorial() {
        window.open(URL_YOUTUBE_TUTORIAL_PLAYLIST, "_blank", "noreferrer");
    }

    /**
     * Redo Based on Action
     *
     * Given the action Item to redo, perform the action accordingly to the action type.
     * @param actionItem ActionItem The action the user did.
     */
    function redoBasedOnAction(actionItem: ActionItem) {

        //  Perform the action based on the type: Move or Create new cube.
        if (actionItem.actionType === PCDActionLogger.ACTION_MOVE_CUBE || actionItem.actionType === PCDActionLogger.ACTION_SIZE_CUBE) {
            if (actionItem.targetCube)
                reDrawCube(selectedCube, actionItem.targetCube.clone());
        } else if (actionItem.actionType === PCDActionLogger.ACTION_ADD_CUBE) {
            if (actionItem.targetCube)
                reDrawCube(null, actionItem.targetCube.clone());
        }
    }


    /**
     * Performs the Undo operation using the ActionLogger
     *
     * ActionLogger logs all the user's action on the content in the scene.
     * Undo the last action of the user. Does nothing if nothing is in the ActionLogger's history.
     */
    function onUndo() {
        //console.log(actionLogger);
        // console.log("scene.children");
        // console.log(scene.children);

        let historySize: number = actionLogger.getHistorySize();
        let browseIndex: number = actionLogger.getBrowseIndex();

        // console.log("Before");
        // console.log(historySize);
        // console.log(browseIndex);
        // actionLogger.printLog();

        if (browseIndex < 0) {
            //  Last Item to be push into history

            if (historySize > 0) {
                // Is history has content, undo last action.

                let lastItemIndex = historySize - 1;
                let lastActionItem = actionLogger.getActionItem(lastItemIndex);

                //  Browse Index is now set to lastItemIndex-1 since we got the lastAction
                lastItemIndex--;
                actionLogger.setBrowseIndex(lastItemIndex);
                let returnResult = actionLogger.get(lastItemIndex);
                if (lastActionItem && returnResult) {
                    //console.log(returnResult);
                    undoBasedOnAction(lastActionItem, returnResult[1]);
                }
            }
        } else {
            //  Index Browser has moved, go to the indicate item and undo that action.

            //  Move the browse Index to previous one
            browseIndex = actionLogger.decrementBrowseIndex();
            if (browseIndex >= 0) {

                //  We already moved, so we +1 to get back to current.
                let lastActionItem = actionLogger.getActionItem(browseIndex + 1);
                let currentActionItem = actionLogger.get(browseIndex);
                if (lastActionItem && currentActionItem) {
                    //console.log(returnResult);
                    undoBasedOnAction(lastActionItem, currentActionItem[1]);
                }
            }
        }

        // console.log("After");
        // console.log(historySize);
        // console.log(browseIndex);
        // actionLogger.printLog();
    }


    /**
     * Undo the last Action.
     *
     * Requires the last action and current action. As you undo the last action, you might need to set up the current action.
     * E.g. 1. Create Cube at A, 2. Move Cube from A to B
     * When you undo Item 2, you will need the action Item 1, to recreate the cube at A.
     * Hence the need for the lastActionItem and the currentActionItem
     *
     * @param lastActionItem ActionItem The last action the user did.
     * @param currentActionItem ActionItem The current action the user is on.
     */
    function undoBasedOnAction(lastActionItem: ActionItem, currentActionItem: ActionItem) {

        // console.log("lastActionItem");
        // console.log(lastActionItem);
        // console.log("currentActionItem");
        // console.log(currentActionItem);

        //  check the Action type and perform the undo appropriately. E.g. Redraw cube or removeCube.
        if (lastActionItem.actionType === PCDActionLogger.ACTION_MOVE_CUBE || lastActionItem.actionType === PCDActionLogger.ACTION_SIZE_CUBE) {
            if (currentActionItem.targetCube)
                reDrawCube(selectedCube, currentActionItem.targetCube.clone());
        } else if (lastActionItem.actionType === PCDActionLogger.ACTION_ADD_CUBE) {
            if (lastActionItem.targetCube && currentActionItem.targetCube)
                removeCube(selectedCube, currentActionItem.targetCube.clone());
            else
                removeCube(selectedCube, null);
        }
    }

    /**
     * ReDraws the Cube
     *
     * Deletes the current Cube and redraws the target cube.
     * E.g. The cube move from Point A to Point B.
     * Delete the cube at Point B, redraw cube a Point A.
     *
     * @param current_cube THREE.Mesh The cube to be deleted.
     * @param target_cube THREE.Mesh The cube to be drawn.
     */
    function reDrawCube(current_cube: THREE.Mesh | null, target_cube: THREE.Mesh) {

        //console.log("reDrawCube");
        //console.log("Current Cube. " + current_cube.name + ", X: " + current_cube.position.x  + ", Y: " + current_cube.position.y  + ", Z: " + current_cube.position.z);
        //console.log("Target Cube. " + target_cube.name + ", X: " + target_cube.position.x  + ", Y: " + target_cube.position.y  + ", Z: " + target_cube.position.z);
        //console.log(current_cube);

        //  Remove current cube.
        if (current_cube)
            removeCube(current_cube, null);

        //  Draw new cube
        //console.log(target_cube);
        let cube_control: TransformControls = getCubeControl(target_cube);
        scene.add(target_cube);
        scene.add(cube_control);
        selectedCube = target_cube;
        selected_cubeControl = cube_control;
        renderer.render(scene, camera);

        // Update Color Points
        colourPointsInCube(selectedCube.name);
    }

    /**
     * Removes the Cube from the scene
     *
     * E.g. User adds cube to the scene, then do undo.
     * Removes the cube from the scene.
     * However, if the user's history has: 1. Moves Cube-01 from A to B. 2. Add new cube-02.
     * If you undo Item 2, selected_cube will lose the reference,
     * Hence, the required replacementCube is there to make sure selected_cube will have a new reference.
     * In this example, selected_cube will now point to Cube-01.
     *
     * @param target_cube THREE.Mesh The cube to be deleted.
     * @param replacementCube THREE.Mesh The replacement cube for selected_cube to be pointed to. (if any)
     */
    function removeCube(target_cube: THREE.Mesh, replacementCube: THREE.Mesh | null) {

        //  Remove current cube.
        // console.log("target_cube");
        // console.log(target_cube);

        //  Remove the cube control
        let cube_control = scene.getObjectByName(target_cube.name + "_control");
        if (cube_control)
            scene.remove(cube_control);

        //  Remove the cube
        let local_cube = scene.getObjectByName(target_cube.name);
        if (local_cube)
            scene.remove(local_cube);

        renderer.render(scene, camera);

        //  Remove any colored Points in existing Scene
        let oldPointsIfAvailable: THREE.Mesh = scene.getObjectByName("pointsInBox_" + target_cube.name)
        if (oldPointsIfAvailable)
            scene.remove(oldPointsIfAvailable)

        if (replacementCube) {
            // console.log("replacementCube");
            // console.log(replacementCube);

            let local_cube = scene.getObjectByName(replacementCube.name);
            if (local_cube)
                selectedCube = replacementCube;

            //selectedCube=replacementCube;
            // let cube_control=getCubeControl(replacementCube);
            // scene.add(replacementCube);
            // scene.add(cube_control);
            // selectedCube=replacementCube;
            // selected_cubeControl=cube_control;
            // renderer.render(scene, camera);
        }
    }

    return (
        <>
            <div id="ErrorContentDiv" className="main h-screen flex items-center justify-center">
                Please adjust your browser to 16:10 aspect ratio to use web app.
            </div>
            <div id="MainContentDiv" className="main flex items-center flex-col">

                <div className="top flex items-center">
                    <div className="progress w-100">
                        <p id="progressText" className="progressText"></p>
                    </div>
                </div>

                <div className="question">
                    <p id="quesText" className="questionText"></p>
                </div>


                <div className="middle flex items-center flex-row">
                    <div/>
                    <div className="polymenu flex items-center flex-col justify-center">

                        <div className='flex flex-col gap-2.5 mx-2'>
                            <div className='flex flex-col items-center justify-center box-border h-14 w-16 p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <PiCubeFocusLight style={{color: '#A81153'}}/>
                                </div>
                                <button style={{color: '#A81153'}} className='whitespace-nowrap text-xs' onClick={onClickAddCube}>
                                    Add Box
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <GrCursor/>
                                </div>
                                <button className='whitespace-nowrap text-xs'>
                                    Select
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <BiMove/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={ui_handle_keyboard_q}>
                                    Move
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <Tb3DRotate/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={ui_handle_keyboard_e}>
                                    Rotate
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <HiMagnifyingGlassPlus/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={onZoomIn}>
                                    Zoom In
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <HiMagnifyingGlassMinus/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={onZoomOut}>
                                    Zoom Out
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <MdUndo/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={onUndo}>
                                    Undo
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <MdRedo/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={onRedo}>
                                    Redo
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <BsInfoCircle/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={onClickGoTutorial}>
                                    Tutorial
                                </button>
                            </div>
                        </div>

                        <hr className="buttonLine"/>

                        <div className='flex flex-col gap-2.5 mx-2'>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <TbPlanet/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={ui_handle_keyboard_v}>
                                    Orbit Axis
                                    <br/>
                                    Show/Hide
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <TbArrowDownLeft/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={ui_handle_keyboard_s}>
                                    Orbit Axis
                                    <br/>
                                    Move Z-Axis
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <TbArrowUpRight/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={ui_handle_keyboard_x}>
                                    Orbit Axis
                                    <br/>
                                    Move Z-Axis
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <TbArrowDownRight/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={ui_handle_keyboard_c}>
                                    Orbit Axis
                                    <br/>
                                    Move X-Axis
                                </button>
                            </div>

                            <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                <div className='w-5 flex content-middle justify-center'>
                                    <TbArrowUpLeft/>
                                </div>
                                <button className='whitespace-nowrap text-xs' onClick={ui_handle_keyboard_z}>
                                    Orbit Axis
                                    <br/>
                                    Move X-Axis
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="overflow-hidden text-gray-700">
                        <div className="flex flex-row">
                            <div className='flex gap-1.5 flex-col'>
                                <div>
                                    Task: Annotate all the locations
                                </div>
                                <div className='relative' ref={dropdownRef}>

                                    <button
                                        onClick={toggleDropdownForListOfTasks}
                                        className='bg-gray-100 text-gray-800 py-1.5 px-4 font-regular rounded inline-flex items-center'>
                                        <span>Choose Location</span>
                                        <svg
                                            className={`w-4 h-4 ml-2 transition-transform ${flagDropdown_listOfTasks ? 'transform rotate-180' : ''
                                            }`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M6.707 7.293a1 1 0 0 1 1.414 0L10 9.586l1.879-1.879a1 1 0 0 1 1.414 1.414l-2.586 2.586a1 1 0 0 1-1.414 0L8 10.414l-1.879 1.879a1 1 0 1 1-1.414-1.414l2.586-2.586z"
                                            />
                                        </svg>
                                    </button>
                                    <div id="div_of_listoftasks"
                                         className="absolute left-0 top-0 right-0 mt-12 w-40 z-10 bg-white border border-gray-300 rounded shadow  transition-opacity duration-300">
                                        <ul id="listoftasks" className="py-2 flex flex-col list-inside list-disc" onClick={onClickTaskList}></ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="justify-between w-full h-screen">
                            <div className="min-h-screen flex flex-col justify-between w-full h-full p-1 md:p-2 img-overlay-wrap" id="satellite">
                                <div></div>
                                <div id="three-canvas" className=""></div>
                                <div>
                                    Q - Set Space / W - Set Translate / E - Set Rotate ||| V - Toggle Orbit Cone / S,X - Moves Camera Orbit Point in Z-Axis / Z,C - Moves Camera Orbit Point in X-Axis
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center flex-col gap-4">
                        <div>
                            List of Annotated Items
                        </div>
                        <div className='flex flex-col gap-10 '>
                            <div className='flex flex-col gap-2.5'>
                                <ul id="cubeAnnotatedList" onClick={onClickAnnotationList}></ul>
                            </div>

                            {/*<div id="cubeContextMenu" className='flex flex-col gap-2.5'>*/}
                            <div className='flex flex-col gap-2.5'>

                                <div className='px-4'>
                                    <input
                                        type="text"
                                        id='in_cubeAnnotation'
                                        onChange={onChangeAnnotation}
                                        onClick={onClickAnnotation}
                                        onKeyPress={onKeypressAnnotation}
                                        className="w-full h-4 border border-gray-300 rounded-md py-2.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Input title"
                                    />
                                    <div className='text-sm font-light flex justify-center items-center'>
                                        Title
                                    </div>
                                </div>

                                <div className='px-4'>
                                    <input
                                        id="cubeSizeWidth"
                                        type="number"
                                        className="w-full h-4 border border-gray-300 rounded-md py-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Input Cube's Width"
                                        min="0.1" step="0.1" max="5.0"
                                        onChange={onChangeCubeWidth}
                                    />
                                    <div className='text-sm font-light flex justify-center items-center'>
                                        Cube's Width
                                    </div>
                                </div>

                                <div className='px-4'>
                                    <input
                                        id="cubeSizeDepth"
                                        type="number"
                                        className="w-full h-4 border border-gray-300 rounded-md py-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Input Cube's Depth"
                                        min="0.1" step="0.1" max="5.0"
                                        onChange={onChangeCubeDepth}
                                    />
                                    <div className='text-sm font-light flex justify-center items-center'>
                                        Cube's Depth
                                    </div>
                                </div>

                                <div className='px-4'>
                                    <input
                                        id="cubeSizeHeight"
                                        type="number"
                                        className="w-full h-4 border border-gray-300 rounded-md py-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Input Cube's Height"
                                        min="0.1" step="0.1" max="5.0"
                                        onChange={onChangeCubeHeight}
                                    />
                                    <div className='text-sm font-light flex justify-center items-center'>
                                        Cube's Height
                                    </div>
                                </div>

                                <div className='px-4'>
                                    <input
                                        id="cubePos_X"
                                        type="number"
                                        className="w-full h-4 border border-gray-300 rounded-md py-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Cube's Position in X-Axis"
                                        min="-5.0" step="0.01" max="5.0"
                                        onChange={onChangeCubePosition}
                                    />
                                    <div className='text-sm font-light flex justify-center items-center'>
                                        x-axis
                                    </div>
                                </div>

                                <div className='px-4'>
                                    <input
                                        id="cubePos_Y"
                                        type="number"
                                        className="w-full h-4 border border-gray-300 rounded-md py-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Cube's Position in Y-Axis"
                                        min="-5.0" step="0.01" max="5.0"
                                        onChange={onChangeCubePosition}
                                    />
                                    <div className='text-sm font-light flex justify-center items-center'>
                                        y-axis
                                    </div>
                                </div>

                                <div className='px-4'>
                                    <input
                                        id="cubePos_Z"
                                        type="number"
                                        className="w-full h-4 border border-gray-300 rounded-md py-3.5 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Cube's Position in Z-Axis"
                                        min="-5.0" step="0.01" max="5.0"
                                        onChange={onChangeCubePosition}
                                    />
                                    <div className='text-sm font-light flex justify-center items-center'>
                                        z-axis
                                    </div>
                                </div>
                                <div className='flex flex-col items-center justify-center box-border h-14 w-16  p-1 border border-gray-200 p-2.5 rounded-md'>
                                    <div className='w-5 flex content-middle justify-center'>
                                        <TbDeviceFloppy/>
                                    </div>
                                    <button className='whitespace-nowrap text-xs' onClick={onClickSaveScene}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}