import {IParticipant} from "./Participant";
import {getRandomInt} from "../util/util";

export interface IAMTWorker extends IParticipant {
    id: number;
    workerId: string;
}

export class AMTWorker implements IAMTWorker {
    id: number;
    workerId: string;

    constructor(id: number, workerId: string) {
        this.id = id;
        this.workerId = workerId;
    }

    toJSON(): string {
        return JSON.stringify({id: this.id, workerId: this.workerId});
    }
}

export class AMTWorkerFactory {
    create(workerId: string): AMTWorker {
        const id = getRandomInt();
        return new AMTWorker(id, workerId);
    }

    createFromJSON(json: string): AMTWorker {
        const parsed: IAMTWorker = JSON.parse(json);
        return new AMTWorker(parsed.id, parsed.workerId);
    }
}