import React from "react";
import {Navigate} from "react-router-dom";
import {GlobalContext, IGlobalContext} from "./App";
import {ICurrentUser} from "./usecase/auth/CurrentUser";
//import jwt_decode from "jwt-decode";
//import jwtDecode from "jwt-decode";
//import * as jwt_decode from 'jwt-decode';
import jwt_decode, { JwtPayload } from 'jwt-decode'
import {authenticationService} from "./App";

//  William Added Protected Route:
export type ProtectedRouteProps = {
    //isAuthenticated: boolean;
    authenticationPath: string;
    outlet: JSX.Element;
};

export function ProtectedRoute({authenticationPath, outlet}: ProtectedRouteProps) {
    const {currentUser} = React.useContext(GlobalContext) as IGlobalContext;

    if (isAuth(currentUser)) {
        return outlet;
    } else {
        return <Navigate to={{pathname: authenticationPath}}/>;
    }
}

function isAuth(currentUser: ICurrentUser) {
    //  Check if user JSON data is available, if not, that means token is also not available.
    const user = currentUser.getCurrentUser();
    console.log("isAuth user",user);

    if(user) {
        //  Check in the user JSON payload that the token is embedded.
        // const userJsonData = JSON.parse(userData);
        // console.log("isAuth user.token",user.token);
        if(typeof user.token === "undefined" || user.token === null || user.token === ''){
            return false;
        }
        else{
            //  Get the Unix Epoch of the current time.
            const today = Math.round((new Date()).getTime() / 1000);

            //  Decode the JWT Token. See: https://github.com/auth0/jwt-decode
            //  Get the Unix Epoch for the Token Expiry with the decoded JSON payload, 'exp'
            const decoded: any = jwt_decode(user.token);
            //const decoded: any = jwtDecode(user.token);
            //const decoded = jwt_decode<JwtPayload>(user.token || '') || null;

            // console.log("jwt decoded");
            // console.log(decoded);
            // console.log(today);

            //  Check if the token has expired compare to current time.
            //  If not: Return True, else False, we log out of the system.
            if(decoded['exp'] > today) {
                console.log("Token Not Expire Yet");
                return true;
            }
            else {
                console.log("Expired Token");
                alert("Session Ended. Please Login Again.");
                authenticationService.logout(currentUser);
                return false;
            }
        }
    } else {
        return false
    }
}