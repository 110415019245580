import {IHITBatchReportRepository} from "../../domain/mturk/HITBatchReportRepository";
import {HITBatchReport} from "../../domain/mturk/HITBatchReport";
import {deserializeHITBatchReport} from "../../adapter/project/HITBatchReportAdapter";
import {CurrentUser} from "../../usecase/auth/CurrentUser";

import {AuthenticationService} from "../api/AuthenticationService";


export class HITBatchReportRepository implements IHITBatchReportRepository {

    currentUser: CurrentUser;

    constructor(user: CurrentUser) {
        this.currentUser = user;
    }

    add(report: HITBatchReport): Promise<number> {
        return new Promise((resolve) => {
            resolve(0);
        })
    }

    listByProjectId(projectId: number): Promise<HITBatchReport[]> {
        const data = JSON.stringify({
            project_id: projectId
        });
        const url = "/api/mturk/list_batch_reports_by_project";

        const user = this.currentUser.getCurrentUser();

        if (user) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => {
                return response.json();
            }).then((json: any) => {
                const hitBatchReportsObject = json["hit_batch_reports"];
                const reports: HITBatchReport[] = hitBatchReportsObject.map(deserializeHITBatchReport);
                return reports;
            });
        }
        return Promise.resolve([]);
    }
}