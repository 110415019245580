export interface IBlueprintInstruction {
    title: string;
    body: string;
}

export class BlueprintInstruction implements IBlueprintInstruction {
    title: string;
    body: string;
    constructor(title: string, body: string) {
        this.title = title;
        this.body = body;
    }
}

export interface IBlueprintInstructionFactory {
    create(title: string, body: string): IBlueprintInstruction
}

export class BlueprintInstructionFactory implements IBlueprintInstructionFactory {
    create(title: string, body: string): IBlueprintInstruction {
        return new BlueprintInstruction(title, body);
    }
}