import React, {useEffect} from "react";
import {ReportTableHeader} from "./ReportTableHeader";
import {ReportTableRow} from "./ReportTableRow";
import {IBatchReport} from "../../../../../domain/task/BatchReport";
import {GlobalContext, IGlobalContext} from "../../../../../App";
import {useNavigate} from "react-router-dom";

export function ReportTable () {
    let navigate = useNavigate();

    const {hitBatchReportRepository, currentProject, currentUser} = React.useContext(GlobalContext) as IGlobalContext;

    const [reports, setReports] = React.useState<IBatchReport[]>([])

    useEffect(function () {
        if (currentProject) {
            const fetchReports = async () => {
                const _reports = await hitBatchReportRepository.listByProjectId(currentProject.id);
                setReports(_reports);

                //  If return empty list and user ID is reset=0, this meant Token has expired
                if(_reports.length==0 && currentUser.currentUser?.id==0)
                    navigate('/signin');
            }
            fetchReports().catch(console.error);
        }
    }, [currentProject, hitBatchReportRepository])

    return (
        <>
            <div className="flex items-center justify-between py-2 px-6">
                <div>
                    <p className="text-left text-sm font-medium text-gray-500">
                        A list of reports for task batches.
                    </p>
                </div>
            </div>
            <div className='flex flex-col'>
                <div className='overflow-x-auto'>
                    <div className='py-2 inline-block min-w-full sm:px-0 lg:px-0'>
                        <div className="overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='bg-white border-b'>
                                <tr>
                                    {/*<BlueprintTableHeader label={'ID'}></BlueprintTableHeader>*/}
                                    <ReportTableHeader label={'Batch Title'}></ReportTableHeader>
                                    <ReportTableHeader label={'Batch Description'}></ReportTableHeader>
                                    <ReportTableHeader label={'Environment'}></ReportTableHeader>
                                </tr>
                                </thead>
                                <tbody>
                                {reports.map((report) => (
                                    <ReportTableRow
                                        key={report.batchId.toString()}
                                        batchId={report.batchId}
                                        title={report.title}
                                        description={report.summary}
                                        environment={report.production ? "Production" : "Sandbox"}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}