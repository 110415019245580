import {IBlueprintRepository} from "../../domain/blueprint/BlueprintRepository";
import {Blueprint} from "../../domain/blueprint/Blueprint";
import {deserializeBlueprint} from "../../adapter/project/BlueprintAdapter";
import {CurrentUser} from "../../usecase/auth/CurrentUser";
import {AuthenticationService} from "../api/AuthenticationService";
import {HITBatchReport} from "../../domain/mturk/HITBatchReport";
import {deserializeHITBatchReport} from "../../adapter/project/HITBatchReportAdapter";

export class BlueprintRepository implements IBlueprintRepository {

    currentUser: CurrentUser;

    constructor(currentUser: CurrentUser) {
        this.currentUser = currentUser;
    }

    add(blueprint: Blueprint): Promise<string> {
        const data = JSON.stringify(blueprint.toJson());
        const url = "/api/blueprint/create";

        const user = this.currentUser.getCurrentUser();
        if (user) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => {
                return response.json();
            }).then(json => {
                 return JSON.stringify(json);
            });
        }

        return Promise.resolve("failed to add a blueprint");
    }

    getById(id: number): Promise<Blueprint | undefined> {
        return Promise.resolve(undefined);
    }

    list(): Promise<Blueprint[]> {
        return Promise.resolve([]);
    }

    getByProject(projectId: number): Promise<Blueprint[]> {
        const data = JSON.stringify({
            project_id: projectId
        });
        const url = "/api/blueprint/list_by_project";

        const user = this.currentUser.getCurrentUser();

        if (user) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => {
                console.log(response);
                return response.json();
            }).then((json: any) => {

                console.log(json);
                const blueprintObjects = json["blueprints"];
                const blueprints: Blueprint[] = blueprintObjects.map(deserializeBlueprint);
                return blueprints;
            });
        }
        return Promise.resolve([]);
    }
}