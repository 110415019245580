import {ReportProgressTable} from "./ReportProgressTable"
import React, {useEffect} from "react";
import {EnterKeyModal} from "./EnterKeyModal";
import {useParams} from "react-router-dom";
import {HITResponse} from "../../../../../domain/mturk/HITResponse";
import {GlobalContext, IGlobalContext} from "../../../../../App";

export function ReportProgress () {
    const params = useParams();

    const {hitResponseRepository} = React.useContext(GlobalContext) as IGlobalContext;

    const [responses, setResponses] = React.useState<HITResponse[]>([])


    useEffect(() => {
        const taskBatchId: number = parseInt(params.batchId!, 10);
        console.log(`useEffect batch id: ${taskBatchId}`)

        const fetchResponses = async () => {
            const _responses = await hitResponseRepository.listByTaskBatchId(taskBatchId)
            setResponses(_responses)
        }
        fetchResponses().catch(console.error);
    }, [hitResponseRepository, params])


    async function handleSubmit (accessKey: string, secretKey: string): Promise<string> {
        const taskBatchId: number = parseInt(params.batchId!, 10);

        console.log(`Access Key: ${accessKey}, Secret Key: ${secretKey}, Batch ID: ${taskBatchId}`);
        const responses = await hitResponseRepository.updateByTaskBatchId(taskBatchId, accessKey, secretKey)
        console.log(responses);

        setResponses(responses);

        return Promise.resolve("");
    }

    return (
        <>
            <div className="flex items-center justify-between py-2 px-6">
                <div>
                    <p className="text-left text-sm font-medium text-gray-500">
                        A list of reports for task batches.
                    </p>
                </div>
                <div className="">
                    <span className="inline px-6">Last synced XXX days ago</span>

                    <EnterKeyModal
                    title="Title: Sync the batch status"
                    handleSubmitClick={handleSubmit}
                    />
                </div>
            </div>
            <ReportProgressTable responses={responses}/>
        </>
    )
}