import {BatchStepper} from "./BatchStepper";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {BatchPrototypeContext, IBatchPrototypeContext} from "../Batches";

export function BatchAttributes () {
    const {batchPrototype, setBatchPrototype} = React.useContext(BatchPrototypeContext) as IBatchPrototypeContext

    const [taskTitle, setTaskTitle] = useState<string>(batchPrototype.title ? batchPrototype.title : '');
    const [taskDescription, setTaskDescription] = useState<string>(batchPrototype.description ? batchPrototype.description : '');
    const [taskKeywords, setTaskKeywords] = useState<string>(batchPrototype.keywords ? batchPrototype.keywords : '');
    const [numParticipants, setNumParticipants] = useState<number>(batchPrototype.maxAssignments ? batchPrototype.maxAssignments : 1);
    const [maxTaskDuration_m, setMaxTaskDuration_m] = useState<number>(batchPrototype.assignmentDuration_s ? batchPrototype.assignmentDuration_s / 60 : 60);
    const [timeToExpire_h, setTimeToExpire_h] = useState<number>(batchPrototype.timeToExpire_s ? batchPrototype.timeToExpire_s / (60 * 60) : 72);
    const [reward, setReward] = useState<number>(batchPrototype.reward ? batchPrototype.reward : 0.5);
    const [autoApprovalDelay_h, setAutoApprovalDelay_h] = useState<number>(batchPrototype.autoApprovalDelay_s ? batchPrototype.autoApprovalDelay_s / (60 * 60) : 1);


    function handleTaskTitleChange (e: React.ChangeEvent<HTMLInputElement>) {
        const title: string = e.target.value;
        setTaskTitle(title);

        setBatchPrototype({...batchPrototype, title: title})
    }

    function handleTaskDescriptionChange (e: React.ChangeEvent<HTMLTextAreaElement>) {
        const description: string = e.target.value;
        setTaskDescription(description);

        setBatchPrototype({...batchPrototype, description: description});
    }

    function handleTaskKeywordsChange (e: React.ChangeEvent<HTMLInputElement>) {
        const keywords: string = e.target.value;
        setTaskKeywords(keywords);

        setBatchPrototype({...batchPrototype, keywords: keywords})
    }

    function handleNumParticipantsChange (e: React.ChangeEvent<HTMLInputElement>) {
        const participants: number = parseInt(e.target.value, 10);
        setNumParticipants(participants);
        setBatchPrototype({...batchPrototype, maxAssignments: participants})
    }

    function handleTimeToExpireChange (e: React.ChangeEvent<HTMLInputElement>) {
        const expire_h: number = parseInt(e.target.value, 10);
        setTimeToExpire_h(expire_h);

        setBatchPrototype({...batchPrototype, timeToExpire_s: expire_h * 60 * 60})
    }

    function handleMaxTaskDurationChange (e: React.ChangeEvent<HTMLInputElement>) {
        const duration_m: number = parseInt(e.target.value, 10);
        setMaxTaskDuration_m(duration_m);

        setBatchPrototype({...batchPrototype, assignmentDuration_s: duration_m * 60})
    }

    function handleRewardChange (e: React.ChangeEvent<HTMLInputElement>) {
        const reward: number = parseFloat(e.target.value);
        setReward(reward);

        setBatchPrototype({...batchPrototype, reward: reward})
    }

    function handleAutoApprovalChange (e: React.ChangeEvent<HTMLInputElement>) {
        const autoApproval_h: number = parseInt(e.target.value, 10);
        setAutoApprovalDelay_h(autoApproval_h);

        setBatchPrototype({...batchPrototype, autoApprovalDelay_s: autoApproval_h * 60 * 60});
    }

    function handleNextClick () {
        setBatchPrototype({...batchPrototype,
            title: taskTitle,
            description: taskDescription,
            keywords: taskKeywords,
            maxAssignments: numParticipants,
            assignmentDuration_s: maxTaskDuration_m * 60,
            timeToExpire_s: timeToExpire_h * 60 * 60,
            reward: reward,
            autoApprovalDelay_s: autoApprovalDelay_h * 60 * 60,
        });
    }

    return (
        <>
            <BatchStepper step={2} />

            <div className="px-6 text-gray-500">
                <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="task-title">
                                Title
                            </label>
                            <input
                                id="batch-qualifications"
                                type="text"
                                value={taskTitle}
                                onChange={handleTaskTitleChange}
                                placeholder="Title"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="task-description">
                                Task Description
                            </label>
                            <textarea
                                rows={2}
                                id="task-description"
                                placeholder="Task description"
                                value={taskDescription}
                                onChange={handleTaskDescriptionChange}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="task-keywords">
                                Task Keywords
                            </label>
                            <input
                                id="task-keywords"
                                type="text"
                                value={taskKeywords}
                                onChange={handleTaskKeywordsChange}
                                placeholder="Title"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="num-participants">
                                Number of Participants
                            </label>
                            <input
                                id="num-participants"
                                type="number"
                                value={numParticipants}
                                onChange={handleNumParticipantsChange}
                                placeholder="Number of participants"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="time-to-expire">
                                Time to Expire (hour)
                            </label>
                            <input
                                id="time-to-expire"
                                type="number"
                                value={timeToExpire_h}
                                onChange={handleTimeToExpireChange}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="max-task-duration">
                                Max Task Duration (minute)
                            </label>
                            <input
                                id="max-task-duration"
                                type="number"
                                value={maxTaskDuration_m}
                                onChange={handleMaxTaskDurationChange}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="task-reward">
                                Reward (US$)
                            </label>
                            <input
                                id="task-reward"
                                type="number"
                                value={reward}
                                onChange={handleRewardChange}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="task-auto-approval">
                                Auto Approval Delay (hour)
                            </label>
                            <input
                                id="task-auto-approval"
                                type="number"
                                value={autoApprovalDelay_h}
                                onChange={handleAutoApprovalChange}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className="flex items-center py-6 px-6">
                <span className='px-2'>
                    <Link to='../create'>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-1
                            bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                        >
                            <span className="text-sm font-semibold tracking-wide">Back</span>
                        </button>
                    </Link>
                </span>
                <span className='px-2'>
                    <Link to='../publish'>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                            bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                            onClick={handleNextClick}
                        >
                            <span className="text-sm font-semibold tracking-wide">Next</span>
                        </button>
                    </Link>
                </span>

                <span className='px-4'>
                    <Link to='..'>
                        Cancel
                    </Link>
                </span>
            </div>
        </>
    )
}