import {Vertex} from "./Vertex";
import {Vertex4D} from "./Vertex4D";

/**
 * Interface: IAnnotatedObject
 * Annotated Object that is uploaded from the Portal to the Backend.
 * This object references what the participant has annotated in the 3D scene,
 * Where the selection cube has encompassed a region of point-cloud and the participant has given it annotated name.
 * E.g. Cube is surrounding a projector, participant gave object_name="projector"
 * The 8 corners of the cube is enclosed where each corner is represented in X,Y,Z axis.
 *
 * @author William Tan 2023 Feb 22.
 */
export interface IAnnotatedObject {
    /**
     * Blueprint ID: The blueprint ID this annotated object belongs to.
     */
    blueprint_id: number;

    /**
     * Room ID: The unique number associated with this room.
     */
    rm_id: number;

    /**
     * The annotated string the participant has given.
     */
    object_name: string;

    /**
     * Cube Position in 3D space in type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_position: Vertex;

    /**
     * Cube Dimension iin type Vertex where Vertex is coordinate in X,Y,Z axis.
     * WHERE the XYZ is mapped:
     * Width=X
     * Height=Y
     * Depth=Z
     */
    cube_dimension: Vertex;

    /**
     * Cube Quaternion. When applied to Cube rotates the cube in 3D space given in type Vertex4D where Vertex4D is coordinate in X,Y,Z,W.
     */
    cube_quaternion: Vertex4D;

    /**
     * Cube Corner 1 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_1: Vertex;

    /**
     * Cube Corner 2 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_2: Vertex;

    /**
     * Cube Corner 3 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_3: Vertex;

    /**
     * Cube Corner 4 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_4: Vertex;

    /**
     * Cube Corner 5 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_5: Vertex;

    /**
     * Cube Corner 6 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_6: Vertex;

    /**
     * Cube Corner 7 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_7: Vertex;

    /**
     * Cube Corner 8 Coordinate of type Vertex where Vertex is coordinate in X,Y,Z axis.
     */
    cube_corner_8: Vertex;
}

export class AnnotatedObject implements IAnnotatedObject {
    blueprint_id: number;
    rm_id: number;
    object_name: string;
    cube_position: Vertex;
    cube_dimension: Vertex;
    cube_quaternion: Vertex4D;
    cube_corner_1: Vertex;
    cube_corner_2: Vertex;
    cube_corner_3: Vertex;
    cube_corner_4: Vertex;
    cube_corner_5: Vertex;
    cube_corner_6: Vertex;
    cube_corner_7: Vertex;
    cube_corner_8: Vertex;

    constructor(blueprint_id: number,
                rm_id: number,
                object_name: string,
                cube_position: Vertex,
                cube_dimension: Vertex,
                cube_quaternion: Vertex4D,
                cube_corner_1: Vertex,
                cube_corner_2: Vertex,
                cube_corner_3: Vertex,
                cube_corner_4: Vertex,
                cube_corner_5: Vertex,
                cube_corner_6: Vertex,
                cube_corner_7: Vertex,
                cube_corner_8: Vertex
    ) {
        this.blueprint_id = blueprint_id;
        this.rm_id = rm_id;
        this.object_name = object_name;
        this.cube_position = cube_position;
        this.cube_dimension = cube_dimension;
        this.cube_quaternion = cube_quaternion;
        this.cube_corner_1 = cube_corner_1;
        this.cube_corner_2 = cube_corner_2;
        this.cube_corner_3 = cube_corner_3;
        this.cube_corner_4 = cube_corner_4;
        this.cube_corner_5 = cube_corner_5;
        this.cube_corner_6 = cube_corner_6;
        this.cube_corner_7 = cube_corner_7;
        this.cube_corner_8 = cube_corner_8;
    }
}
