import {Link, useParams} from "react-router-dom";
import React from "react";
import {GlobalContext, IGlobalContext} from "../../../App";

type MenuLink = {
    title: string;
    ref: string;
}

export function SideMenu () {
    const params = useParams();
    const {currentProject} = React.useContext(GlobalContext) as IGlobalContext;

    const menuItems: MenuLink[] = [
        {title: "Blueprints", ref: `/project/${params.projectId}/blueprints`},
        {title: "Batches", ref: `/project/${params.projectId}/batches`},
        {title: "Reports", ref: `/project/${params.projectId}/reports`},
    ]

    return (
        <>
            <aside className='py-2 px-6 w-64 border-r border-gray-200'>
                <div className='text-left'>
                    <h1 className='text-left text-2xl font-semibold leading-relaxed text-gray-800'>
                        {currentProject ? currentProject.title : `Project ${params.projectId}`}
                    </h1>
                    <Link to='/projects' className='hover:text-blue-600 hover:underline'>
                        Back to Projects
                    </Link>
                </div>
                <ul className='flex flex-col gap-y-6 pt-20'>
                    {menuItems.map((item) => (
                        <li key={item.title}>
                            <Link to={item.ref}
                                  className='flex gap-x-4 items-center py-2
                                             hover:underline hover:text-blue-600'>
                                <span
                                    className="absolute w-1.5 h-8 bg-blue-600 rounded-r-full left-0 scale-y-0
                                    -translate-x-full group-hover:scale-y-100 group-hover:translate-x-0
                                    transition-transform ease-in-out"
                                />
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </aside>
        </>
    )
}