import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {GlobalContext, IGlobalContext} from "../../../App";

export function SignUp() {
    const {authenticationService, currentUser} = React.useContext(GlobalContext) as IGlobalContext;

    let navigate = useNavigate();

    //  William Added:
    const [userEnteredName, setUserEnteredName] = useState("");
    const [userEnteredEmail, setUserEnteredEmail] = useState("");
    const [userEnteredPassword, setUserEnteredPassword] = useState("");

    //  William changes:
    function onSignUpClick (event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        const promise = authenticationService.signup(userEnteredName, userEnteredEmail, userEnteredPassword, currentUser);

        promise.then(response => {
            console.log(response);
            if (response["Status"] === 'Success') {
                console.log("Success")
                //  Divert to Signin Page after registering.
                navigate('/signin')
            } else {
                console.log("Fail")
                //  Divert back to sign up page.
                navigate('/signup')
            }
        })
    }

    //  Original Code before William changes:
    // function onSignUpClick () {
    //     navigate('/projects')
    // }

    return (
        <>
            <div className='bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-xl'>
                <section>
                    <h1 className='font-bold text-xl text-gray-600 pt-2'>Sign up.</h1>
                </section>

                <section className='mt-10'>
                    <form className='flex flex-col text-left'>
                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="email">Username</label>
                            <input
                                type='text'
                                id='username'
                                value={userEnteredName}
                                onChange={(e) => setUserEnteredName(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>
                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="email">Email</label>
                            <input
                                type='email'
                                id='email'
                                value={userEnteredEmail}
                                onChange={(e) => setUserEnteredEmail(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>
                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="password">Password</label>
                            <input
                                type='password'
                                id='password'
                                value={userEnteredPassword}
                                onChange={(e) => setUserEnteredPassword(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                            type="submit"
                            onClick={onSignUpClick}>
                            Sign Up
                        </button>
                    </form>
                </section>

                <section className='mt-10'>
                    <p className=''>
                        You already have an account? <Link to='/signin' className='font-bold hover:underline'>Sign in</Link>.
                    </p>
                </section>
            </div>
        </>
    )
}