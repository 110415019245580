import React from "react";
import {Outlet} from "react-router-dom";

export function ConsoleMain () {
    return (
        <>
            <main className='w-full'>
                <Outlet />
            </main>
        </>
    )
}