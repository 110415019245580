import React, {useEffect, useState} from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {Feature, Polygon} from "@turf/turf";
import * as d3 from "d3";


/**
 * Image Polygon Template
 * - Puts Polygon (4 points) to mark out/segment/identify/etc portions on the image.
 * - Polygon manipulation is a port from the Aerial Project () to Typescript
 * - Polygon currently only supports 4-points Polygon, moving of polygon vertices (Circles), delete/add of Polygon and Undo operation
 * - No Backend API yet, all code here uses Sample JSON encoded locally
 * - How it should work: Image and Polygon (if any) are downloaded via JSON, user manipulates the polygon, re-uploads back to backend.
 * - What is Missing/TODO: Create the Backend API and integrate into it with Tests.
 *
 * Notes
 * - This version is originally from Rosie's code, adapted into Typescript.
 * - This version does not use the polygon overlay (Rosie), it only rates what is presented in the image.
 * - This is a template: It does not have backend code: No download, No upload.
 * @author William Tan 2022-Nov-22.
 * @constructor
 */
export function ImagePolygon() {

    //  Test Sample JSON (Image Set from Server)
    const testJson = "{\n" +
        "\n" +
        " \"blueprint\": {\n" +
        "        \"blueprint_id\": 1,\n" +
        "        \"blueprint_type_id\": 0,\n" +
        "        \"created_by\": {\n" +
        "          \"description\": \"Assistant Professor\",\n" +
        "          \"email\": \"kotarohara@smu.edu.sg\",\n" +
        "          \"user_id\": 1,\n" +
        "          \"user_type\": 0,\n" +
        "          \"username\": \"kotarohara\"\n" +
        "        },\n" +
        "        \"description\": \"survey-link-description\",\n" +
        "        \"items\": {\n" +
        "          \"items\": [\n" +
        "            {\n" +
        "              \"body\": \"This is an instruction\",\n" +
        "              \"title\": \"Survey Link Body\"\n" +
        "            },\n" +
        "            {\n" +
        "              \"body\": \"link\",\n" +
        "              \"title\": \"Survey Link Body\"\n" +
        "            }\n" +
        "          ]\n" +
        "        },\n" +
        "        \"project_id\": 1,\n" +
        "        \"title\": \"survey-link-title\"\n" +
        "      },\n" +
        " \"imageset\": [\n" +
        "{\n" +
        "\"image_id\":1,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/test.png\",\n" +
        "\"image_qn\":\"How well do the polygons overlap with sidewalks?\",\n" +
        "\"features\": [\n" +
        "{\n" +
        "\"type\": \"Feature\", \n" +
        "\"properties\": {\"entity_name\": \"escalator\", \"building\": \"lkcsb\", \"level\": \"l1\", \"type\": \"generic\", \"description\": null},\n" +
        "\"geometry\": {\n" +
        "\"type\": \"Polygon\", \n" +
        "\"coordinates\": [\n" +
        "[\n" +
        "[339.0, 373.0], \n" +
        "[20.0, 373.0], \n" +
        "[20.0, 22.0], \n" +
        "[339.0, 22.0]\n" +
        "]]\n" +
        "}\n" +
        "}\n" +
        "]\n" +
        "}\n" +
        ",\n" +
        "{\n" +
        "\"image_id\":2,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/test.png\",\n" +
        "\"image_qn\":\"How well do the polygons overlap with sidewalks?\",\n" +
        "\"features\": [\n" +
        "{\n" +
        "\"type\": \"Feature\", \n" +
        "\"properties\": {\"entity_name\": \"escalator\", \"building\": \"lkcsb\", \"level\": \"l1\", \"type\": \"generic\", \"description\": null},\n" +
        "\"geometry\": {\n" +
        "\"type\": \"Polygon\", \n" +
        "\"coordinates\": [\n" +
        "[\n" +
        "[667.0, 518.0], \n" +
        "[667.0, 518.0], \n" +
        "[713.0, 518.0], \n" +
        "[716.0, 578.0], \n" +
        "[663.0, 578.0], \n" +
        "[667.0, 518.0]\n" +
        "]]\n" +
        "}\n" +
        "}\n" +
        "]\n" +
        "}\n" +
        "\n" +
        "],\n" +
        "\"status\": \"Success\"\n" +
        "}\n";

    //  Holds the Operation Mode: edit/delete/etc
    let [modeOfOperation, setModeOfOperation] = useState<string>("edit");

    //  Hold Polygon vertex GeometryDatum
    let [geoDS, setGeoDS] = useState<GeometryDatum>();
    //const [polygonVertexDS, setPolygonVertexDS] = useState<PolygonVertexDatum[]>([]);

    //  Hold Vertices Circles
    let [circleDS, setCircleDS] = useState<CircleDatum[]>([]);

    //  Holds the Progress Bar status
    const [progressState, setProgressState] = useState<number>();

    //  Holds the firstload flag. Sets to False after first load.
    const [firstload, setFirstLoad] = useState<boolean>(true);

    let svge = d3.select("#image-overlay");

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        if (firstload) {
            processJSON();
            setFirstLoad(false);
        }
        //  console.log("useEffect modeOfOperation: " + modeOfOperation);
    })

    //  Interface for Image Set
    interface MyEachImageSet {
        image_id: number,
        image_url: string,
        image_qn: string,
        image_rate: number,
        features: JSON
        //entities: Feature[]
    }

    //  Interface Polygon: Holds the 4 point Vertices
    interface PolygonVertexDatum {
        vertexId: string;
        label: string;
        x: number;
        y: number;
    }

    //  Interface Holds multiple Polygons.
    interface PolygonDatum {
        points: PolygonVertexDatum[];
    }

    //  Interface Holds multiple sets of Polygons
    interface GeometryDatum {
        geoData: PolygonDatum[];
    }

    //  Interface: Holds the Circles that indicates the Vertices of Polygons: Allow the user to move the Polygon
    interface CircleDatum {
        nodeId: string;
        name: string;
        label: string;
        x: number;
        y: number;
        r: number;
        color: string;
    }

    //  Interface: Vertices to hold the Undo History (Vertices here tracked the moving of the Polygon's vertex. i.e. Allow the before-after, so that we can undo a move)
    interface actionVertices {
        x: number;
        y: number;
    }

    //  Interface: Holds the action item for an Undo operation: Edit/Delete/Move/Add. Some operations require vertices to track the movement for undo.
    interface actionItem {
        actionType: string;
        actionTarget: string;
        vertices: actionVertices[];
        actionTime: string;
    }

    //  Holds the action History (for Undo operation)
    let [actionHistory, setActionHistory] = useState<actionItem[]>();

    //  Interface for Array of Image Set
    interface MySetImageSet extends Array<MyEachImageSet> {}

    //  Holds the active Image Set
    const [setOfImage, setImageSet] = useState<MySetImageSet>();

    //  The current index pointing to the image.
    const [currentQnIndex, setCurrentQnIndex] = useState<number>(0);

    //  The Image Path
    const [imagepath, setImagePath] = useState<string>();

    //  The Question per Image
    const [imageQn, setImageQn] = useState<string>();


    /**
     * Loads the JSON file: This is for this template, on production, JSON needs to be downloaded as per Blueprint/Batch selection of the user define.
     * This is on first load.
     *
     * On load:
     * 1. JSON is parsed.
     * 2. Image is set.
     * 3. Polygon data is extracted, processed and rendered.
     */
    function processJSON() {
        //  Parse the load the image set
        const data = JSON.parse(testJson);
        let setdata: MySetImageSet = data["imageset"];
        setImageSet(setdata);

        //  Reset the index to 0, reset the progress, loads the first image
        setCurrentQnIndex(0);
        setImagePath(setdata[0].image_url);
        setImageQn(setdata[0].image_qn);
        setProgressState(0);
        //setEntities(json.features.filter((setdata[0].geometry))
        //console.log("features" + setdata[0].features);

        const floorplanImage: HTMLImageElement | null = document.querySelector("#floorplan-image");
        if (floorplanImage) {
            //  Get the image/Floor plan width+height
            const originalWidth = floorplanImage.naturalWidth;
            const originalHeight = floorplanImage.naturalHeight;
            const currentWidth = floorplanImage.width;
            const currentHeight = floorplanImage.height;

            // console.log("originalWidth");
            // console.log(originalWidth);
            // console.log("originalHeight");
            // console.log(originalHeight);
            // console.log("currentWidth");
            // console.log(currentWidth);
            // console.log("currentHeight");
            // console.log(currentHeight);

            let test = JSON.stringify(setdata[0]);
            const myResponse = new Response(test);
            myResponse.json()
                .then((json) => {
                    const entities: Feature[] = json.features;
                    //console.log("entities: ");
                    //console.log(entities);

                    //  Load the Init Polygon Data: Extract the vertex of the Polygon, for drawing the polygon and the circles that is on its vertex.
                    let localCircleDS: CircleDatum[] = [];
                    let polygonVertexDS: PolygonVertexDatum[] = [];
                    const coordintates = (entities[0].geometry as Polygon).coordinates[0];
                    for (let i = 0; i < coordintates.length; i++) {
                        let eachPolygonNode: PolygonVertexDatum = {vertexId: "Node" + i, label: "Node" + i, x: coordintates[i][0], y: coordintates[i][1]};
                        polygonVertexDS.push(eachPolygonNode);

                        let eachCircleVertex: CircleDatum = {nodeId: "Node" + i, name: "Node" + i, label: "Node" + i, x: coordintates[i][0], y: coordintates[i][1], r: 6.5, color: "steelblue"};
                        localCircleDS.push(eachCircleVertex);
                    }

                    let polygonData: PolygonDatum = {points: polygonVertexDS};
                    let geoData: GeometryDatum = {geoData: [polygonData]};

                    //  Update the data (Have to do this, setGeoDS and SetCircleDS is enqueue operation, data may not be refelected immediately)
                    geoDS = geoData;
                    circleDS = localCircleDS;
                    setGeoDS(geoData);
                    setCircleDS(localCircleDS);

                    //  Render the Polygon and Circles
                    if (originalWidth === 0 && originalHeight === 0)
                        renderEntities(geoData, localCircleDS, currentWidth, currentHeight, currentWidth, currentHeight)
                    else
                        renderEntities(geoData, localCircleDS, currentWidth, currentHeight, originalWidth, originalHeight)
                })
        }
    }

    /**
     * Render Entities
     * - Render the Polygon and Vertex Circles
     *
     * @param geoData The Geometry Datum: That holds te Polygon Data.
     * @param circleDS The Circle (Vertex) Datums: That holds each vertex of (Polygon).
     * @param imageWidth The image width. (TODO: Maybe remove)
     * @param imageHeight The image height (TODO: Maybe remove)
     * @param originalWidth The image width. (TODO: Maybe remove)
     * @param originalHeight The image height. (TODO: Maybe remove)
     */
    function renderEntities(geoData: GeometryDatum, circleDS: CircleDatum[], imageWidth: number, imageHeight: number, originalWidth: number, originalHeight: number) {

        //  Get the handle to the image overlay via SVG
        svge = d3.select("#image-overlay");

        //  Draw Polygon and Polygon Vertices Circles
        drawPolygon(geoData, imageWidth, imageHeight, originalWidth, originalHeight);
        drawVertexCircles(circleDS);
    }

    /**
     * Draw Polygon
     *
     * @param geoData The Geometry Datum: That holds te Polygon Data.
     * @param imageWidth The image width. (TODO: Maybe remove)
     * @param imageHeight The image height (TODO: Maybe remove)
     * @param originalWidth The image width. (TODO: Maybe remove)
     * @param originalHeight The image height. (TODO: Maybe remove)
     */
    function drawPolygon(geoData: GeometryDatum, imageWidth: number, imageHeight: number, originalWidth: number, originalHeight: number) {

        const wScale = imageWidth / originalWidth;
        const hScale = imageHeight / originalHeight;

        svge.selectAll("polygon")
            .data(geoData.geoData)
            .enter().append("polygon")
            .attr("points", (datum, index) => {
                const points = datum.points.map(coord => `${wScale * coord.x},${hScale * coord.y}`)
                return points.join(" ");
            })
            .style("fill", "rgba(200, 0, 0, 0.3)")
            .style("stroke", "rgba(0, 0, 0, 0.5)")
            .style("strokeWidth", "10px");

        //console.log("entities");
        //console.log(entities);
    }

    /**
     * Draw Circles on the Vertex of the Polygon
     * - This allows the user to have some UI element to move the Polygon
     * - The circle drawing encompasses the mouseover/drag operation for moving the circles.
     * - This in-turn will cause the vertex of the polygon to move as well. Hence, redraw of the polygon to reflect this change.
     *
     * @param localCircleDS The Circle (Vertex) Datums: That holds each vertex of (Polygon).
     */
    function drawVertexCircles(localCircleDS: CircleDatum[]) {
        //  Ref: https://stackblitz.com/edit/react-saqrv9?file=src%2Fgraph.tsx
        //  https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/d3-drag/d3-drag-tests.ts

        svge
            .selectAll("circle")
            .data(localCircleDS)
            .enter()
            .append("circle")
            .attr("r", 6.5)
            .style("fill", "steelblue")
            .style("stroke", "white")
            .style("stroke-width", 2.5)
            .on("mouseover", function () {

                if (modeOfOperation === "delete") {
                    d3.select(this).classed("active", true);
                    d3.select(this).property("style").cursor = "pointer";
                } else if (modeOfOperation === "edit") {
                    d3.select(this).classed("active", true);
                    d3.select(this).property("style").cursor = "move";
                }
            })
            .on("mousedown", (event, d) => {

                // console.log("draw mousedown");
                //console.log(d);
                deletePolygonAndCircles(d.x, d.y);
                return;
            })
            .on("mouseup", (event, d) => {

                //console.log("draw mouseup");
                //console.log(d);
                deletePolygonAndCircles(d.x, d.y);
                return;
            })
            .call(d3.drag<SVGCircleElement, CircleDatum>()
                //.call(handler
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended)
            )
            .attr("cx", (datum, index) => {
                //console.log("datum");
                //console.log(datum);
                return datum.x;
            })
            .attr("cy", (datum, index) => {
                //console.log("datum");
                //console.log(datum);
                //console.log(index);
                return datum.y;
            })
            .exit().remove()
        ;
    }

    /**
     * Delete Polygon And Circles
     * -Delete the Polygon as well as the circles (Drawn on the polygon's vertex)
     * -Can only be called during "Delete" Mode.
     *
     * @param verticeX One of the Vertex Point of the Polygon (X-position)
     * @param verticeY One of the Vertex Point of the Polygon (Y-position)
     */
    function deletePolygonAndCircles(verticeX: number, verticeY: number) {

        //console.log("deletePolygonAndCircles modeOfOperation: " + modeOfOperation);
        if (modeOfOperation === "delete")
            deleteGeneralPolygonAndCircles(verticeX, verticeY);
    }

    /**
     * Generealised Delete Polygon And Circles
     * -Delete the Polygon as well as the circles (Drawn on the polygon's vertex)
     * -Generalised function so that it can be executed during different operation. E.g. delete, undo, etc.
     *
     * @param verticeX One of the Vertex Point of the Polygon (X-position)
     * @param verticeY One of the Vertex Point of the Polygon (Y-position)
     */
    function deleteGeneralPolygonAndCircles(verticeX: number, verticeY: number) {

        //console.log("deleteGeneralPolygonAndCircles");
        if (geoDS !== undefined) {
            let toBeDeletedPolygonVertexDS: PolygonDatum = {points: []};

            //  Find the Polygon to be deleted based on the clicked vertices
            geoDS.geoData.forEach((eachPolygonDatum) => {
                for (let i = 0; i < eachPolygonDatum.points.length; i++) {
                    let eachData: PolygonVertexDatum = eachPolygonDatum.points[i];
                    if (eachData.x === verticeX && eachData.y === verticeY) {
                        toBeDeletedPolygonVertexDS = eachPolygonDatum;
                        break;
                    }
                }
            });

            //  Iterate through to delete the Polygon
            let newGeoDS: GeometryDatum = {geoData: []};
            let localGeoDS = geoDS;
            //console.log("deletePolygonAndCircles geoDS1: " + geoDS.geoData.length);
            localGeoDS.geoData.forEach((eachPolygonDatum) => {
                let foundFlag: boolean = false;

                for (let i = 0; i < eachPolygonDatum.points.length; i++) {
                    let eachData: PolygonVertexDatum = eachPolygonDatum.points[i];

                    for (let j = 0; j < toBeDeletedPolygonVertexDS.points.length; j++) {
                        let eachDeletedData: PolygonVertexDatum = toBeDeletedPolygonVertexDS.points[j];
                        if (eachData.x === eachDeletedData.x && eachData.y === eachDeletedData.y) {
                            foundFlag = true;
                            //console.log("deletePolygonAndCircles True Reached.");
                            //break;
                        }
                    }
                }

                if (!foundFlag) {
                    newGeoDS.geoData.push(eachPolygonDatum);
                }
            });
            geoDS = newGeoDS;
            setGeoDS(newGeoDS);
            //console.log("deletePolygonAndCircles geoDS2: " + geoDS.geoData.length);

            //  Delete the Vertices Circles
            let localCircleDS: CircleDatum[] = [];
            circleDS.forEach((eachCircleVertice) => {
                let foundFlag: boolean = false;
                toBeDeletedPolygonVertexDS.points.forEach((eachPolygonDatum) => {
                    if (eachCircleVertice.x === eachPolygonDatum.x && eachCircleVertice.y === eachPolygonDatum.y)
                        foundFlag = true;
                });

                if (!foundFlag)
                    localCircleDS.push(eachCircleVertice);
            });
            circleDS = localCircleDS;
            setCircleDS(localCircleDS);

            //  Add to undo history (so that it can be undone)
            let actVertArray: actionVertices[] = [];
            toBeDeletedPolygonVertexDS.points.forEach((eachPolygonDatum) => {
                let actVert: actionVertices = {x: eachPolygonDatum.x, y: eachPolygonDatum.y};
                actVertArray.push(actVert);
            });
            actionLogOperator("delete", "polygonAndCircles", actVertArray);

            reDrawOverlay();
        }
    }

    /**
     * ReDraw Operation
     * -Redraws the Polygon and Circles based on current data.
     * -So that screen can be refresh after an operation: Move/Delete/add/etc.
     */
    function reDrawOverlay() {

        reDrawPolygon();
        reDrawVertexCircles();
    }

    /**
     * ReDraw the Polygon
     * -Removes the currently drawn polgon
     * -Draw the new one in (based on what is in GeoDS)
     */
    function reDrawPolygon() {

        //  Remove current drawn polygon/s
        svge
            .selectAll("polygon")
            .remove();

        //  Draw the newest polygon/s
        let testGeoData: GeometryDatum;
        if (geoDS !== undefined) {
            testGeoData = geoDS;

            svge.selectAll("polygon")
                .data(testGeoData.geoData)
                .enter().append("polygon")
                .attr("points", (datum, index) => {
                    const points = datum.points.map(coord => `${coord.x},${coord.y}`)
                    return points.join(" ");
                })
                .style("fill", "rgba(200, 0, 0, 0.3)")
                .style("stroke", "rgba(0, 0, 0, 0.5)")
                .style("strokeWidth", "10px");
        }
    }

    /**
     * ReDraw the Circles (on the Polygon Verex)
     * -Removes the currently drawn circles
     * -Draw the new one in (based on what is in circleDS)
     */
    function reDrawVertexCircles() {
        //  Ref: https://stackblitz.com/edit/react-saqrv9?file=src%2Fgraph.tsx
        //  https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/d3-drag/d3-drag-tests.ts
        //console.log("reDrawVertexCircles");

        //  Remove current drawn circles
        svge
            .selectAll("circle")
            .remove();

        //  Draw the new circles
        svge
            .selectAll("circle")
            .data(circleDS)
            .enter()
            .append("circle")
            .attr("r", 6.5)
            .style("fill", "steelblue")
            .style("stroke", "white")
            .style("stroke-width", 2.5)
            .on("mouseover", function () {

                if (modeOfOperation === "delete") {
                    d3.select(this).classed("active", true);
                    d3.select(this).property("style").cursor = "pointer";
                } else if (modeOfOperation === "edit") {
                    d3.select(this).classed("active", true);
                    d3.select(this).property("style").cursor = "move";
                }
            })
            .on("mousedown", (event, d) => {

                //console.log("redraw mousedown");
                //console.log(d);

                deletePolygonAndCircles(d.x, d.y);
                return;
            })
            .on("mouseup", (event, d) => {

                //console.log("redraw mouseup");
                //console.log(d);

                deletePolygonAndCircles(d.x, d.y);
                return;
            })
            .call(d3.drag<SVGCircleElement, CircleDatum>()
                //.call(handler
                .on('start', dragstarted)
                .on('drag', dragged)
                .on('end', dragended)
            )
            .attr("cx", (datum, index) => {
                //console.log("datum");
                //console.log(datum);
                return datum.x;
            })
            .attr("cy", (datum, index) => {
                //console.log(datum);
                //console.log(index);
                return datum.y;
            })
            .exit().remove()
        ;
    }

    /**
     * Callback Function: Executed when Circle Drag operation has started.
     * @param event The Event Datum (usually contains the dragstarted event data)
     * @param d The datum of the x,y of the circle on the start of the Drag operation.
     */
    const dragstarted = (event: any, d: any) => {

        if (modeOfOperation === "edit") {
            //d3.select(event.subject).raise().classed("active", true);
            event.sourceEvent.stopPropagation();

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!event.active) {
                //simulation.alphaTarget(0.3).restart();
            }
            //d.fx = d.x;
            //d.fy = d.y;

            //  Add to history
            let actVertArray: actionVertices[] = [];
            let actVert: actionVertices = {x: d.x, y: d.y};
            actVertArray.push(actVert);
            actionLogOperator("edit", "dragstarted", actVertArray);

            //console.log("dragstarted x: " + d.x + ", y: " + d.y);
        }
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /**
     * Callback Function: Executed during the Circle Drag
     * -Event datum  contains the current coordinates
     * -d-Datum: Contains the previous coordinates
     * @param event The Event Datum (usually contains the drag event data)
     * @param d The datum of the x,y of the circle on the during the Drag operation.
     */
    const dragged = (event: any, d: any) => {

        if (modeOfOperation === "edit") {
            //console.log("dragged");
            //console.log(event);
            //console.log(d);

            //d3.select(event.subject).attr("cx", event.x).attr("cy", event.y);
            //d3.select().attr("cx", event.x).attr("cy", event.y);

            const oldX = d.x;
            const oldY = d.y;

            //polygonVertexDS
            if (geoDS !== undefined) {
                let localGeoDS = geoDS;
                //console.log("dragged: localGeoDS");
                localGeoDS.geoData.forEach((eachPolygonDatum) => {
                    let newPolygonVertexDS: PolygonVertexDatum[] = [];
                    for (let i = 0; i < eachPolygonDatum.points.length; i++) {
                        let eachData: PolygonVertexDatum = eachPolygonDatum.points[i];
                        if (eachData.x === oldX && eachData.y === oldY) {
                            eachData.x = event.x;
                            eachData.y = event.y;
                            newPolygonVertexDS.push(eachData);
                        } else {
                            newPolygonVertexDS.push(eachData)
                        }
                    }
                    eachPolygonDatum.points = newPolygonVertexDS;
                });
                setGeoDS(localGeoDS);
            }
            // else {
            //     console.log("dragged: localGeoDS2");
            // }

            d.x = event.x;
            d.y = event.y;
            //console.log(d);

            let newCircleDS: CircleDatum[] = [];
            for (let i = 0; i < circleDS.length; i++) {
                let eachData: CircleDatum = circleDS[i];
                if (eachData.name === d.name)
                    newCircleDS.push(d);
                 else
                    newCircleDS.push(circleDS[i])
            }

            //console.log(newCircleDS);
            setCircleDS(newCircleDS);

            // d.fx += event.dx;
            // d.fy += event.dy;

            //d.fixed = true;
            // d3.select(this).attr("cx", d.x = d3.event.x).attr("cy", d.y = d3.event.y);

            //d.fx = event.x;
            //d.fy = event.y;

            //reDrawVertexCircles();

            //  Add to history
            // let actVertArray: actionVertices[]=[];
            // let actVert: actionVertices={x:oldX, y:oldY};
            // actVertArray.push(actVert);
            // actVert={x:event.x, y:event.y};
            // actVertArray.push(actVert);
            //
            // toBeDeletedPolygonVertexDS.points.forEach((eachPolygonDatum) => {
            //     let actVert: actionVertices={x:eachPolygonDatum.x, y:eachPolygonDatum.y};
            //     actVertArray.push(actVert);
            // });
            // actionLogOperator("delete","polygonAndCircles",actVertArray);

            reDrawOverlay();
        }
    };

    /**
     * Move the Circles (on the Polygon Verex)
     * @param oldX The old X-position
     * @param oldY The old Y-position
     * @param newX The new X-position
     * @param newY The new Y-position
     */
    function moveVertices(oldX: number, oldY: number, newX: number, newY: number) {

        //  Move Polygon
        if (geoDS !== undefined) {

            let localGeoDS = geoDS;
            localGeoDS.geoData.forEach((eachPolygonDatum) => {
                let newPolygonVertexDS: PolygonVertexDatum[] = [];
                for (let i = 0; i < eachPolygonDatum.points.length; i++) {
                    let eachData: PolygonVertexDatum = eachPolygonDatum.points[i];
                    if (eachData.x === oldX && eachData.y === oldY) {
                        eachData.x = newX;
                        eachData.y = newY;
                        newPolygonVertexDS.push(eachData);
                    } else
                        newPolygonVertexDS.push(eachData)
                }
                eachPolygonDatum.points = newPolygonVertexDS;
            });
            setGeoDS(localGeoDS);
        }

        //  Move Circles
        let newCircleDS: CircleDatum[] = [];
        for (let i = 0; i < circleDS.length; i++) {
            let eachData: CircleDatum = circleDS[i];
            if (eachData.x === oldX && eachData.y === oldY) {
                eachData.x = newX;
                eachData.y = newY;
            }
            newCircleDS.push(circleDS[i])
        }

        //console.log(newCircleDS);
        setCircleDS(newCircleDS);

        reDrawOverlay();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    /**
     * Callback Function: Executed when Circle Drag operation has ended.
     * @param event The Event Datum (usually contains the dragended event data)
     * @param d The datum of the x,y of the circle on the end of the Drag operation.
     */
    const dragended = (event: any, d: any) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!event.active) {
            //simulation.alphaTarget(0);
        }

        //d.fx = null;
        //d.fy = null;

        if (modeOfOperation === "edit") {
            //  Add to history
            let actVertArray: actionVertices[] = [];
            let actVert: actionVertices = {x: d.x, y: d.y};
            actVertArray.push(actVert);
            actionLogOperator("edit", "dragended", actVertArray);
        }

        //console.log("dragended x: " + d.x + ", y: " + d.y);
    };


    /**
     * Click on Next Item
     * Next: Finish 1 Image and go to next, all finish upload
     * @param event
     */
    async function onClickNextItem(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        //console.log("Inside onClickNextItem")
        if (setOfImage) {
            //console.log("Inside 2nd")
            const currentIndex = currentQnIndex + 1;
            if (currentIndex < setOfImage.length) {
                //  Move to next image.
                setCurrentQnIndex(currentIndex);
                //console.log(currentIndex)
                setImagePath(setOfImage[currentIndex].image_url);
                //console.log(setOfImage[currentIndex].image_url)
                setImageQn(setOfImage[currentIndex].image_qn);
            } else {
                //  Finish all images.
                //  TODO: Upload data (Template does not have this code)
                setCurrentQnIndex(0);
                setImagePath(setOfImage[0].image_url);
                setImageQn(setOfImage[0].image_qn);
            }
            //console.log(currentIndex + ", " + setOfImage.length)
            setProgressState((currentIndex / setOfImage.length) * 100)
        }
    }

    /**
     * Click on Add Polygon
     * - Add new Polygon in the centre of the image.
     * - This also add the Circles (vertex)
     */
    async function onClickAddPolygonSet() {

        //  Only add in Edit mode
        if (modeOfOperation === "delete")
            return;

        //  Only do this is if available
        if (geoDS !== undefined) {

            //  For History Logging
            let actVertArrayForHistory: actionVertices[] = [];

            const max_X = 640, max_Y = 640;
            const midX = max_X / 2;
            const midY = max_Y / 2;

            let newSet: PolygonVertexDatum[] = [];

            let indexCounter = 0;
            geoDS.geoData.forEach((eachPolygonDatum) => {
                if (indexCounter === 0)
                    indexCounter = eachPolygonDatum.points.length;
                else
                    indexCounter = indexCounter + eachPolygonDatum.points.length;
            });


            //  Vertex and Vertex Circles
            let i = indexCounter;
            let newNode1: PolygonVertexDatum = {vertexId: "Node" + i, label: "Node" + i, x: midX - 20, y: midY - 20};
            newSet.push(newNode1);
            let circleNode1: CircleDatum = {nodeId: "Node" + i, name: "Node" + i, label: "Node" + i, x: midX - 20, y: midY - 20, r: 6.5, color: "steelblue"};
            circleDS.push(circleNode1);
            let actVert: actionVertices = {x: midX - 20, y: midY - 20};
            actVertArrayForHistory.push(actVert);
            i++;

            let newNode2: PolygonVertexDatum = {vertexId: "Node" + i, label: "Node" + i, x: midX + 20, y: midY - 20};
            newSet.push(newNode2);
            let circleNode2: CircleDatum = {nodeId: "Node" + i, name: "Node" + i, label: "Node" + i, x: midX + 20, y: midY - 20, r: 6.5, color: "steelblue"};
            circleDS.push(circleNode2);
            actVert = {x: midX + 20, y: midY - 20};
            actVertArrayForHistory.push(actVert);
            i++;

            let newNode3: PolygonVertexDatum = {vertexId: "Node" + i, label: "Node" + i, x: midX + 20, y: midY + 20};
            newSet.push(newNode3);
            let circleNode3: CircleDatum = {nodeId: "Node" + i, name: "Node" + i, label: "Node" + i, x: midX + 20, y: midY + 20, r: 6.5, color: "steelblue"};
            circleDS.push(circleNode3);
            actVert = {x: midX + 20, y: midY + 20};
            actVertArrayForHistory.push(actVert);
            i++;

            let newNode4: PolygonVertexDatum = {vertexId: "Node" + i, label: "Node" + i, x: midX - 20, y: midY + 20};
            newSet.push(newNode4);
            let circleNode4: CircleDatum = {nodeId: "Node" + i, name: "Node" + i, label: "Node" + i, x: midX - 20, y: midY + 20, r: 6.5, color: "steelblue"};
            circleDS.push(circleNode4);
            actVert = {x: midX - 20, y: midY + 20};
            actVertArrayForHistory.push(actVert);


            let polygonData: PolygonDatum = {points: newSet};

            let currentGeoData: GeometryDatum | undefined = geoDS;
            if (currentGeoData === undefined) {
                let geoData: GeometryDatum = {geoData: [polygonData]};
                setGeoDS(geoData);
            } else {
                currentGeoData.geoData.push(polygonData)
                setGeoDS(currentGeoData);
            }

            //  Add to history
            actionLogOperator("add", "polygonAndCircles", actVertArrayForHistory);

            reDrawOverlay();
        }
    }

    /**
     * Click on Add Polygon
     * - The difference between this and onClickAddPolygonSet() function, this function is primarily used during undo operation.
     * - When the user undo a delete operation, we have to add back in the previously deleted polygon.
     * - Add new Polygon in the centre of the image.
     * - This also add the Circles (vertex)
     * @param actVertArray The polygon datum to be added and drawn on the screen.
     */
    function addPolygonAndCircleSet(actVertArray: actionVertices[]) {

        if (actVertArray === undefined)
            return;

        if (actVertArray.length === 0)
            return;

        //  Only do this is if available
        if (geoDS !== undefined) {

            let newSet: PolygonVertexDatum[] = [];

            let indexCounter = 0;
            geoDS.geoData.forEach((eachPolygonDatum) => {
                if (indexCounter === 0)
                    indexCounter = eachPolygonDatum.points.length;
                else
                    indexCounter = indexCounter + eachPolygonDatum.points.length;
            });

            //  Vertex and Vertex Circles
            let i = indexCounter;
            actVertArray.forEach((eachSet) => {
                let newNode: PolygonVertexDatum = {vertexId: "Node" + i, label: "Node" + i, x: eachSet.x, y: eachSet.y};
                newSet.push(newNode);
                let circleNode: CircleDatum = {nodeId: "Node" + i, name: "Node" + i, label: "Node" + i, x: eachSet.x, y: eachSet.y, r: 6.5, color: "steelblue"};
                circleDS.push(circleNode);
                i++;
            });

            let polygonData: PolygonDatum = {points: newSet};

            let currentGeoData: GeometryDatum | undefined = geoDS;
            if (currentGeoData === undefined) {
                let geoData: GeometryDatum = {geoData: [polygonData]};
                setGeoDS(geoData);
            } else {
                currentGeoData.geoData.push(polygonData)
                setGeoDS(currentGeoData);
            }

            reDrawOverlay();
        }
    }

    /**
     * Click on Delete
     * - Switches to Delete mode, in this mode, when user click on the Circles (Vertex),
     * - it will delete the Polygon and Circles.
     */
    function onClickDelete() {

        //  Force a ReDraw if not SVG callbacks does not hold the correct modeOfOperation State.
        reDrawOverlay();

        //  Set Mode of Operation to Edit Mode
        setModeOfOperation("delete");
        modeOfOperation = "delete";
        //console.log("delete selected: " + modeOfOperation);

        //  Update the undo-log
        const actVertArray: actionVertices[] = [{x: -99, y: -99}];
        actionLogOperator("delete", "mode", actVertArray);
    }

    /**
     * Click on Edit
     * - Switches to Edit mode, in this mode.
     * - User will be able to move and add polygon to the image.
     */
    function onClickEdit() {

        //  Force a ReDraw if not SVG callbacks does not hold the correct modeOfOperation State.
        reDrawOverlay();

        //  Set Mode of Operation to Edit Mode
        setModeOfOperation("edit");
        modeOfOperation = "edit";
        //console.log("edit selected: " + modeOfOperation);

        //  Update the undo-log
        const actVertArray: actionVertices[] = [{x: -99, y: -99}];
        actionLogOperator("edit", "mode", actVertArray);
    }

    /**
     * Undo the previous operation
     * - Goes to action History, pop the last entry.
     * - Undo the last entry. E.g. If the entry is delete polygon, it will take what was deleted (vertices datum) and re-add into the screen.
     * - Hence, in all operation above, we have to add the action into the actionHistory (AKA the undo log)
     * - So that when the user clicks on this, we can pop and undo the operation.
     *
     * Notes: In the future, if new actions are added, remember to add the undo operation into this as well.
     */
    function onClickUndo() {

        if (actionHistory === undefined)
            return;

        if (actionHistory.length === 0)
            return;

        //  get a local copy: Cannot work on the State, the pop operation does not remove items, only local copy can pop.
        let localActionHistory: actionItem[] = []
        actionHistory.forEach((eachItem) => {
            localActionHistory.push(eachItem);
        });

        //  Force a ReDraw if not SVG callbacks does not hold the correct modeOfOperation State.
        reDrawOverlay();

        let latest: actionItem | undefined = {actionType: "nil", actionTarget: "nil", vertices: [], actionTime: "nil"};
        latest = localActionHistory.pop();

        if (latest !== undefined) {

            if (latest.actionType === "delete") {

                if (latest.actionTarget === "mode") {
                    //  Set back to Edit mode.
                    onClickEdit();
                } else if (latest.actionTarget === "polygonAndCircles") {
                    //console.log("onClickUndo delete polygonAndCircles");

                    //  Set back to Edit mode.
                    addPolygonAndCircleSet(latest.vertices);
                }
            } else if (latest.actionType === "edit") {

                if (latest.actionTarget === "mode") {
                    //  Set back to Delete mode.
                    onClickDelete();
                } else if (latest.actionTarget === "dragended") {
                    //  Set back to Delete mode.
                    let curretX = latest.vertices[0].x;
                    let curretY = latest.vertices[0].y;

                    let prevOne = localActionHistory.pop();
                    if (prevOne != undefined && prevOne.actionTarget === "dragstarted") {
                        let prevX = prevOne.vertices[0].x;
                        let prevY = prevOne.vertices[0].y;

                        moveVertices(curretX, curretY, prevX, prevY);
                    }
                }
            } else if (latest.actionType === "add") {

                if (latest.actionTarget === "polygonAndCircles") {
                    //console.log("onClickUndo add polygonAndCircles");

                    //  Set back to Delete mode.
                    deleteGeneralPolygonAndCircles(latest.vertices[0].x, latest.vertices[0].y)
                }
            }

            //  Force a ReDraw if not SVG callbacks does not hold the correct modeOfOperation State.
            reDrawOverlay();

            actionHistory = localActionHistory;
            setActionHistory(localActionHistory);
            //console.log("actionLogOperator");
            //console.log(actionHistory);
        } else
            return;
    }

    /**
     * Logs the action into the Undo History Log
     * - When an action is done, add/delete/move/etc. the action will be added into this history.
     * - This includes the Action Type: Was in a Edit operation or Delete operation
     * - Action Target: The action target in the operation, e.g. Deleting a polygon, edit-Moving a circle(vertex), etc.
     * - As well as the VerArray: Which holds the data of the polygon or circle. This allows undo to be done. E.g. if you delete a polygon, the polygon datum will need to be stored here. So that
     * if the user undo, we can pop this VerArray out and redraw the Polygon back to screen. This applies to circles as well (only circle has only 1 pair X-Y coordinates)
     *
     * @param actionType The action Type: edit/Delete
     * @param actionTarget The targeted action: Drag/delete-Polygon/etc
     * @param actVerArray Holds the Vertex data: 1 pair for Circle or 4 pair for polygon
     */
    function actionLogOperator(actionType: string, actionTarget: string, actVerArray: actionVertices[]) {

        const currentTime = getCurrentTime();

        //  Update the log
        let actionEntry: actionItem = {actionType: actionType, actionTarget: actionTarget, vertices: actVerArray, actionTime: currentTime};
        if (actionHistory === undefined) {
            let localActionHistory: actionItem[] = [];
            localActionHistory.push(actionEntry);
            actionHistory = localActionHistory;
            setActionHistory(localActionHistory);
        } else
            actionHistory.push(actionEntry);

        //console.log("actionLogOperator");
        //console.log(actionHistory);
    }

    /**
     * Get the Current Time.
     */
    function getCurrentTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = ("00" + (now.getMonth() + 1)).slice(-2);
        const date = ("00" + (now.getDate())).slice(-2);
        const hours = ("00" + (now.getHours())).slice(-2);
        const min = ("00" + (now.getMinutes())).slice(-2);
        const sec = ("00" + (now.getSeconds())).slice(-2);

        return [year, month, date].join('/') + " " + [hours, min, sec].join(':')
    }

    return (
        <>
            <div className="main flex items-center flex-col">
                <div className="top flex items-center">
                    <div className="progress w-100">
                        <ProgressBar now={progressState} label={`${progressState}% Completed.`}/>
                        Mode of Operation: {modeOfOperation}
                    </div>
                </div>

                <div className="middle flex items-center flex-row">
                    <div className="polymenu flex items-center flex-col">


                        <button className="moveButton" value="move" onClick={onClickEdit}>
                            <img src="/icon/moveImage.png" id="moveImage" alt="No Icon" width="25"/><br/>
                            <p id="moveLabel">Edit<br/></p>
                        </button>

                        <button className="addButton" value="add" onClick={onClickAddPolygonSet}>
                            <img src="/icon/addImage.png" id="addImage" alt="No Icon" width="25"/><br/>
                            <p id="addLabel">Add<br/></p>
                        </button>

                        <button className="deleteButton" value="delete" onClick={onClickDelete}>
                            <img src="/icon/deleteImage.png" id="deleteImage" alt="No Icon" width="25"/><br/>
                            <p id="deleteLabel">Delete<br/></p>
                        </button>

                        <hr className="buttonLine"/>

                        {/*<button className="hideButton">*/}
                        {/*    <img src="/icon/eye-slash.png" id="hideImage" alt="No Icon" width="25"/><br/>*/}
                        {/*    <p id="hideLabel">Hide<br/></p>*/}
                        {/*</button>*/}

                        {/*<button className="recenterButton">*/}
                        {/*    <img src="/icon/recenterImage.png" id="recenterImage" alt="No Icon" width="25"/><br/>*/}
                        {/*    <p id="recenterLabel">Center<br/></p>*/}
                        {/*</button>*/}

                        <button className="undoButton" onClick={onClickUndo}>
                            <img src="/icon/undoImage.png" id="undoImage" alt="No Icon" width="25"/><br/>
                            <p id="undoLabel">Undo<br/></p>
                        </button>
                    </div>

                    <div className="overflow-hidden text-gray-700 ">
                        <div className="flex flex-wrap w-full">
                            <div className="w-full p-1 md:p-2 img-overlay-wrap" id="satellite">
                                <img src={imagepath} id="floorplan-image" className="block object-cover object-center w-full h-full rounded-lg" alt="gallery"></img>
                                {/*<img src={imagepath} id="floorplan-image" className="block object-cover object-center w-full h-full rounded-lg" alt="gallery" onLoad={() => processJSON()}></img>*/}
                                <svg id="image-overlay" width={640} height={640}></svg>
                            </div>
                        </div>
                    </div>

                </div>


                <div className="bottom flex items-center flex-col">
                    <div className="question">
                        <p className="questionText">{imageQn}</p>
                    </div>


                    <div className="submit">
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                            bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                            onClick={onClickNextItem}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}