import React from "react";

export interface IReportTableHeaderProps {
    label: string
}

export function ReportTableHeader ({label}: IReportTableHeaderProps) {
    return (
        <th className='text-left text-sm text-gray-900 px-6 py-4 whitespace-nowrap'>{label}</th>
    )
}