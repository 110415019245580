import React from 'react';
import {MainRoutes} from "./MainRoutes";
import {UserFactory} from "./domain/user/User";
import {Project, ProjectFactory} from "./domain/project/Project";
import {MockProjectRepository} from "./infra/repository/MockProjectRepository";
import {ProjectService} from "./usecase/project/ProjectService";
import {CurrentUser} from "./usecase/auth/CurrentUser";
import {AuthenticationService} from "./infra/api/AuthenticationService";
import {MockBlueprintRepository} from "./infra/repository/MockBlueprintRepository";
import {MockHITRepository} from "./infra/repository/MockHITRepository";
import {MockHITBatchRepository} from "./infra/repository/MockHITBatchRepository";
import {IBlueprintRepository} from "./domain/blueprint/BlueprintRepository";
import {IHITBatchRepository} from "./domain/mturk/HITBatchRepository";
import {IHITRepository} from "./domain/mturk/HITRepository";
import {IProjectRepository} from "./domain/project/ProjectRepository";
import {ProjectRepository} from "./infra/repository/ProjectRepository";
import {BlueprintRepository} from "./infra/repository/BlueprintRepository";
import {IMTurkService} from "./domain/mturk/MTurkService";
import {MockMTurkService} from "./infra/api/MockMTurkService";
import {HITFactory} from "./domain/mturk/HIT";
import {HITBatchFactory} from "./domain/mturk/HITBatch";
import {MTurkService} from "./infra/api/MTurkService";
import {HITBatchRepository} from "./infra/repository/HITBatchRepository";
import {IHITResponseRepository} from "./domain/mturk/HITResponseRepository";
import {MockHITResponseRepository} from "./infra/repository/MockHITResponseRepository";
import {IHITBatchReportRepository} from "./domain/mturk/HITBatchReportRepository";
import {MockHITBatchReportRepository} from "./infra/repository/MockHITBatchReportRepository";
import {HITBatchReportRepository} from "./infra/repository/HITBatchReportRepository";
import {HITResponseRepository} from "./infra/repository/HITResponseRepository";
import {UserTasksService} from "./infra/api/UserTasksService";

export type IGlobalContext = {
    authenticationService: AuthenticationService,
    blueprintRepository: IBlueprintRepository,
    currentUser: CurrentUser,
    currentProject: Project | undefined,
    setCurrentProject: (project: Project) => void
    hitBatchRepository: IHITBatchRepository,
    hitRepository: IHITRepository,
    hitResponseRepository: IHITResponseRepository,
    hitBatchReportRepository: IHITBatchReportRepository,
    projectFactory: ProjectFactory,
    projectRepository: IProjectRepository,
    projectService: ProjectService,
    userFactory: UserFactory,
    mTurkService: IMTurkService,
    userTasksService: UserTasksService,
}

export const GlobalContext = React.createContext<IGlobalContext | null>(null);

const projectFactory = new ProjectFactory()
const userFactory = new UserFactory();

export const authenticationService = new AuthenticationService();
const projectService = new ProjectService()
let mTurkService: IMTurkService;
const userTasksService=new UserTasksService();

const currentUser = CurrentUser.getInstance();
let user = currentUser.getCurrentUser();

if (typeof user === "undefined") {
    user = userFactory.create('anonymous-user', 0);
}
currentUser.setCurrentUser(user);

let blueprintRepository: IBlueprintRepository;
let hitRepository: IHITRepository;
let hitBatchRepository: IHITBatchRepository;
let hitResponseRepository: IHITResponseRepository;
let hitBatchReportRepository: IHITBatchReportRepository;
let projectRepository: IProjectRepository;

console.log("Node Environment:", process.env.NODE_ENV);
if (process.env.NODE_ENV === "development") {

    blueprintRepository = new MockBlueprintRepository();
    hitRepository = new MockHITRepository();
    hitBatchRepository = new MockHITBatchRepository();
    hitResponseRepository = MockHITResponseRepository.getInstance();
    hitBatchReportRepository = MockHITBatchReportRepository.getInstance();
    projectRepository = MockProjectRepository.getInstance();

    const hitFactory = new HITFactory();
    const hitBatchFactory = new HITBatchFactory();
    mTurkService = new MockMTurkService(currentUser, hitRepository, hitFactory, hitBatchRepository, hitBatchFactory,
        blueprintRepository, hitResponseRepository, hitBatchReportRepository);
} else {
    blueprintRepository = new BlueprintRepository(currentUser);
    hitBatchRepository = new HITBatchRepository(currentUser);
    projectRepository = new ProjectRepository(currentUser);
    mTurkService = new MTurkService(currentUser);
    hitBatchReportRepository = new HITBatchReportRepository(currentUser)
    hitResponseRepository = new HITResponseRepository(currentUser);

    hitRepository = new MockHITRepository();  // This is not needed!
}

export default function App () {
    const [currentProject, setCurrentProject] = React.useState<Project | undefined>(undefined);

    return (
      <div className="App">
          <GlobalContext.Provider value={{
              authenticationService,
              blueprintRepository,
              currentUser,
              currentProject,
              setCurrentProject,
              hitBatchRepository,
              hitRepository,
              hitResponseRepository,
              hitBatchReportRepository,
              projectFactory,
              projectRepository,
              projectService,
              userFactory,
              mTurkService,
              userTasksService
          }}>
              <MainRoutes />
          </GlobalContext.Provider>
      </div>
  );
}
