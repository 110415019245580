import React, {Fragment, useState} from 'react'
import {Combobox, Transition} from '@headlessui/react'
import {CheckIcon, SelectorIcon} from '@heroicons/react/solid'
import {BlueprintTypes, IBlueprintType} from "../../../../../domain/blueprint/Blueprint";
import {BlueprintPrototypeContext, IBlueprintPrototypeContext} from "../Blueprints";


export default function BlueprintTypeCombobox() {
    const {blueprintPrototype, setBlueprintPrototype} = React.useContext(BlueprintPrototypeContext) as IBlueprintPrototypeContext
    const [query, setQuery] = useState<string>('');

    let currentSelected;
    if (blueprintPrototype.typeId) {
        const currentType = BlueprintTypes.find((type) => type.typeId === blueprintPrototype.typeId);
        currentSelected = currentType!.name;
    } else {
        currentSelected = BlueprintTypes[0].name;
    }
    const [selected, setSelected] = useState<string>(currentSelected);


    function handleBlueprintTypeSelected (selected: string) {
        const blueprintType: IBlueprintType | undefined = BlueprintTypes.find((type) => type.name === selected)

        if (blueprintType) {
            setBlueprintPrototype({...blueprintPrototype, "typeId": blueprintType.typeId})
        }

        return selected;
    }

    const filteredBlueprintNames: string[] =
        query === ''
            ? BlueprintTypes.map((type) => type.name)
            : BlueprintTypes.map((type) => type.name).filter((blueprintType) =>
                blueprintType.toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    return (
        <div className="appearance-none block w-full">
            <Combobox value={selected} onChange={setSelected}>
                <div className="relative mt-1">
                    <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left
                    shadow-md
                    sm:text-sm">
                        <Combobox.Input
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                            displayValue={handleBlueprintTypeSelected}
                            onChange={(event) => {
                                return setQuery(event.target.value)
                            }}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options
                            className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md
                            bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredBlueprintNames.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredBlueprintNames.map((blueprintName, index) => (
                                    <Combobox.Option
                                        key={index}
                                        className={({active}) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active ? 'bg-blue-600 text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={blueprintName}
                                    >
                                        {({selected, active}) => (
                                            <>
                        <span
                            className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {blueprintName}
                        </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                            active ? 'text-white' : 'text-blue-600'
                                                        }`}
                                                    >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
}
