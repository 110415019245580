import React from "react";
import {Blueprint} from "../../domain/blueprint/Blueprint";

export interface IBlueprintSurveyLinkProps {
    blueprint: Blueprint
}

export function BlueprintSurveyLink ({blueprint}: IBlueprintSurveyLinkProps) {
    return (
        <>
            <div className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6
                            text-left align-middle shadow-md transition-all">
                <h2 className="text-lg font-medium leading-6 text-gray-600">
                    Survey Instruction
                </h2>
                <div className="mt-2 pb-6">
                    <p className="text-sm text-gray-800">
                        {blueprint.items[0].body}
                    </p>
                </div>

                <h2 className="text-lg font-medium leading-6 text-gray-600">
                    Survey Link
                </h2>
                <div className="mt-2 pb-6">
                    <p className="text-sm text-gray-800">
                        {blueprint.items[1].body}
                    </p>
                </div>

                <h2 className="text-lg font-medium leading-6 text-gray-600">
                    Completion Code
                </h2>
                <div className="mt-2 pb-6">
                    <p className="text-sm text-gray-500">
                        * A participant will be prompted to submit a completion code.
                    </p>
                </div>

            </div>
        </>
    )
}