import {ConsoleHeader} from "../requester/console/ConsoleHeader";
import {Outlet} from "react-router-dom";
import React from "react";

/**
 * Image Task
 * Holds the following:
 * - Image Rate
 * - Image Compare
 * - Image Polygon Edit
 *
 * Notes
 * - This version is originally from Rosie's code, adapted into Typescript.
 * - Image Rate and Image Compare does not have Polygon overlay.
 * - This is a template: It does not have backend code: No download, No upload.
 * @author William Tan 2022-Oct-11.
 * @constructor
 */
export function ImageTask() {

    return (
        <>
            <ConsoleHeader title='Image Task' subtitle=''/>
            <Outlet/>
        </>
    )
}