import React from "react";
import {Link} from "react-router-dom";

export interface IBlueprintTableRowProp {
    key: string;
    batchId: number;
    title: string;
    description: string;
    environment: string;
}

export function ReportTableRow ({batchId, title, description,environment}: IBlueprintTableRowProp) {
    return (
        <tr className='bg-gray-100 border-b'>
            <td className="text-left text-sm text-sky-600 underline decoration-solid font-light px-6 py-4 whitespace-nowrap">
                <Link to={`${batchId}`} key={batchId}>{title}</Link>
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {description}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {environment}
            </td>
        </tr>
    )
}