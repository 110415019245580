import React from "react";
import {useParams} from "react-router-dom";

export function Task () {
    const params = useParams()
    const projectId: number = parseInt(params.projectId!, 10);
    const taskBatchId: number = parseInt(params.taskBatchId!, 10);

    if (Number.isNaN(projectId) || Number.isNaN(taskBatchId)) {
        return <>The URL should follow the following format: `/task/:int/:int`</>
    } else {
        return (
            <>
                <h1>Task</h1>
                <p>Project ID: {projectId}</p>
                <p>Task Batch ID: {taskBatchId}</p>
            </>
        )
    }
}