import {getRandomInt} from "../../../util/util";

export interface IBlueprintInstructionPrototype {
    id: number;
    title?: string;
    body?: string;
}

export class BlueprintInstructionPrototype implements IBlueprintInstructionPrototype {
    id: number;
    title: string;
    body: string;
    constructor(id: number, title: string, body: string) {
        this.id = id;
        this.title = title;
        this.body = body;
    }
}

export class BlueprintInstructionPrototypeFactory {
    create(title: string, body: string, id?: number): BlueprintInstructionPrototype {
        if (id === undefined) {
            id = getRandomInt();
        }
        return new BlueprintInstructionPrototype(id, title, body);
    }

    copy(instruction: BlueprintInstructionPrototype, id?: number, title?: string, body?: string): BlueprintInstructionPrototype {
        return new BlueprintInstructionPrototype(
            id ? id : instruction.id,
            title ? title : instruction.title,
            body ? body : instruction.body
        )
    }
}