import {IHITResponseRepository} from "../../domain/mturk/HITResponseRepository";
import {HITResponse} from "../../domain/mturk/HITResponse";

export class MockHITResponseRepository implements IHITResponseRepository {
    responses: HITResponse[];
    private static instance: MockHITResponseRepository;

    constructor(hitResponses?: HITResponse[]) {
        if (hitResponses) {
            this.responses = hitResponses
        } else {
            this.responses = [];
        }
    }

    public static getInstance(): MockHITResponseRepository {
        if (!MockHITResponseRepository.instance) {
            MockHITResponseRepository.instance = new MockHITResponseRepository()
        }
        return MockHITResponseRepository.instance;
    }

    add(response: HITResponse): Promise<number> {
        this.responses.push(response)
        return Promise.resolve(response.id);
    }

    getById(id: number): Promise<HITResponse | undefined> {
        return new Promise((resolve) => {
            const results = this.responses.filter(res => res.id === id);
            if (results.length > 0) {
                resolve(results[0]);
            } else {
                resolve(undefined);
            }
        })
    }

    listByTaskBatchId(id: number): Promise<HITResponse[]> {
        return new Promise((resolve) => {
            const results = this.responses.filter(res => res.batchId === id);
            resolve(results);
        });
    }

    updateByTaskBatchId(id: number, accessKey: string, secretKey: string): Promise<HITResponse[]> {
        return this.listByTaskBatchId(id);
    }
}