import {IBlueprintQuestion, IBlueprintQuestionFactory} from "./BlueprintQuestion";

export class BinaryQuestion implements IBlueprintQuestion {
    title: string;
    options: string[];
    body?: string;

    constructor(title: string, options: string[], body?: string) {
        this.title = title;
        this.options = options;
        this.body = body;
    }
}

export class BinaryQuestionFactory implements IBlueprintQuestionFactory {
    create(title: string, options: string[], body?: string): BinaryQuestion {
        if (options.length < 2) {
            throw new Error("BinaryQuestion should not have less than 2 answer options");
        } else if (options.length > 2) {
            throw new Error("BinaryQuestion should not have more than 2 answer options");
        }

        return new BinaryQuestion(title, options, body);
    }
}