import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BatchTableHeader} from "./BatchTableHeader";
import {GlobalContext, IGlobalContext} from "../../../../../App";
import {HITBatch} from "../../../../../domain/mturk/HITBatch";
import {BatchTableRow} from "./BatchTableRow";
import {getHITURL} from "../../../../../domain/mturk/HIT";

export function BatchTable () {
    const {hitBatchRepository, currentProject} = React.useContext(GlobalContext) as IGlobalContext;

    const [batches, setBatches] = useState<HITBatch[]>([]);

    useEffect(() => {
        if (currentProject) {
            const fetchBatches = async () => {
                const _batches = await hitBatchRepository.listByProjectId(currentProject.id);
                setBatches(_batches)
            }
            fetchBatches().catch(console.error)
        }
    }, [currentProject, hitBatchRepository])


    return (
        <>
            <div className="flex items-center justify-between py-2 px-6">
                <div>
                    <p className="text-left text-sm font-medium text-gray-500">
                        A list of task batches.
                    </p>
                </div>
                <div className="">
                    <button
                        type='button'
                        className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                            bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                    >
                        <Link to='create'>
                            <span className="text-sm font-semibold tracking-wide">Create a New Task Batch</span>
                        </Link>
                    </button>
                </div>
            </div>
            <div className='flex flex-col'>
                <div className='overflow-x-auto'>
                    <div className='py-2 inline-block min-w-full sm:px-0 lg:px-0'>
                        <div className="overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='bg-white border-b'>
                                <tr>
                                    <BatchTableHeader label={'AMT HIT ID'}></BatchTableHeader>
                                    <BatchTableHeader label={'Batch Title'}></BatchTableHeader>
                                    <BatchTableHeader label={'Batch Description'}></BatchTableHeader>
                                    <BatchTableHeader label={'Source Blueprint Title'}></BatchTableHeader>
                                    <BatchTableHeader label={'Blueprint Type'}></BatchTableHeader>
                                    <BatchTableHeader label={'Created by'}></BatchTableHeader>
                                    <BatchTableHeader label={'Environment'}></BatchTableHeader>
                                    <BatchTableHeader label={'Task Link'}></BatchTableHeader>
                                </tr>
                                </thead>
                                <tbody>

                                {batches.map((batch, batchIndex) => (
                                    <BatchTableRow
                                        key={batchIndex}
                                        amtHITID={batch.taskAttributes.amt_hit_id}
                                        batchTitle={batch.taskAttributes.title}
                                        batchDescription={batch.taskAttributes.description}
                                        blueprintTitle={batch.blueprint.title}
                                        blueprintTypeId={batch.blueprint.typeId}
                                        createdBy={batch.createdBy.name}
                                        //  original: environment={batch.hits[0].production ? "Production" : "Sandbox"}
                                        //  Check Hit Length: Just-in-case hits is zero.
                                        environment={batch.hits.length>0 ? (batch.hits[0].production ? "Production" : "Sandbox") : "Sandbox-NilHits" }
                                        link={batch.hits.length > 0 ? getHITURL(batch.hits[0]) : ""}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}