import {IProject, Project} from "../../domain/project/Project";
import {User} from "../../domain/user/User";
import {IProjectRepository} from "../../domain/project/ProjectRepository";

export class MockProjectRepository implements IProjectRepository {
    _list: Project[];
    private static instance: MockProjectRepository;

    constructor() {
        this._list = [];
    }

    public static getInstance(): MockProjectRepository {
        if (!MockProjectRepository.instance) {
            MockProjectRepository.instance = new MockProjectRepository()
        }
        return MockProjectRepository.instance;
    }

    list(): Promise<Project[]> {
        return Promise.resolve(this._list);
    }

    add(project: Project): Promise<string> {
        this._list.push(project);
        return Promise.resolve("success");
    }

    getById(onwer: User, projectId: number): Promise<Project | undefined> {
        const project = this._list.find((project: Project) => project.id === projectId);
        return Promise.resolve(project);
    }

    getByOwner(owner: User): Promise<IProject[] | undefined> {
        const projects = this._list.filter((project: Project) => project.owner === owner)
        return Promise.resolve(projects);
    }
}