import {IUser, User} from "../user/User";
import {getRandomInt} from "../util/util";

export interface IProject {
    id: number;
    title: string;
    description: string;
    owner: IUser
}

export class Project implements IProject {
    id: number;
    title: string;
    description: string;
    owner: User

    constructor(id: number, title: string, owner: User, description: string) {
        this.id = id;
        this.title = title;
        this.owner = owner;
        this.description = description;
    }
}

export interface IProjectFactory {
    create(title: string, owner: User, description: string): IProject;
}

export class ProjectFactory implements IProjectFactory {
    create(title: string, owner: User, description: string): Project {
        const projectId = getRandomInt();
        return new Project(projectId, title, owner, description);
    }
}