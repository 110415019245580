import React, {Fragment, useState} from "react";
import {Dialog, Transition, Menu} from "@headlessui/react";
import {ArrowCircleDownIcon} from "@heroicons/react/solid";
import {GlobalContext, IGlobalContext} from "../../../App";
import {useNavigate} from "react-router-dom";

export interface INewProjectModalProp {
    createProject: (title: string, description: string) => Promise<void>
}

type NewProjectFormState = {
    title: string;
    description: string;
}

export function NewProjectModal ({createProject}: INewProjectModalProp) {
    const {authenticationService, currentUser} = React.useContext(GlobalContext) as IGlobalContext;
    let navigate = useNavigate();

    let [isOpen, setIsOpen] = useState(false);
    let [formValues, setFormValues] = useState<NewProjectFormState>({title: "", description: ""})

    function openModal() {
        setIsOpen(true);
    }

    function closeModal () {
        setIsOpen(false);
        setFormValues({title: "", description: ""})
    }

    async function clickCreate () {
        await createProject(formValues.title, formValues.description);
        closeModal();
    }

    function handleTitleChange (e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({title: e.target.value, description: formValues.description})
    }

    function handleDescriptionChange (e: React.ChangeEvent<HTMLTextAreaElement>) {
        setFormValues({title: formValues.title, description: e.target.value})
    }

    function clickExitPortal() {
        /*
            Click to Exit Portal

            Added: William 18 Jul 2022.
         */
        //  Call to logout of Portal
        authenticationService.logout(currentUser);

        //  Navigate to Sign In page.
        navigate('/signin')
    }

    return (
        <>
            <div className="">
                <button
                    type='button'
                    onClick={openModal}
                    className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                    bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                    hover:shadow-xl transition duration-200
                    "
                >
                    <span className="text-sm font-semibold tracking-wide">New Project</span>

                </button>

                {/*
                    DropDown Menu to hold the Password Change and Exit

                    Added: William 18 Jul 2022.
                */}
                <Menu>
                    <Menu.Button
                        className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                    bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                    hover:shadow-xl transition duration-200
                    "
                    >
                        <span className="text-sm font-semibold tracking-wide">User</span>
                        <ArrowCircleDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={`${active && 'bg-blue-500'}`}
                                        href="/changepw"
                                    >
                                        Change Password
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        type='button'
                                        onClick={clickExitPortal}
                                        className={`${active && 'bg-blue-500'}`}
                                    >
                                        Log Out
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Menu>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as='div'
                        className='fixed inset-0 z-10 overflow-y-auto'
                        open={isOpen}
                        onClose={closeModal}>
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child as={Fragment}
                                          enter='ease-out duration-300'
                                          enterFrom="opacity-0"
                                          enterTo="opacity-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0">
                            <Dialog.Overlay className='fixed inset-0 bg-black opacity-30'/>
                        </Transition.Child>
                        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title as="h3"
                                              className="text-lg font-medium leading-6 text-gray-900">
                                    Create a New Project
                                </Dialog.Title>
                                <div className="mt-2">
                                    <div className="text-sm text-gray-500">
                                        <form>
                                            <div className='form-group mb-6'>
                                                <label htmlFor='project-title'>Project Title</label>
                                                <input
                                                    type='text'
                                                    id='project-title'
                                                    value={formValues.title}
                                                    onChange={handleTitleChange}
                                                    className="form-control block w-full px-3 py-1.5 text-base
                                                        font-normal text-gray-700 bg-white bg-clip-padding
                                                        border border-solid border-gray-300 rounded
                                                        transition ease-in-out m-0 focus:text-gray-700
                                                        focus:bg-white focus:border-blue-600 focus:outline-none" />
                                            </div>
                                            <div className='form-group mb-6'>
                                                <label htmlFor='project-description'>Project Description</label>
                                                <textarea
                                                    id='project-description'
                                                    rows={3}
                                                    value={formValues.description}
                                                    onChange={handleDescriptionChange}
                                                    className='form-control block w-full px-3 py-1.5
                                                        text-base font-normal text-gray-700
                                                        bg-white bg-clip-padding
                                                        border border-solid border-gray-300 rounded
                                                        transition ease-in-out m-0
                                                        focus:text-gray-700 focus:bg-white
                                                        focus:border-blue-600 focus:outline-none'
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={clickCreate}
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
