import {ConsoleHeader} from "../requester/console/ConsoleHeader";
import {Outlet} from "react-router-dom";
import React from "react";

/**
 * PCD (Point-Cloud) Task
 * Do the following View and Segment
 *
 * Notes
 * - This is a template: It does not have backend code: No download, No upload.
 * @author William Tan 2022-Nov-25.
 * @constructor
 */
export function PCDTask() {

    return (
        <>
            {/*<ConsoleHeader title='Point-Cloud Task' subtitle=''/>*/}
            <Outlet/>
        </>
    )
}