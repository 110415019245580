import {IBlueprintQuestion} from "./item/question/BlueprintQuestion";
import {BlueprintInstructionFactory, IBlueprintInstruction} from "./item/instruction/BlueprintInstruction";
import {getRandomInt} from "../util/util";
import {IUser, User} from "../user/User";
import {BlueprintPrototype, BlueprintPrototypeItem, IBlueprintPrototype} from "./BlueprintPrototype";
import {BinaryQuestionFactory} from "./item/question/BinaryQuestion";

export type BlueprintItem = IBlueprintQuestion | IBlueprintInstruction;

export enum BlueprintTypeId {
    SurveyLink,
    Questionnaire,
    ImageRating,
    ImageComparison,
    ImageSegmentation,
}

export interface IBlueprintType {
    typeId: BlueprintTypeId;
    name: string;
}

export const BlueprintTypes: IBlueprintType[] = [
    {name: 'Survey Link', typeId: BlueprintTypeId.SurveyLink},
    {name: 'Questionnaire', typeId: BlueprintTypeId.Questionnaire},
    {name: 'Image Rating', typeId: BlueprintTypeId.ImageRating},
    {name: 'Image Comparison', typeId: BlueprintTypeId.ImageComparison},
    {name: 'Image Segmentation', typeId: BlueprintTypeId.ImageSegmentation},
]

export interface IBlueprint {
    id: number;
    title: string;
    description: string;
    typeId: BlueprintTypeId;
    items: BlueprintItem[];
    createdBy: IUser;
    projectId: number;
}


export class Blueprint implements IBlueprint {
    id: number;
    title: string;
    description: string;
    typeId: BlueprintTypeId;
    items: BlueprintItem[];
    createdBy: User;
    projectId: number;

    constructor(id: number, title: string, description: string, typeId: BlueprintTypeId, items: BlueprintItem[],
                createdBy: User, projectId: number) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.typeId= typeId;
        this.items = items;
        this.createdBy = createdBy;
        this.projectId = projectId;
    }

    toJson(): object {
        return {
            owner: this.createdBy.name,
            project_id: this.projectId,
            title: this.title,
            description: this.description,
            type_id: this.typeId,
            items: {"items": this.items}
        }
    }
}

export interface IBlueprintFactory {
     create(title: string, description: string, typeId: BlueprintTypeId, items: BlueprintItem[],
            createdBy: IUser, projectId: number): IBlueprint;
     createFromPrototype(projectId: number, prototype: IBlueprintPrototype): IBlueprint;
}

export class BlueprintFactory implements IBlueprintFactory {
    create(title: string, description: string, typeId: BlueprintTypeId, items: BlueprintItem[],
           createdBy: User, projectId: number, blueprintId?: number): Blueprint {
        if (blueprintId) {
            return new Blueprint(blueprintId, title, description, typeId, items, createdBy, projectId);
        } else {
            const id = getRandomInt();
            return new Blueprint(id, title, description, typeId, items, createdBy, projectId);
        }
    }

    createFromPrototype(projectId: number, prototype: BlueprintPrototype): Blueprint {
        const binaryQuestionFactory = new BinaryQuestionFactory();
        const instructionFactory = new BlueprintInstructionFactory();

        const items = prototype.itemRepo.list.map((item: BlueprintPrototypeItem) => {
            if ('options' in item) {
                // `item` implements `IBlueprintQuestionPrototype`
                return binaryQuestionFactory.create(item.title!, item.options!, item.body)
            } else {
                // `item` implements `IBlueprintInstructionPrototype`
                return instructionFactory.create(item.title!, item.body!);
            }
        })
        return this.create(
            prototype.title,
            prototype.description,
            prototype.typeId!,
            items,
            prototype.createdBy,
            projectId
        )
    }
}