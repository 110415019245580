export interface IHIT {
    hit_id: string;
    amt_hit_id: string;
    hit_type_id: string;
    hit_group_id: string;
    creation_time: Date;
    title: string;
    description: string;
    question: string;
    keywords: string;
    hit_status: string;
    max_assignments: number;
    reward: number;
    auto_approval_delay_s: number;
    expiration: Date;
    assignment_duration_s: number;
    qualification_requirements: string[];
    production: boolean;
}

export class HIT implements IHIT {
    constructor(
        public hit_id: string,
        public amt_hit_id: string,
        public hit_type_id: string,
        public hit_group_id: string,
        public creation_time: Date,
        public title: string,
        public description: string,
        public question: string,
        public keywords: string,
        public hit_status: string,
        public max_assignments: number,
        public reward: number,
        public auto_approval_delay_s: number,
        public expiration: Date,
        public assignment_duration_s: number,
        public qualification_requirements: string[],
        public production: boolean
    ) {
    }
}

export function getHITURL(hit: HIT): string {
    if (hit.production) {
        return `https://www.mturk.com/mturk/preview?groupId=${hit.hit_type_id}`;
    } else {
        return `https://workersandbox.mturk.com/mturk/preview?groupId=${hit.hit_type_id}`;
    }
}

export class HITFactory {
    create (
        hit_id: string,
        amt_hit_id: string,
        hit_type_id: string,
        hit_group_id: string,
        creation_time: Date,
        title: string,
        description: string,
        question: string,
        keywords: string,
        hit_status: string,
        max_assignments: number,
        reward: number,
        auto_approval_delay_s: number,
        expiration: Date,
        assignment_duration_s: number,
        qualification_requirements: string[],
        production: boolean
    ) {
        return new HIT(
            hit_id,
            amt_hit_id,
            hit_type_id,
            hit_group_id,
            creation_time,
            title,
            description,
            question,
            keywords,
            hit_status,
            max_assignments,
            reward,
            auto_approval_delay_s,
            expiration,
            assignment_duration_s,
            qualification_requirements,
            production
        )
    }
}