import React from "react";
import {BlueprintTypeId, BlueprintTypes, IBlueprintType} from "../../../../../domain/blueprint/Blueprint";

export interface IBlueprintTableRowProp {
    key: string;
    id: number;
    title: string;
    description: string;
    typeId: BlueprintTypeId;
    createdBy: string;
}

export function BlueprintTableRow ({title, description, typeId, createdBy}: IBlueprintTableRowProp) {
    const blueprintType: IBlueprintType | undefined = BlueprintTypes.find((o) => o.typeId === typeId);

    return (
        <tr className='bg-gray-100 border-b'>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {title}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {description}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {blueprintType?.name}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {createdBy}
            </td>
            <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                Create a task batch
            </td>
        </tr>
    )
}