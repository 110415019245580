import React from "react";

interface ConsoleHeaderProps {
    title: string;
    subtitle: string;
}

export function ConsoleHeader ({title, subtitle}: ConsoleHeaderProps) {
    return (
        <>
            <header className="flex items-center justify-between py-2 px-6">
                <div>
                    <h1 className="text-left text-2xl font-semibold leading-relaxed text-gray-800">
                        {title}
                    </h1>
                    <p className="text-left text-sm font-medium text-gray-500">
                        {subtitle}
                    </p>
                </div>
            </header>
        </>
    )
}