export function getRandomInt (): number {
    /**
     * Returns a random integer number from 1 to 2147483647, which a range of values that is coherent with
     * the postgres serial type
     */
    const min = Math.ceil(1);
    const max = Math.floor(2147483647);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function getDate (delay_days: number): Date {
    const date = new Date();
    date.setDate(date.getDate() + delay_days);
    return date;
}