import {IUser, User} from "../../domain/user/User";
import {UserFactory} from "../../domain/user/User";  //  William Added. For Web Services call.

export interface ICurrentUser {
    setToken(token: string): void;
    currentUser: IUser | undefined;
    clear(): void;
    getCurrentUser(): IUser | undefined;
    setCurrentUser(user: IUser): void;
    setToUndefined(): void;
}

export class CurrentUser implements ICurrentUser {
    currentUser: User | undefined;
    private static instance: CurrentUser;

    constructor(user?: User) {
        this.currentUser = user;
    }

    public static getInstance(): CurrentUser {
        if (!CurrentUser.instance) {
            CurrentUser.instance = new CurrentUser()
        }
        return CurrentUser.instance;
    }

    setToken(token: string): void {
        if (this.currentUser) {
            this.currentUser.token = token;
        }
    }

    setCurrentUser(user: User): void {
        //localStorage.setItem("user", JSON.stringify(user.toJSON())); // TODO. Don't use localStorage.

        //  Set the user's data to Local Storage.
        //  Why? Because when navigating under ngix (i.e. pasting link into browser). Page is reloaded (cache cleared.)
        //  Localstorage provides means to retrieve existing logged-in user.
        localStorage.setItem('user_id', String(user.id));
        localStorage.setItem('user_token', user.token);
        localStorage.setItem('user_name', user.name);
        this.currentUser = user;
    }

    clear(): void {
        localStorage.setItem("user", ""); // TODO. Don't use localStorage.
        localStorage.setItem('user_id', "");
        localStorage.setItem('user_token', "");
        localStorage.setItem('user_name', "");
        this.currentUser = undefined;
    }

    getCurrentUser(): User | undefined {
        // if (this.currentUser) {
        //     return this.currentUser;
        // }
        // return this.currentUser;

        //  Get the user info from LocalStorage if exist.
        const user_name: string | null=localStorage.getItem("user_name")
        const user_id: string | null =localStorage.getItem("user_id")
        const user_token: string | null=localStorage.getItem("user_token")

        //  Check if exist.
        if(user_name && user_id && user_token) {

            //  Create user if exist
            const userFactory = new UserFactory();
            const user = userFactory.create(user_name, parseInt(user_id), user_token)
            if(user) {
                this.currentUser = user;
                return this.currentUser;
            }
        }
        else {
            if (this.currentUser) {
                return this.currentUser;
            }
        }

        return this.currentUser;
    }

    setToUndefined(): void {
        this.currentUser = undefined;
    }

}