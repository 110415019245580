import React from "react";

export interface IBlueprintStepperProps {
    step: number;
}

export function BlueprintStepper ({step}: IBlueprintStepperProps) {
    const borderedStepperCircleClass = "rounded-full h-10 w-10 py-2 border-2 border-blue-600";
    const filledStepperCircleClass = "rounded-full h-10 w-10 py-2 border-2 bg-blue-600 border-blue-600 text-white";
    const grayStepperCircleClass = "rounded-full h-10 w-10 py-2 border-2 border-gray-300";
    const blueStepperSegmentClass = "flex-auto border-t-2 border-blue-600";
    const grayStepperSegmentClass = "flex-auto border-t-2 border-gray-300";
    return (
        <>
            <div className="px-20 text-gray-500 pt-5 pb-20">
                <div className="mx-4 p-4">
                    <div className="flex items-center">
                        <div className="flex items-center relative">
                            <div className={step === 1 ? filledStepperCircleClass : borderedStepperCircleClass}>
                                <p className={`text-sm font-medium  text-center ${step < 2 ? "text-white" : "text-blue-600"}`}>
                                    1
                                </p>
                            </div>
                            <div
                                className="absolute top-0 -ml-20 text-center mt-16 w-48 text-xs font-medium uppercase">
                                Select a blueprint type and edit details
                            </div>
                        </div>

                        <div className={step < 2 ? grayStepperSegmentClass : blueStepperSegmentClass}></div>

                        <div className="flex items-center relative">
                            <div className={step === 2 ? filledStepperCircleClass : (step < 2 ? grayStepperCircleClass : borderedStepperCircleClass )}>
                                <p className="text-sm font-medium  text-center">2</p>
                            </div>
                            <div
                                className="absolute top-0 -ml-20 text-center mt-16 w-48 text-xs font-medium uppercase">
                                Design questions and instructions
                            </div>
                        </div>

                        <div className={step < 3 ? grayStepperSegmentClass : blueStepperSegmentClass}></div>

                        <div className="flex items-center text-gray-500 relative">
                            <div
                                className={step === 3 ? filledStepperCircleClass : grayStepperCircleClass}>
                                <p className="text-sm font-medium  text-center">3</p>
                            </div>
                            <div
                                className="absolute top-0 -ml-20 text-center mt-16 w-48 text-xs font-medium uppercase">
                                Preview and create a blueprint
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}