import React from "react";

export interface IReportProgressTableRow {
    key: string;
    batchId: number;
    status: string;
    participant: string;
    result: string;
}

export function ReportProgressTableRow ({batchId, status, participant, result}: IReportProgressTableRow) {
    return (
        <>
            <tr className='bg-gray-100 border-b'>
                <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    {batchId}
                </td>
                <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    {status}
                </td>
                <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    {participant}
                </td>
                <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    {result}
                </td>
                <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <span>Accept | Reject</span>
                </td>
            </tr>
        </>
    )
}