import {getRandomInt} from "../util/util";

export interface IUser {
    name: string;
    id: number;
    token: string;

    toJSON: () => string;
}

export class User implements IUser {
    name: string
    id: number
    token: string;

    constructor(name: string, userId: number, token?: string) {
        this.id = userId;
        this.name = name;
        this.token = token ? token : "";

        if (token) {
            this.token = token;
        } else {
            this.token = "";
        }
    }

    toJSON (): string {
        return JSON.stringify({name: this.name, id: this.id, token: this.token});
    }
}

export interface IUserFactory {
    create(name: string, userId?: number, token?: string): IUser
}

export class UserFactory implements IUserFactory {
    create(name: string, userId?: number, token?: string): User {
        const _id: number = (typeof userId === 'undefined') ? getRandomInt() : userId;
        const _token: string = (typeof token === "undefined") ? "" : token;

        return new User(name, _id, _token);
    }

    createFromJSON(json: string): User {
        const parsed: IUser = JSON.parse(json);
        return new User(parsed.name, parsed.id, parsed.token);
    }
}