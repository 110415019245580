import {IHITBatchReportRepository} from "../../domain/mturk/HITBatchReportRepository";
import {HITBatchReport} from "../../domain/mturk/HITBatchReport";

export class MockHITBatchReportRepository implements IHITBatchReportRepository {
    private static instance: MockHITBatchReportRepository;

    constructor(
        public reports: HITBatchReport[]
    ) {
    }

    public static getInstance(): MockHITBatchReportRepository {
        if (!MockHITBatchReportRepository.instance) {
            MockHITBatchReportRepository.instance = new MockHITBatchReportRepository([]);
        }
        return MockHITBatchReportRepository.instance;
    }

    add(report: HITBatchReport): Promise<number> {
        return new Promise((resolve) => {
            this.reports.push(report);
            resolve(0);
        })
    }

    listByProjectId(projectId: number): Promise<HITBatchReport[]> {
        return new Promise((resolve) => {
            const list = this.reports.filter((report) => report.projectId === projectId);
            resolve(list);
        })
    }
}