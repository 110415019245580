import {User} from "../../domain/user/User";
import {ProjectFactory} from "../../domain/project/Project";
import {IProjectRepository} from "../../domain/project/ProjectRepository";


export class ProjectService {
    createProject(title: string,
                  description: string,
                  user: User,
                  projectFactory: ProjectFactory,
                  projectRepository: IProjectRepository): Promise<string> {
        const project = projectFactory.create(title, user, description)
        return projectRepository.add(project);
    }
}