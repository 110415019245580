import {BlueprintPrototypeCards} from "./BlueprintPrototypeCards";
import React from "react";
import {BlueprintPrototypeContext, IBlueprintPrototypeContext} from "../Blueprints";
import {
    BinaryQuestionPrototypeFactory
} from "../../../../../domain/blueprint/prototype/question/BinaryQuestionPrototype";

export function BlueprintDesignQuestionnaire () {
    const {blueprintPrototype, setBlueprintPrototype} = React.useContext(BlueprintPrototypeContext) as IBlueprintPrototypeContext

    const questionFactory = new BinaryQuestionPrototypeFactory()
    function handleNewQuestionClick () {
        const question = questionFactory.create("Answer 'Yes' or 'no'.", ["Yes", "No"])
        blueprintPrototype.itemRepo.add(question);
        setBlueprintPrototype({...blueprintPrototype, "itemRepo": blueprintPrototype.itemRepo})
    }

    return (
        <>
            <BlueprintPrototypeCards />

            <div className='text-lg text-center'>
                <button
                    type='button'
                    onClick={handleNewQuestionClick}
                    className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                                bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                                hover:shadow-xl transition duration-200"
                >
                        <span
                            // onClick={handleNewQuestionClick}
                            className="text-sm font-semibold tracking-wide">Add a New Question</span>
                </button>
            </div>
        </>
    );
}