import {IAuthenticationService} from "../../usecase/auth/AuthenticationService";
import {CurrentUser} from "../../usecase/auth/CurrentUser";
import {UserFactory} from "../../domain/user/User";  //  William Added. For Web Services call.

const userFactory = new UserFactory();

export class AuthenticationService implements IAuthenticationService {
    login(username: string, useremail: string, password: string, currentUser: CurrentUser): Promise<any> {
        console.log("Environment:", process.env.NODE_ENV);
        if (process.env.NODE_ENV === "development") {
            const username = "test-user";
            const token = "12345678";

            const user = userFactory.create(username, undefined, token);
            currentUser.setCurrentUser(user);

            return Promise.resolve({"Status": "Success"});
        } else {
            //  Construct the params
            const data = JSON.stringify({
                signin_useremail: useremail,
                signin_username: username,
                signin_password: password
            })

            const promise = fetch('/api/auth/signin', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: data
            });
            return promise.then(response => response.json()).then(json => {
                let str_data = JSON.stringify(json)
                if (str_data.includes("Status") && str_data.includes("Fail")) {
                    //  Fail: Revert to login page.
                    // navigate("/", { replace: true });
                    alert("Please Enter Correct User and Password!")
                    return {"Status": "Fail"};
                } else {
                    //  Success: Store user data to localStorage
                    //  Notes: The access token is store in this.
                    const user = userFactory.create(json['name'], json["userid"], json["token"])
                    currentUser.setCurrentUser(user);

                    return {"Status": "Success"}
                }
            });
        }
    }

    logout(currentUser: CurrentUser):  Promise<string> {
        console.log("logout");
        currentUser.clear()
        const anonymousUser = userFactory.create('anonymous-user', 0);
        currentUser.setCurrentUser(anonymousUser);

        return Promise.resolve("");
    }




    signup(username: string, useremail: string, password: string, currentUser: CurrentUser): Promise<any> {

        //  William Notes: currentUser is not used in this context, not sure if needed since it was defined in the interface.
        //  But I still set the user to anonymous
        const anonymousUser = userFactory.create('anonymous-user', 0);
        currentUser.setCurrentUser(anonymousUser);

        const data = JSON.stringify({
            signup_username: username,
            signup_email: useremail,
            signup_password: password
        })

        const promise = fetch('/api/auth/signup', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        });

        return promise.then(response => response.json()).then(json => {
            let str_data = JSON.stringify(json);

            if (str_data.includes("Status") && str_data.includes("Fail")) {

                //  Fail: Show the failure message
                alert(json.Message)
                return {"Status": "Fail"};
            } else {
                //  Success
                return {"Status": "Success"}
            }
        });
    }

    change_pw(current_password: string, new_password: string, currentUser: CurrentUser): Promise<any> {
        /*
            Change Password: Allow the user to change the password
            Added: William 15 Jul 2022.
         */

        const user = currentUser.getCurrentUser();
        if (user) {

            const data = JSON.stringify({
                user_id: user.id,
                user_old_password: current_password,
                user_new_password: new_password
            })

            const promise = fetch('/api/auth/changepw', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            });

            return promise.then(response => response.json()).then(json => {
                let str_data = JSON.stringify(json);

                if (str_data.includes("Status") && str_data.includes("Fail")) {

                    //  Fail: Show the failure message
                    alert(json.Message)
                    return {"Status": "Fail"};
                } else {
                    //  Success: Password changed!
                    return {"Status": "Success"}
                }
            });
        }

        return Promise.resolve({"Status": "Fail"});
    }
}