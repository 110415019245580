import React from "react";
import {BinaryQuestion} from "../../domain/blueprint/item/question/BinaryQuestion";
import {BinaryQuestionCard} from "./questionnaire/BinaryQuestionCard";
import {BlueprintItem} from "../../domain/blueprint/Blueprint";


export interface IBlueprintCardProps {
    item: BlueprintItem
}

export function BlueprintCard ({item}: IBlueprintCardProps) {
    if (item instanceof BinaryQuestion) {
        return (
            <>
                <BinaryQuestionCard title={item.title} options={item.options} body={item.body}/>
            </>
        )
    } else {
        return (
            <>
                <div>Unrecognized</div>
            </>
        )
    }
}