import {Blueprint, BlueprintFactory, BlueprintItem, BlueprintTypeId} from "../../domain/blueprint/Blueprint";
import {UserFactory} from "../../domain/user/User";
import {
    BlueprintInstructionFactory,
    IBlueprintInstruction
} from "../../domain/blueprint/item/instruction/BlueprintInstruction";
import {IBlueprintQuestion} from "../../domain/blueprint/item/question/BlueprintQuestion";
import {BinaryQuestionFactory} from "../../domain/blueprint/item/question/BinaryQuestion";

export function deserializeBlueprint(object: any): Blueprint {
    const blueprintFactory = new BlueprintFactory();
    const userFactory = new UserFactory();

    const blueprintId: number = object["blueprint_id"];
    const username: string = object["created_by"]["username"];
    const userId: number = object["created_by"]["user_id"];
    const title: string = object["title"];
    const description: string = object["description"];
    const blueprintTypeId: BlueprintTypeId = object["blueprint_type_id"];
    const projectId: number = object["project_id"];

    const createdBy = userFactory.create(username, userId);
    const items: any[] = object["items"]["items"];
    const blueprintItems: BlueprintItem[] = items.map(deserializeBlueprintItem);

    return blueprintFactory.create(
        title,
        description,
        blueprintTypeId,
        blueprintItems,
        createdBy,
        projectId,
        blueprintId
    );
}

export function deserializeBlueprintItem(object: any): IBlueprintInstruction | IBlueprintQuestion {
    const title: string = object["title"];
    const body: string | undefined = object["body"];

    if ("options" in object) {
        const options: string[] = object["options"];
        const factory = new BinaryQuestionFactory();
        return factory.create(title, options, body);
    } else {
        const factory = new BlueprintInstructionFactory();
        return factory.create(title, body ? body : "");
    }
}