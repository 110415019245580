import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {GlobalContext, IGlobalContext} from "../../../App";

export function SignIn() {
    const {authenticationService, currentUser} = React.useContext(GlobalContext) as IGlobalContext;

    let navigate = useNavigate();

    const [userEnteredIdentifier, setUserEnteredIdentifier] = useState("");
    const [userEnteredPassword, setUserEnteredPassword] = useState("");

    //  William changes:
    async function onSignInClick (event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        let username="";
        let useremail="";
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (userEnteredIdentifier.match(regexEmail)) {
            username="";
            useremail=userEnteredIdentifier;
        } else {
            username=userEnteredIdentifier;
            useremail="";
        }

        const response = await authenticationService.login(username, useremail, userEnteredPassword, currentUser);

        console.log("Sign in", response)
        if (response["Status"] === 'Success') {
            navigate('/projects')
            console.log("Success.")
        } else {
            console.log("Fail")
            navigate("/", { replace: true });
        }
        // promise.then(response => {
        //     console.log(response);
        //     if (response["Status"] === 'Success') {
        //         navigate('/projects')
        //         console.log("Success.")
        //     } else {
        //         console.log("Fail")
        //         navigate("/", { replace: true });
        //     }
        // })
    }

    return (
        <>
            <div className='bg-white max-w-lg mx-auto p-8 md:p-12 my-10 rounded-lg shadow-xl'>
                <section>
                    <h1 className='font-bold text-xl text-gray-600 pt-2'>Sign in to your account.</h1>
                </section>

                <section className='mt-10'>
                    <form className='flex flex-col text-left'>
                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="email">User Name or Email</label>
                            <input
                                type='text'
                                id='identifier'
                                value={userEnteredIdentifier}
                                onChange={(e) => setUserEnteredIdentifier(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>
                        <div className='mb-6 pt-3 rounded bg-gray-100'>
                            <label className='block text-gray-700 text-sm fonnt-bold mb-2 ml-3' htmlFor="password">Password</label>
                            <input
                                type='password'
                                id='password'
                                value={userEnteredPassword}
                                onChange={(e) => setUserEnteredPassword(e.target.value)}
                                className='bg-gray-100 rounded w-full text-gray-700 focus:outline-none border-b-24 border-gray-300 focus:border-gray-400 transition duration-500 px-3 pb-3' />
                        </div>
                        <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg hover:shadow-xl transition duration-200"
                            type="submit"
                            onClick={onSignInClick}>
                            Sign In
                        </button>
                    </form>
                </section>

                <section className='mt-10'>
                    <p className=''>
                        Don't have an account? <Link to="/signup" className='font-bold hover:underline'>Sign up</Link>.
                    </p>
                </section>
            </div>
        </>
    )
}