import {AnnotatedObject} from "./AnnotatedObject";

/**
 * Interface: IAnnotatedObjectList
 * Contain the list Annotated Object that is uploaded from the Portal to the Backend.
 * A list of AnnotatedObject is due to that given a room, there are multiple objects. E.g. Projector, table, chairs, whiteboard, etc
 * Hence, when the participant is annotating a room, multiple annotated objects are generated, hence a list is uploaded instead of a single annotated object.
 * (See AnnotatedObject for more details)
 *
 * @author William Tan 2023 Feb 22.
 */
export interface IAnnotatedObjectList {

    /**
     * List of AnnotatedObject
     */
    listOfObjects: AnnotatedObject[];
}

export class AnnotatedObjectList implements IAnnotatedObjectList {
    public listOfObjects: AnnotatedObject[]=[];

    constructor(listOfObjects: AnnotatedObject[]) {
        this.listOfObjects = listOfObjects;
    }


}
