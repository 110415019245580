import React, {useEffect, useState} from "react";
import {ProjectTableHeader} from "./ProjectTableHeader";
import {ProjectTableRow} from "./ProjectTableRow";
import {NewProjectModal} from "./NewProjectModal";
import {GlobalContext, IGlobalContext} from "../../../App";
import {Project} from "../../../domain/project/Project";



export function ProjectTable() {
    const {currentUser, projectFactory, projectRepository, projectService} = React.useContext(GlobalContext) as IGlobalContext;

    const [projects, setProjects] = useState<Project[]>([])

    useEffect(() => {
        const user = currentUser.getCurrentUser()
        console.log(user);
        if (user) {
            projectRepository.getByOwner(user).then(result => {
                if (result) {
                    setProjects(result);
                }
            })
        }
    }, [currentUser, projectRepository])

    async function createProject(title: string, description: string) {
        const user = currentUser.getCurrentUser();
        if (user) {
            await projectService.createProject(title, description, user, projectFactory, projectRepository);
            const projects: Project[] | undefined = await projectRepository.getByOwner(user);
            if (projects) {
                const newProjects: Project[] = projects.map(p => p);
                setProjects(newProjects);
            }
        }
    }

    return (
        <>
            <div>
                <div className="flex items-center justify-between py-2 px-6">
                    <div>
                        <h1 className="text-left text-2xl font-semibold leading-relaxed text-gray-800">Projects</h1>
                        <p className="text-left text-sm font-medium text-gray-500">
                            This is a project table.
                        </p>
                    </div>
                    <NewProjectModal createProject={createProject}/>
                </div>

                <div className='flex flex-col'>
                    <div className='overflow-x-auto'>
                        <div className='py-2 inline-block min-w-full sm:px-0 lg:px-0'>
                            <div className="overflow-hidden">
                                <table className='min-w-full'>
                                    <thead className='bg-white border-b'>
                                    <tr>
                                        <ProjectTableHeader label={'Title'}></ProjectTableHeader>
                                        <ProjectTableHeader label={'Description'}></ProjectTableHeader>
                                        <ProjectTableHeader label={'Created by'}></ProjectTableHeader>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {projects.map((project) =>
                                        <ProjectTableRow
                                            key={project.id}
                                            id={project.id}
                                            title={project.title}
                                            description={project.description ? project.description : ''}
                                            createdBy={project.owner.name}
                                        />
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
