import {BlueprintStepper} from "./BlueprintStepper";
import {Link} from "react-router-dom";
import React from "react";
import {BlueprintCards} from "../../../../blueprint/BlueprintCards";
import {Blueprint, BlueprintFactory, BlueprintTypeId} from "../../../../../domain/blueprint/Blueprint";
import {BlueprintPrototypeContext, IBlueprintPrototypeContext} from "../Blueprints";
import {BlueprintSurveyLink} from "../../../../blueprint/BlueprintSurveyLink";
import {GlobalContext, IGlobalContext} from "../../../../../App";


export function BlueprintPreview () {
    const {blueprintRepository, currentProject} = React.useContext(GlobalContext) as IGlobalContext;
    const {blueprintPrototype} = React.useContext(BlueprintPrototypeContext) as IBlueprintPrototypeContext
    const blueprintFactory = new BlueprintFactory()

    const projectId: number = currentProject? currentProject.id : 0;
    const blueprint: Blueprint = blueprintFactory.createFromPrototype(projectId, blueprintPrototype)

    function handleCreateClick () {
        blueprintRepository.add(blueprint);
    }

    let designField: JSX.Element;
    if (blueprintPrototype.typeId === BlueprintTypeId.Questionnaire) {
        designField = <BlueprintCards blueprint={blueprint} />
    } else {
        designField = <BlueprintSurveyLink blueprint={blueprint} />;
    }

    return (
        <>
            <BlueprintStepper step={3} />

            <div className="px-6 text-gray-500">
                <p className='text-sm'>
                    The following is what participants will see when they are administered with the task.
                </p>
            </div>

            <div className="mx-6 p-6 text-gray-600 border border-gray-300 rounded">
                {designField}
            </div>

            <div className="flex items-center py-10 px-4">
                <span className='px-2'>
                    <Link to='../design'>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                                focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-1
                                bg-gray-200 hover:bg-gray-300 text-white font-bold py-2 rounded shadow-sm
                                hover:shadow-lg transition duration-200"
                        >

                            <span className="text-sm font-semibold tracking-wide text-gray-500">Back</span>

                        </button>
                    </Link>
                </span>

                <span className='px-2'>
                    <Link to='..'>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                                bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                                hover:shadow-xl transition duration-200"
                            onClick={handleCreateClick}
                        >

                            <span className="text-sm font-semibold tracking-wide">Create</span>
                        </button>
                    </Link>
                </span>

                <Link to='..'>
                    <span className='px-2'>
                        Cancel
                    </span>
                </Link>
            </div>
        </>
    )
}