import React, {useEffect, useState} from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

/**
 * Image Compare (2 Image) Template
 * - Compares the 2 Images: Selects 1 image over the other
 * - No Backend API yet, all code here uses Sample JSON encoded locally
 * - How it should work: Image Set is downloaded via JSON, user selects one of the 2 image set, re-uploads back to backend.
 * - How image compare works: User is presents with 2 images, question is presented and user is asked to select either of the presented 2 images.
 * - What is Missing/TODO: Create the Backend API and integrate into it with Tests.
 *
 * Notes
 * - This version is originally from Rosie's code, adapted into Typescript.
 * - This version does not use the polygon overlay (Rosie), it only compares between 2 images
 * - This is a template: It does not have backend code: No download, No upload.
 * @author William Tan 2022-Oct-11.
 * @constructor
 */
export function ImageCompare() {

    //  Test Sample JSON (Image Set from Server)
    const testJson = "{\n" +
        "\n" +
        " \"blueprint\": {\n" +
        "        \"blueprint_id\": 1,\n" +
        "        \"blueprint_type_id\": 0,\n" +
        "        \"created_by\": {\n" +
        "          \"description\": \"Assistant Professor\",\n" +
        "          \"email\": \"kotarohara@smu.edu.sg\",\n" +
        "          \"user_id\": 1,\n" +
        "          \"user_type\": 0,\n" +
        "          \"username\": \"kotarohara\"\n" +
        "        },\n" +
        "        \"description\": \"survey-link-description\",\n" +
        "        \"items\": {\n" +
        "          \"items\": [\n" +
        "            {\n" +
        "              \"body\": \"This is an instruction\",\n" +
        "              \"title\": \"Survey Link Body\"\n" +
        "            },\n" +
        "            {\n" +
        "              \"body\": \"link\",\n" +
        "              \"title\": \"Survey Link Body\"\n" +
        "            }\n" +
        "          ]\n" +
        "        },\n" +
        "        \"project_id\": 1,\n" +
        "        \"title\": \"survey-link-title\"\n" +
        "      },\n" +
        " \"imageset\": [\n" +
        "{\n" +
        "\"compareset_id\":1,\n" +
        "\"compareset\":[\n" +
        "{\n" +
        "\"image_id\":1,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/test.png\"\n" +
        "},\n" +
        "{\n" +
        "\"image_id\":2,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/test2.png\"\n" +
        "}],\n" +
        "\"image_qn\":\"How well do the polygons overlap with sidewalks?\",\n" +
        "\"image_rate\":0\n" +
        "},\n" +
        "{\n" +
        "\"compareset_id\":2,\n" +
        "\"compareset\":[\n" +
        "{\n" +
        "\"image_id\":3,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/test2.png\"\n" +
        "},\n" +
        "{\n" +
        "\"image_id\":4,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/test.png\"\n" +
        "}],\n" +
        "\"image_qn\":\"How well does the chicken cross the sidewalks?\",\n" +
        "\"image_rate\":0\n" +
        "}],\n" +
        "\"status\": \"Success\"\n" +
        "}";

    //  Holds the Progress Bar status
    const [progressState, setProgressState] = useState<number>();

    //  Holds the firstload flag. Sets to False after first load.
    const [firstload, setFirstLoad] = useState<boolean>(true);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        if (firstload) {
            processJSON();
            setFirstLoad(false);
        }
    })

    //  Interface for Compare Set
    interface MyEachCompareSet {
        image_id: number,
        image_url: string,
    }

    //  Interface for Array of Compare Set
    interface MyCompareImageSet extends Array<MyEachCompareSet> {
    }

    //  Interface for Image Set
    interface MyEachImageSet {
        compareset_id: number,
        compareset: MyCompareImageSet,
        image_qn: string,
        image_rate: number
    }

    //  Interface for Array of Image Set
    interface MySetImageSet extends Array<MyEachImageSet> {
    }

    //  Holds the active Image Set
    const [setOfImage, setImageSet] = useState<MySetImageSet>();

    //  The current index pointing to the image.
    const [currentQnIndex, setCurrentQnIndex] = useState<number>(0);

    //  The Image Path
    const [image1path, setImage1Path] = useState<string>();
    const [image2path, setImage2Path] = useState<string>();

    //  The Question per Image
    const [imageQn, setImageQn] = useState<string>();

    //  Loads the JSON file: This is for this template, on production, JSON needs to be downloaded as per Blueprint/Batch selection of the user define.
    //  This is on first load.
    function processJSON() {
        //  Parse the load the image set
        const data = JSON.parse(testJson);
        let setdata: MySetImageSet = data["imageset"];
        setImageSet(setdata);

        //  Reset the index to 0, reset the progress, loads the first image
        setCurrentQnIndex(0);
        setImage1Path(setdata[0].compareset[0].image_url);
        setImage2Path(setdata[0].compareset[1].image_url);
        setImageQn(setdata[0].image_qn);
        setProgressState(0)
        //console.log(setdata[0].image_url)
    }


    //  Next: Finish 1 Image and go to next, all finish upload
    async function onClickNextItem(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        //console.log("Inside onClickNextItem")
        if (setOfImage) {
            //console.log("Inside 2nd")
            const currentIndex = currentQnIndex + 1;
            if (currentIndex < setOfImage.length) {
                //  Move to next image.
                setCurrentQnIndex(currentIndex);
                //console.log(currentIndex)
                setImage1Path(setOfImage[currentIndex].compareset[0].image_url);
                setImage2Path(setOfImage[currentIndex].compareset[1].image_url);
                //console.log(setOfImage[currentIndex].image_url)
                setImageQn(setOfImage[currentIndex].image_qn);
            } else {
                //  Finish all images.
                //  TODO: Upload data (Template does not have this code)
                setCurrentQnIndex(0);
                setImage1Path(setOfImage[0].compareset[0].image_url);
                setImage2Path(setOfImage[0].compareset[1].image_url);
                setImageQn(setOfImage[0].image_qn);

            }
            //console.log(currentIndex + ", " + setOfImage.length)
            setProgressState((currentIndex / setOfImage.length) * 100)
        }

    }

    const [rate1ButtonisActive, setRate1ButtonisActive] = useState<boolean>();
    const [rate2ButtonisActive, setRate2ButtonisActive] = useState<boolean>();

    //  Set Rate 1
    async function onClickRate1(event: React.MouseEvent<HTMLElement>) {

        setRate1ButtonisActive(true);
        setRate2ButtonisActive(false);

        if (setOfImage) {
            //console.log("Rate 1");
            setOfImage[currentQnIndex].image_rate = setOfImage[currentQnIndex].compareset[0].image_id;
        }
    }

    //  Set Rate 2
    async function onClickRate2(event: React.MouseEvent<HTMLElement>) {

        setRate1ButtonisActive(false);
        setRate2ButtonisActive(true);

        if (setOfImage) {
            //console.log("Rate 1");
            setOfImage[currentQnIndex].image_rate = setOfImage[currentQnIndex].compareset[1].image_id;
        }
    }

    return (
        <>
            <div className="main flex items-center flex-col">
                <div className="top flex items-center">
                    <div className="progress w-100">
                        <ProgressBar now={progressState} label={`${progressState}% Completed.`}/>
                    </div>
                </div>

                <div className="middle flex flex-row">

                    <div className="satellite1" id="satellite1" style={{backgroundColor: rate1ButtonisActive ? 'salmon' : ''}}>
                        <input type="image" alt="no icon" className="bad flex border py-3 px-3" id="bad" src={image1path} value="1"
                               onClick={onClickRate1}
                        ></input>
                    </div>
                    <div className="satellite2 " id="satellite2" style={{backgroundColor: rate2ButtonisActive ? 'salmon' : ''}}>
                        <input type="image" alt="no icon" className="bad flex border py-3 px-3" id="bad" src={image2path} value="1"
                               onClick={onClickRate2}
                        ></input>
                    </div>

                </div>


                <div className="bottom flex items-center flex-col">
                    <div className="question">
                        <p className="questionText">{imageQn}</p>
                    </div>

                    <div className="submit">
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                            bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                            onClick={onClickNextItem}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}