import React, {useEffect, useState} from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

/**
 * Image Rate Template
 * - Rates the image with Likert Scale 1 to 5
 * - No Backend API yet, all code here uses Sample JSON encoded locally
 * - How it should work: Image Set is downloaded via JSON, user rates the image set, re-uploads back to backend.
 * - How image rate works: User is presents with image, question is presented and user is asked to rate the image based on what is asked.
 * - What is Missing/TODO: Create the Backend API and integrate into it with Tests.
 *
 * Notes
 * - This version is originally from Rosie's code, adapted into Typescript.
 * - This version does not use the polygon overlay (Rosie), it only rates what is presented in the image.
 * - This is a template: It does not have backend code: No download, No upload.
 * @author William Tan 2022-Oct-06.
 * @constructor
 */
export function ImageRate() {

    //  Test Sample JSON (Image Set from Server)
    const testJson = "{\n" +
        "\n" +
        " \"blueprint\": {\n" +
        "        \"blueprint_id\": 1,\n" +
        "        \"blueprint_type_id\": 0,\n" +
        "        \"created_by\": {\n" +
        "          \"description\": \"Assistant Professor\",\n" +
        "          \"email\": \"kotarohara@smu.edu.sg\",\n" +
        "          \"user_id\": 1,\n" +
        "          \"user_type\": 0,\n" +
        "          \"username\": \"kotarohara\"\n" +
        "        },\n" +
        "        \"description\": \"survey-link-description\",\n" +
        "        \"items\": {\n" +
        "          \"items\": [\n" +
        "            {\n" +
        "              \"body\": \"This is an instruction\",\n" +
        "              \"title\": \"Survey Link Body\"\n" +
        "            },\n" +
        "            {\n" +
        "              \"body\": \"link\",\n" +
        "              \"title\": \"Survey Link Body\"\n" +
        "            }\n" +
        "          ]\n" +
        "        },\n" +
        "        \"project_id\": 1,\n" +
        "        \"title\": \"survey-link-title\"\n" +
        "      },\n" +
        " \"imageset\": [\n" +
        "{\n" +
        "\"image_id\":1,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/test.png\",\n" +
        "\"image_qn\":\"How well do the polygons overlap with sidewalks?\",\n" +
        "\"image_rate\":0\n" +
        "},\n" +
        "{\n" +
        "\"image_id\":2,\n" +
        "\"image_url\":\"http://192.168.7.1/icon/eye-slash.png\",\n" +
        "\"image_qn\":\"Does this eye has lashes?\",\n" +
        "\"image_rate\":0\n" +
        "}],\n" +
        "\"status\": \"Success\"\n" +
        "}";

    //  Holds the Progress Bar status
    const [progressState, setProgressState] = useState<number>();

    //  Holds the firstload flag. Sets to False after first load.
    const [firstload, setFirstLoad] = useState<boolean>(true);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        if (firstload) {
            processJSON();
            setFirstLoad(false);
        }
    })

    //  Interface for Image Set
    interface MyEachImageSet {
        image_id: number,
        image_url: string,
        image_qn: string,
        image_rate: number
    }

    //  Interface for Array of Image Set
    interface MySetImageSet extends Array<MyEachImageSet> {
    }

    //  Holds the active Image Set
    const [setOfImage, setImageSet] = useState<MySetImageSet>();

    //  The current index pointing to the image.
    const [currentQnIndex, setCurrentQnIndex] = useState<number>(0);

    //  The Image Path
    const [imagepath, setImagePath] = useState<string>();

    //  The Question per Image
    const [imageQn, setImageQn] = useState<string>();

    //  Loads the JSON file: This is for this template, on production, JSON needs to be downloaded as per Blueprint/Batch selection of the user define.
    //  This is on first load.
    function processJSON() {
        //  Parse the load the image set
        const data = JSON.parse(testJson);
        let setdata: MySetImageSet = data["imageset"];
        setImageSet(setdata);

        //  Reset the index to 0, reset the progress, loads the first image
        setCurrentQnIndex(0);
        setImagePath(setdata[0].image_url);
        setImageQn(setdata[0].image_qn);
        setProgressState(0)
        //console.log(setdata[0].image_url)
    }

    //  Rating for the Likert Scale 1-bad to 5-good
    //  This is used to help flip the color change when selected.
    const [rate1badButtonisActive, setRate1badButtonisActive] = useState<boolean>();
    const [rate2FairlybadButtonisActive, setRate2FairlybadButtonisActive] = useState<boolean>();
    const [rate3NormalButtonisActive, setRate3NormalButtonisActive] = useState<boolean>();
    const [rate4FairlyGoodButtonisActive, setRate4FairlyGoodButtonisActive] = useState<boolean>();
    const [rate5GoodButtonisActive, setRate5GoodButtonisActive] = useState<boolean>();

    //  Set Rate 1 - Bad
    async function onClickRate1Bad(event: React.MouseEvent<HTMLElement>) {

        setRate1badButtonisActive(true);
        setRate2FairlybadButtonisActive(false);
        setRate3NormalButtonisActive(false);
        setRate4FairlyGoodButtonisActive(false);
        setRate5GoodButtonisActive(false);

        if (setOfImage) {
            console.log("Rate 1");
            setOfImage[currentQnIndex].image_rate = 1;
        }
    }

    //  Set Rate 2 - Fairly Bad
    async function onClickRate2fairlyBad(event: React.MouseEvent<HTMLElement>) {

        setRate1badButtonisActive(false);
        setRate2FairlybadButtonisActive(true);
        setRate3NormalButtonisActive(false);
        setRate4FairlyGoodButtonisActive(false);
        setRate5GoodButtonisActive(false);

        if (setOfImage) {
            console.log("Rate 2");
            setOfImage[currentQnIndex].image_rate = 2;
        }
    }

    //  Set Rate 3 - Normal
    async function onClickRate3Normal(event: React.MouseEvent<HTMLElement>) {

        setRate1badButtonisActive(false);
        setRate2FairlybadButtonisActive(false);
        setRate3NormalButtonisActive(true);
        setRate4FairlyGoodButtonisActive(false);
        setRate5GoodButtonisActive(false);

        if (setOfImage) {
            console.log("Rate 3");
            setOfImage[currentQnIndex].image_rate = 3;
        }

    }

    //  Set Rate 4 - Fairly Good
    async function onClickRate4fairlyGood(event: React.MouseEvent<HTMLElement>) {

        setRate1badButtonisActive(false);
        setRate2FairlybadButtonisActive(false);
        setRate3NormalButtonisActive(false);
        setRate4FairlyGoodButtonisActive(true);
        setRate5GoodButtonisActive(false);

        if (setOfImage) {
            console.log("Rate 4");
            setOfImage[currentQnIndex].image_rate = 4;
        }

    }

    //  Set Rate 5 - Good
    async function onClickRate5Good(event: React.MouseEvent<HTMLElement>) {

        setRate1badButtonisActive(false);
        setRate2FairlybadButtonisActive(false);
        setRate3NormalButtonisActive(false);
        setRate4FairlyGoodButtonisActive(false);
        setRate5GoodButtonisActive(true);

        if (setOfImage) {
            console.log("Rate 5");
            setOfImage[currentQnIndex].image_rate = 5;
        }

    }

    //  Next: Finish 1 Image and go to next, all finish upload
    async function onClickNextItem(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();

        //console.log("Inside onClickNextItem")
        if (setOfImage) {
            //console.log("Inside 2nd")
            const currentIndex = currentQnIndex + 1;
            if (currentIndex < setOfImage.length) {
                //  Move to next image.
                setCurrentQnIndex(currentIndex);
                //console.log(currentIndex)
                setImagePath(setOfImage[currentIndex].image_url);
                //console.log(setOfImage[currentIndex].image_url)
                setImageQn(setOfImage[currentIndex].image_qn);
            } else {
                //  Finish all images.
                //  TODO: Upload data (Template does not have this code)
                setCurrentQnIndex(0);
                setImagePath(setOfImage[0].image_url);
                setImageQn(setOfImage[0].image_qn);

            }
            //console.log(currentIndex + ", " + setOfImage.length)
            setProgressState((currentIndex / setOfImage.length) * 100)

            //  Reset
            setRate1badButtonisActive(false);
            setRate2FairlybadButtonisActive(false);
            setRate3NormalButtonisActive(false);
            setRate4FairlyGoodButtonisActive(false);
            setRate5GoodButtonisActive(false);
        }

    }


    return (
        <>
            <div className="main flex items-center flex-col">
                <div className="top flex items-center">
                    <div className="progress w-100">
                        <ProgressBar now={progressState} label={`${progressState}% Completed.`}/>
                    </div>
                </div>

                <div className="middle flex items-center flex-row">
                    {/*<button className="hideButtonMobile">*/}
                    {/*    <div className="hideButtonMobileLeft">*/}
                    {/*        <img src="/icon/eye-slash.png" id="hideImageMobile" alt="No Icon" width="25"></img>*/}
                    {/*        <br/>*/}
                    {/*    </div>*/}
                    {/*    <div className="hideButtonMobileRight">*/}
                    {/*        <p id="hideLabelMobile"></p>*/}
                    {/*    </div>*/}
                    {/*</button>*/}

                    <div className="center">
                        <div className="satellite" id="satellite">
                            <img src={imagepath} id="testimg" alt="No Icon"></img>
                        </div>
                    </div>
                </div>


                <div className="bottom flex items-center flex-col">
                    <div className="question">
                        <p className="questionText">{imageQn}</p>
                    </div>

                    <div className="flex flex-row">
                        <div className="poorLabel">
                            <p id="poor">poor</p>
                        </div>

                        <div className="badButton">
                            <input type="image" alt="no icon" className="bad" id="bad" src="/icon/bad.png" value="1" width="50"
                                   style={{backgroundColor: rate1badButtonisActive ? 'salmon' : ''}}
                                   onClick={onClickRate1Bad}
                            ></input>
                            <label htmlFor="bad" id="badLabel">1</label>
                        </div>
                        <div className="fairlyBadButton">
                            <input type="image" alt="no icon" className="fairlyBad" id="fairlyBad" src="/icon/fairlyBad.png" value="2" width="50"
                                   style={{backgroundColor: rate2FairlybadButtonisActive ? 'salmon' : ''}}
                                   onClick={onClickRate2fairlyBad}></input>
                            <label htmlFor="fairlyBad" id="fairlyBadLabel">2</label>
                        </div>
                        <div className="normalButton">
                            <input type="image" alt="no icon" className="normal" id="normal" src="/icon/normal.png" value="3" width="50"
                                   style={{backgroundColor: rate3NormalButtonisActive ? 'salmon' : ''}}
                                   onClick={onClickRate3Normal}></input>
                            <label htmlFor="normal" id="normalLabel">3</label>
                        </div>
                        <div className="fairlyGoodButton">
                            <input type="image" alt="no icon" className="fairlyGood" id="fairlyGood" src="/icon/fairlyGood.png" value="4" width="50"
                                   style={{backgroundColor: rate4FairlyGoodButtonisActive ? 'salmon' : ''}}
                                   onClick={onClickRate4fairlyGood}></input>
                            <label htmlFor="fairlyGood" id="fairlyGoodLabel">4</label>
                        </div>
                        <div className="goodButton">
                            <input type="image" alt="no icon" className="good" id="good" src="/icon/good.png" value="5" width="50"
                                   style={{backgroundColor: rate5GoodButtonisActive ? 'salmon' : ''}}
                                   onClick={onClickRate5Good}></input>
                            <label htmlFor="good" id="goodLabel">5</label>
                        </div>

                        <div className="excellentLabel">
                            <p id="excellent">good</p>
                        </div>
                    </div>

                    <div className="submit">
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                            bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                            onClick={onClickNextItem}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}