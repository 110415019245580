import {SideMenu} from "./SideMenu";
import {ConsoleMain} from "./ConsoleMain";
import React from "react";

export function Console () {
    return (
        <>
            <div className='flex w-full min-h-screen text-gray-700 bg-gray-50'>
                    <SideMenu/>
                    <ConsoleMain/>
            </div>
        </>
    )
}