import React from "react";

export interface IBlueprintTableHeaderProps {
    label: string
}

export function BlueprintTableHeader ({label}: IBlueprintTableHeaderProps) {
    return (
        <th className='text-left text-sm text-gray-900 px-6 py-4 whitespace-nowrap'>{label}</th>
    )
}