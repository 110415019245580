import {HIT, HITFactory} from "../../domain/mturk/HIT";

export function deserializeHIT(hitObject: any): HIT {
    const factory = new HITFactory();
    const creationTime: Date = new Date(hitObject.creation_time);
    const expiration: Date = new Date(hitObject.expiration);

    return factory.create(
        hitObject.hit_id,
        hitObject.amt_hit_id,
        hitObject.hit_type_id,
        hitObject.hit_group_id,
        creationTime,
        hitObject.title,
        hitObject.description,
        hitObject.question,
        hitObject.keywords,
        hitObject.hit_status,
        hitObject.max_assignments,
        hitObject.reward,
        hitObject.auto_approval_delay_s,
        expiration,
        hitObject.assignment_duration_s,
        hitObject.qualification_requirements,
        hitObject.production);
}