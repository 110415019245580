import {ISurveyLinkProtocol} from "../../domain/mturk/SurveyLinkTask";
import {ICurrentUser} from "../../usecase/auth/CurrentUser";
import {IMTurkService} from "../../domain/mturk/MTurkService";


export class MTurkService implements IMTurkService {
    currentUser: ICurrentUser;

    constructor(currentUser: ICurrentUser) {
        this.currentUser = currentUser;
    }

    async createSurveyLinkHIT(
        accessKey: string,
        secretKey: string,
        protocol: ISurveyLinkProtocol
    ): Promise<any> {
        const user = this.currentUser.getCurrentUser();

        if (user) {
            const qualifications = [
                {
                    "qualification_type": "locales",
                    "locale_values": ["US"]
                },
                {
                    "qualification_type": "approval_count",
                    "integer_value": 250
                },
                {
                    "qualification_type": "approval_rate",
                    "integer_value": 99
                }
            ];

            const data = JSON.stringify({
                "username": user.name,
                "blueprint_id": protocol.survey_link_attributes.blueprint_id,
                "aws_access_key": accessKey,
                "aws_secret_key": secretKey,
                "is_test": false,
                "max_assignments": protocol.task_attributes.max_assignments,
                'lifetime_s': protocol.task_attributes.time_to_expire_s,
                'assignment_duration_s': protocol.task_attributes.assignment_duration_s,
                'reward': protocol.task_attributes.reward,
                'title': protocol.task_attributes.title,
                'keywords': protocol.task_attributes.keywords,
                'description': protocol.task_attributes.description,
                "production": protocol.task_attributes.production,
                'instruction': protocol.survey_link_attributes.instruction,
                'link': protocol.survey_link_attributes.survey_url,
                "qualifications": qualifications
            });
            const url = "/api/mturk/create_survey_link_task";

            return fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => {
                return response.json();
            }).then(json => {
                return JSON.stringify(json);
            });
        }

        return Promise.resolve("error. username or blueprint does not exist.");
    }
}

