import React, {Fragment, useEffect, useState} from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import {BatchPrototypeContext, IBatchPrototypeContext} from "../Batches";
import {GlobalContext, IGlobalContext} from "../../../../../App";
import {Blueprint} from "../../../../../domain/blueprint/Blueprint";
import {useNavigate} from "react-router-dom";

export function BatchCreateBlueprintSelection() {
    let navigate = useNavigate();

    const {blueprintRepository, currentProject, currentUser} = React.useContext(GlobalContext) as IGlobalContext;
    const {batchPrototype, setBatchPrototype} = React.useContext(BatchPrototypeContext) as IBatchPrototypeContext

    const [selected, setSelected] = useState<Blueprint | undefined>(undefined);
    const [blueprints, setBlueprints] = useState<Blueprint[]>([])

    useEffect( function () {
        if (currentProject) {
            const fetchBlueprints = async () => {
                const _blueprints = await blueprintRepository.getByProject(currentProject.id);
                setBlueprints(_blueprints);

                //  If return empty list and user ID is reset=0, this meant Token has expired
                if(_blueprints.length==0 && currentUser.currentUser?.id==0)
                    navigate('/signin');
            }
            fetchBlueprints().catch(console.error);
        }
    }, [blueprintRepository, currentProject])

    function handleChange (userSelection: Blueprint) {
        setBatchPrototype({...batchPrototype, blueprint: userSelection})
        setSelected(userSelection)
    }

    return (
        <>
            <Listbox value={selected} onChange={handleChange}>
                <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10
                                               text-left shadow-md focus:outline-none focus-visible:border-blue-500
                                               focus-visible:ring-2 focus-visible:ring-white
                                               focus-visible:ring-opacity-75 focus-visible:ring-offset-2
                                               focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span className="block truncate">{selected ? selected.title : "Choose a blueprint"}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
              />
            </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1
                        text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                            {blueprints.map((blueprint, blueprintIdx) => (
                                <Listbox.Option
                                    key={blueprintIdx}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                                        }`
                                    }
                                    value={blueprint}
                                >
                                    {({ selected }) => (
                                        <>
                      <span
                          className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {blueprint.title}
                      </span>
                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </>
    )
}