import {IBatchReport} from "../task/BatchReport";

export class HITBatchReport implements IBatchReport {
    projectId: number;
    batchId: number;
    blueprintId: number;
    title: string;
    summary: string;
    production: boolean;

    constructor(projectId: number, batchId: number, blueprintId: number, title: string, summary: string, production: boolean) {
        this.projectId = projectId;
        this.batchId = batchId;
        this.blueprintId = blueprintId;
        this.title = title;
        this.summary = summary;
        this.production = production;
    }
}