import {AMTWorker} from "../participant/AMTWorker";
import {ITaskResponse} from "../task/TaskResponse";

export class HITResponse implements ITaskResponse {
    id: number;
    batchId: number;
    status: string;
    participant: AMTWorker | undefined;
    summary: string

    constructor(id: number, batchId: number, status: string, participant?: AMTWorker, summary?: string) {
        this.id = id;
        this.batchId = batchId;
        this.status = status;
        this.participant = participant;
        this.summary = summary ? summary : "";
    }
}