import {BlueprintPrototypeItem} from "../../domain/blueprint/BlueprintPrototype";
import {IPrototypeItemRepository} from "../../domain/blueprint/prototype/PrototypeItemRepository";

export class MockPrototypeItemRepository implements IPrototypeItemRepository {
    items: BlueprintPrototypeItem[]
    constructor() {
        this.items = []
    }

    add(item: BlueprintPrototypeItem) {
        this.items.push(item);
    }

    get list () {
        return this.items;
    }

    getById(id: number): BlueprintPrototypeItem| undefined {
        return this.list.find((item) => item.id === id)
    }

    update(to: BlueprintPrototypeItem): void {
        const from = this.list.find((item) => item.id === to.id)

        if (from) {
            const index = this.items.indexOf(from);
            this.list[index] = to;
        }
    }

    clear() {
        this.items = [];
    }

    reset(newItems: BlueprintPrototypeItem[]): void {
        this.items = newItems;
    }

}