import {IHITResponseRepository} from "../../domain/mturk/HITResponseRepository";
import {HITResponse} from "../../domain/mturk/HITResponse";
import {CurrentUser} from "../../usecase/auth/CurrentUser";
import {deserializeHITResponse} from "../../adapter/project/HITResponseAdapter";

export class HITResponseRepository implements IHITResponseRepository {
    currentUser: CurrentUser;

    constructor(user: CurrentUser) {
        this.currentUser = user;
    }

    add(response: HITResponse): Promise<number> {
        return Promise.resolve(0);
    }

    getById(id: number): Promise<HITResponse | undefined> {
        return Promise.resolve(undefined);
    }

    listByTaskBatchId(id: number): Promise<HITResponse[]> {
        const data = JSON.stringify({
            batch_id: id
        });
        const url = "/api/mturk/list_batch_responses";

        const user = this.currentUser.getCurrentUser();

        if (user) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => {
                return response.json();
            }).then((json: any) => {
                const hitResponsesObject = json['hit_batch_responses'];
                const hitResponses: HITResponse[] = hitResponsesObject.map(deserializeHITResponse);

                return hitResponses;
            });
        }

        return Promise.resolve([]);
    }

    updateByTaskBatchId(id: number, accessKey: string, secretKey: string): Promise<HITResponse[]> {
        const data = JSON.stringify({
            batch_id: id,
            aws_access_key: accessKey,
            aws_secret_key: secretKey,
        });
        const url = "/api/mturk/update_batch_responses";

        const user = this.currentUser.getCurrentUser();

        if (user) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => {
                return response.json();
            }).then((json: any) => {
                const hitResponsesObject = json['hit_batch_responses'];
                const hitResponses: HITResponse[] = hitResponsesObject.map(deserializeHITResponse);

                return hitResponses;
            });
        }

        return Promise.resolve([]);
    }
}