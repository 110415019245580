/**
 * Interface: IVertex
 * Holds the coordinates in X,Y,Z
 * It is used to present each point of a cube in 3D Space
 * (i.e. Cube has 8 points in 3D space, each point is represented in X,Y,Z coordinates)
 *
 * @author William Tan 2023 Feb 22.
 */
export interface IVertex {
    /**
     * Coordinate in X-Axis
     */
    x: number;

    /**
     * Coordinate in Y-Axis
     */
    y: number;

    /**
     * Coordinate in Z-Axis
     */
    z: number;
}

/**
 * Class Implementation of the IVertex (See above)
 */
export class Vertex implements IVertex {
    x: number;
    y: number;
    z: number;

    constructor(x: number,y: number,z: number) {
        this.x = x;
        this.y = y;
        this.z = z;
    }
}
