import {IHITBatchRepository} from "../../domain/mturk/HITBatchRepository";
import {CurrentUser} from "../../usecase/auth/CurrentUser";
import {HITBatch} from "../../domain/mturk/HITBatch";
import {deserializeHITBatch} from "../../adapter/project/HITBatchAdapter";
import {AuthenticationService} from "../api/AuthenticationService";
import {Blueprint} from "../../domain/blueprint/Blueprint";
import {deserializeBlueprint} from "../../adapter/project/BlueprintAdapter";

export class HITBatchRepository implements IHITBatchRepository {

    currentUser: CurrentUser;
    constructor(user: CurrentUser) {
        this.currentUser = user;
    }

    add(hitBatch: HITBatch): Promise<string> {
        return Promise.resolve("Not implemented")
    }

    list(): Promise<HITBatch[]> {
        return Promise.resolve([]);
    }

    listByProjectId(projectId: number): Promise<HITBatch[]> {
        const data = JSON.stringify({
            project_id: projectId
        });
        const url = "/api/mturk/list_batches_by_project";

        const user = this.currentUser.getCurrentUser();

        if (user) {
            return fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + user.token,
                },
                body: data
            }).then(response => {
                return response.json();
            }).then((json: any) => {
                const hitBatchesObject = json["hit_batches"];
                const hitBatches: HITBatch[] = hitBatchesObject.map(deserializeHITBatch);
                return hitBatches;
            });
        }

        return Promise.resolve([]);
    }
}