import {IBlueprintQuestionPrototype, IBlueprintQuestionPrototypeFactory} from "./BlueprintQuestionPrototype";
import {getRandomInt} from "../../../util/util";

export class BinaryQuestionPrototype implements IBlueprintQuestionPrototype {
    id: number;
    title: string;
    options: string[];
    constructor(id: number, title: string, options: string[]) {
        this.id = id;
        this.title = '';
        this.options = options;
    }
}

export class BinaryQuestionPrototypeFactory implements IBlueprintQuestionPrototypeFactory {
    create(title: string, options: string[]): BinaryQuestionPrototype {
        const id = getRandomInt();
        return new BinaryQuestionPrototype(id, title, options)
    }
}