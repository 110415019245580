import React from "react";
import {useContext, useState} from "react";
import {BlueprintPrototypeContext, IBlueprintPrototypeContext} from "../Blueprints";
import {
    BlueprintInstructionPrototype,
    BlueprintInstructionPrototypeFactory
} from "../../../../../domain/blueprint/prototype/instruction/BlueprintInstructionPrototype";

export function BlueprintDesignSurveyLink () {
    const {blueprintPrototype, setBlueprintPrototype} = useContext(BlueprintPrototypeContext) as IBlueprintPrototypeContext

    const instructionFactory = new BlueprintInstructionPrototypeFactory();

    const defaultSurveyLinkInstruction = instructionFactory.create('Survey Link Instruction', '')
    const [surveyLinkInstruction, setSurveyLinkInstruction] = useState(defaultSurveyLinkInstruction);

    const defaultSurveyLinkURL = instructionFactory.create('Survey Link Body', '');
    const [surveyLinkURL, setSurveyLinkURL] = useState<BlueprintInstructionPrototype>(defaultSurveyLinkURL);

    function handleSurveyLinkInstructionChange (e: React.ChangeEvent<HTMLTextAreaElement>) {
        const newInstruction = instructionFactory.copy(surveyLinkURL, undefined, undefined, e.target.value)
        setSurveyLinkInstruction(newInstruction);

        blueprintPrototype.itemRepo.reset([
            newInstruction,
            blueprintPrototype.itemRepo.list[1]
        ]);
        setBlueprintPrototype({...blueprintPrototype, itemRepo: blueprintPrototype.itemRepo});
    }

    function handleSurveyLinkURLChange (e: React.ChangeEvent<HTMLInputElement>) {
        const newURL = instructionFactory.copy(surveyLinkURL, undefined, undefined, e.target.value)
        setSurveyLinkURL(newURL);

        blueprintPrototype.itemRepo.reset([
            blueprintPrototype.itemRepo.list[0],
            newURL
        ]);
        setBlueprintPrototype({...blueprintPrototype, itemRepo: blueprintPrototype.itemRepo});
    }

    return (
        <>
            <div className="px-6 text-gray-500">
                <h2 className="text-lg font-medium leading-6 text-gray-600 pb-6">
                    Survey Link Task Blueprint
                </h2>
                <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="blueprint-description">
                                Instruction to participants
                            </label>
                            <textarea
                                rows={5}
                                id="blueprint-description"
                                placeholder="Please answer questions in the Qualtrics survey linked from the following URL. Once you finish the survey, you will be presented with a six digit completion code. Enter the completion code in the field below and submit."
                                value={surveyLinkInstruction.body}
                                onChange={handleSurveyLinkInstructionChange}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="blueprint-survey-link-url">
                                Survey link URL
                            </label>
                            <input
                                id="blueprint-survey-link-url"
                                type="text"
                                value={surveyLinkURL.body}
                                onChange={handleSurveyLinkURLChange}
                                // placeholder="Blueprint title"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}