import {ReportTableHeader} from "../table/ReportTableHeader";
import React from "react";
import {HITResponse} from "../../../../../domain/mturk/HITResponse";
import {ReportProgressTableRow} from "./ReportProgressTableRow";

export interface IReportProgressTableProps {
    responses: HITResponse[]
}

export function ReportProgressTable ({responses}: IReportProgressTableProps) {

    return (
        <>
            <div className='flex flex-col'>
                <div className='overflow-x-auto'>
                    <div className='py-2 inline-block min-w-full sm:px-0 lg:px-0'>
                        <div className="overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='bg-white border-b'>
                                <tr>
                                    {/*<BlueprintTableHeader label={'ID'}></BlueprintTableHeader>*/}
                                    <ReportTableHeader label={'Batch ID'}></ReportTableHeader>
                                    <ReportTableHeader label={'Status'}></ReportTableHeader>
                                    <ReportTableHeader label={'Participant'}></ReportTableHeader>
                                    <ReportTableHeader label={'Result'}></ReportTableHeader>
                                    <ReportTableHeader label={''}></ReportTableHeader>
                                </tr>
                                </thead>
                                <tbody>
                                {responses.map((response: HITResponse) => (
                                    <ReportProgressTableRow
                                        key={response.id.toString()}
                                        batchId={response.batchId}
                                        status={response.status}
                                        participant={response.participant?.workerId!}
                                        result={response.summary}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}