import {BlueprintStepper} from "./BlueprintStepper";
import {Link} from "react-router-dom";
import React from "react";
import {BlueprintPrototypeContext, IBlueprintPrototypeContext} from "../Blueprints";
import BlueprintTypeCombobox from "./BlueprintTypeCombobox";


export function BlueprintCreate () {
    const {blueprintPrototype, setBlueprintPrototype} = React.useContext(BlueprintPrototypeContext) as IBlueprintPrototypeContext

    function handleTitleChange (e: React.ChangeEvent<HTMLInputElement>) {
        setBlueprintPrototype({...blueprintPrototype, "title": e.target.value})
    }

    function handleDescriptionChange (e: React.ChangeEvent<HTMLTextAreaElement>) {
        setBlueprintPrototype({...blueprintPrototype, description: e.target.value})
    }

    return (
        <>
            <BlueprintStepper step={1} />

            <div className="px-6 text-gray-500">
                <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="blueprint-task-type">
                                Task Type
                            </label>
                            <BlueprintTypeCombobox />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="blueprint-title">
                                Blueprint Title
                            </label>
                            <input
                                id="blueprint-title"
                                type="text"
                                value={blueprintPrototype.title}
                                onChange={handleTitleChange}
                                // placeholder="Blueprint title"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                 />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="blueprint-description">
                                Blueprint Description
                            </label>
                            <textarea
                                rows={2}
                                id="blueprint-description"
                                placeholder="Blueprint description"
                                value={blueprintPrototype.description}
                                onChange={handleDescriptionChange}
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                        </div>
                    </div>
                </form>
            </div>

            <div className="flex items-center py-6 px-6">
                <Link to='../design'>
                    <button
                        type='button'
                        className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                        bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                        hover:shadow-xl transition duration-200"
                    >
                        <span className="text-sm font-semibold tracking-wide">Next</span>
                    </button>
                </Link>

                <span className='px-4'>
                    <Link to='..'>
                        Cancel
                    </Link>
                </span>
            </div>
        </>
    )
}