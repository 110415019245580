import React from "react";
import {BlueprintPrototypeItem} from "../../../../../domain/blueprint/BlueprintPrototype";
import {BlueprintPrototypeContext, IBlueprintPrototypeContext} from "../Blueprints";

export interface IBlueprintCardProps {
    item: BlueprintPrototypeItem
}

export function BlueprintPrototypeCard ({item}: IBlueprintCardProps) {
    const {blueprintPrototype, setBlueprintPrototype} = React.useContext(BlueprintPrototypeContext) as IBlueprintPrototypeContext

    if ('options' in item) {
        if (!item.options) {
            item.options = [];
        }
        while (item.options.length < 2) {
            item.options.push('');
        }
    }

    function handleQuestionChange (e: React.ChangeEvent<HTMLInputElement>) {
        item.title = e.target.value;
        blueprintPrototype.itemRepo.update(item);
        setBlueprintPrototype({...blueprintPrototype, "itemRepo": blueprintPrototype.itemRepo})
    }

    function handleBodyChange (e: React.ChangeEvent<HTMLInputElement>) {
        item.body = e.target.value;
        blueprintPrototype.itemRepo.update(item);
        setBlueprintPrototype({...blueprintPrototype, "itemRepo": blueprintPrototype.itemRepo})
    }

    function handleOption1Change (e: React.ChangeEvent<HTMLInputElement>) {
        if ('options' in item) {
            item.options![0] = e.target.value;
            blueprintPrototype.itemRepo.update(item);
            setBlueprintPrototype({...blueprintPrototype, "itemRepo": blueprintPrototype.itemRepo})
        }
    }

    function handleOption2Change (e: React.ChangeEvent<HTMLInputElement>) {
        if ('options' in item) {
            item.options![1] = e.target.value;
            blueprintPrototype.itemRepo.update(item);
            setBlueprintPrototype({...blueprintPrototype, "itemRepo": blueprintPrototype.itemRepo})
        }
    }

    return (
        <>
            <div className="max-w-sm rounded overflow-hidden shadow-lg mb-4 justify-self-center">
                <div className="px-4 pt-4">
                    <div className="font-bold text-md mb-2">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor={`blueprint-prototype-item-title-${item.id}`}>
                                Binary Question
                            </label>
                            <input
                                id={`blueprint-prototype-item-title-${item.id}`}
                                type="text"
                                value={item.title}
                                onChange={handleQuestionChange}
                                placeholder='Your question'
                                className="appearance-none block w-full text-gray-700 bg-gray-50
                                py-3 px-4 leading-tight
                                focus:outline-none focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="font-bold text-md mb-2">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor={`blueprint-prototype-item-body-${item.id}`}>
                                Body
                            </label>
                            <input
                                id={`blueprint-prototype-item-body-${item.id}`}
                                type="text"
                                value={item.body ? item.body : ''}
                                onChange={handleBodyChange}
                                placeholder='Additional note'
                                className="appearance-none block w-full text-gray-700 bg-gray-50
                                py-3 px-4 leading-tight
                                focus:outline-none focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6 px-3">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor={`blueprint-prototype-item-option-1-${item.id}`}>
                                Option 1
                            </label>
                            <input
                                className="appearance-none block w-full text-gray-700 bg-gray-50
                                py-3 px-4 leading-tight
                                focus:outline-none focus:border-gray-500"
                                id={`blueprint-prototype-item-option-1-${item.id}`}
                                type="text"
                                value={'options' in item ? item.options![0] : ''}
                                onChange={handleOption1Change}
                                placeholder="Option 1" />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor={`blueprint-prototype-item-option-2-${item.id}`}>
                                Option 2
                            </label>
                            <input
                                className="appearance-none block w-full text-gray-700 bg-gray-50
                                py-3 px-4 leading-tight
                                focus:outline-none focus:border-gray-500"
                                id={`blueprint-prototype-item-option-2-${item.id}`}
                                value={'options' in item ? item.options![1] : ''}
                                onChange={handleOption2Change}
                                type="text"
                                placeholder="Option 2" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}