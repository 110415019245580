import React from "react";
import {Outlet} from "react-router-dom";
import {ConsoleHeader} from "../ConsoleHeader";


export function Reports () {
    return (
        <>
            <ConsoleHeader title='Reports' subtitle='' />
            <Outlet />
        </>
    )
}