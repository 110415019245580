import {IUserTasksService} from "../../usecase/tasks/UserTasksService";
import {AnnotatedObjectList} from "../../domain/user_tasks/annotate_3d_room/AnnotatedObjectList";  //  William Added. For Web Services call.

/**
 * Class implementation of IUserTasksService
 * (See IUserTasksService for more details)
 *
 * @author William Tan 2023 Feb 22.
 */
export class UserTasksService implements IUserTasksService {

    task_3d_rm_annotate_list_rooms(blueprint_id: number): Promise<any> {

        //  Set up the POST JSON
        const data = JSON.stringify({
            blueprint_id: blueprint_id,
        })

        //  Download the data
        const promise = fetch('/api/tasks/annotate_room_list_tasks', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        });

        //  Return the JSON Data
        return promise.then(response => response.json()).then(json => {
            let str_data = JSON.stringify(json);

            if (str_data.includes("Status") && str_data.includes("Fail")) {

                //  Fail: Show the failure message
                alert(json.Message)
            }

            return JSON.stringify(json);
        });

    }

    task_3d_rm_upload_annotation(blueprint_id: number, annotate_list: AnnotatedObjectList): Promise<any> {

        //  Setup the POST JSON
        const data = JSON.stringify({
            blueprint_id: blueprint_id,
            annotated_list: annotate_list.listOfObjects
        })

        //  Upload the data
        const promise = fetch('/api/tasks/annotate_room_submit_annotated_objects', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        });

        //  Return the JSON Data
        return promise.then(response => response.json()).then(json => {
            let str_data = JSON.stringify(json);

            if (str_data.includes("status") && str_data.includes("Fail")) {
                // Fail: Show the failure message
                alert(json.Message)
            }

            return JSON.stringify(json);
        });
    }


    task_3d_rm_get_annotation(blueprint_id: number, room_id: number): Promise<any> {

        //  Setup the POST JSON
        const data = JSON.stringify({
            blueprint_id: blueprint_id,
            room_id: room_id
        })

        //  Upload the data
        const promise = fetch('/api/tasks/annotate_room_get_annotated_objects', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: data
        });

        //  Return the JSON Data
        return promise.then(response => response.json()).then(json => {
            let str_data = JSON.stringify(json);

            if (str_data.includes("status") && str_data.includes("Fail")) {
                // Fail: Show the failure message
                alert(json.Message)
            }

            return JSON.stringify(json);
        });
    }
}