import * as THREE from "three";


/**
 * Interface: Holds the action interface for an Undo/Redo operation.
 * This includes:
 *  -   actionType: The type of action
 *  -   actionTarget: The target name/label/etc.
 *  -   targetCube: The ThreeJS Mesh data (Note: Passed-by-ref)
 *  -   ActionTime: The time the action was taken.
 * @author William Tan Kiat Wee 25 Sep 2023
 */
export interface ActionItem {
    actionType: string;
    actionTarget: string;
    targetCube: THREE.Mesh | null;
    actionTime: string;
}


/**
 * Point-Cloud Portal Action Logger
 *
 * This class is used to capture the actions undertaken by the user when interacting with the portal.
 * This allows the user to perform Undo an action or redo the action.
 *
 * Action Logger has the following behaviour/features:
 *  -   Tracks the action (ActionItem) in an array (stack)
 *  -   When undo is executed, the ActionItem is not "pop" from the stack, instead an index is tracked.
 *  -   When redo is executed, the index is replayed with the ActionItem. (Hence ActionItem is not "pop")
 *  -   When the user has performed an undo(s), and now execute a new ActionItem, actionItem now replaced the stack from where the index is.
 *  -   Hence, previous ActionItem from where the index is now cleared and the new ActionItem takes over the new slot.
 *
 * @author William Tan Kiat Wee 25 Sep 2023
 */
export class PCDActionLogger {

    /**
     * CONSTANTS. Action Type: Empty action.
     */
    //public static ACTION_NIL: string = "NIL";

    /**
     * CONSTANTS. Action Type: Boot Init.
     */
    public static ACTION_INIT: string = "INIT";

    /**
     * CONSTANTS. Action Type: When Cube is added into the scene.
     */
    public static ACTION_ADD_CUBE: string = "CUBE_ADD";

    /**
     * CONSTANTS. Action Type: When Cube is moved within the scene.
     */
    public static ACTION_MOVE_CUBE: string = "CUBE_MOVE";

    /**
     * CONSTANTS. Action Type: When Cube is re-sized.
     */
    public static ACTION_SIZE_CUBE: string = "CUBE_SIZE";

    /**
     * Tracks where the Undo/Redo operation is currently pointing in the stack
     */
    indexBrowser: number = -1;

    /**
     * The stack that holds the Actions that the user did.
     */
    actionHistory: ActionItem[] = [];

    /**
     * Get Current Time
     * Obtains the time in string format: year/month/date hour:min:sec
     */
    public getCurrentTime() {
        const now: Date = new Date();
        const year = now.getFullYear();
        const month = ("00" + (now.getMonth() + 1)).slice(-2);
        const date = ("00" + (now.getDate())).slice(-2);
        const hours = ("00" + (now.getHours())).slice(-2);
        const min = ("00" + (now.getMinutes())).slice(-2);
        const sec = ("00" + (now.getSeconds())).slice(-2);

        return [year, month, date].join('/') + " " + [hours, min, sec].join(':')
    }

    /**
     * Reset the log
     *
     * Use when switching between tasks (e.g. new room)
     */
    public reset() {

        //  Reset
        this.actionHistory = [];
        this.indexBrowser = -1;
    }

    /**
     * Get the Browser Index
     * (number)
     *
     * This returns the position where the Undo/Redo pointer is currently at in the stack.
     */
    public getBrowseIndex(): number {
        return this.indexBrowser;
    }

    /**
     * Set the Browser Index
     * (number)
     *
     * This sets the browser position where the Undo/Redo pointer is currently at in the stack.
     * @param browseIndex number the browser position
     */
    public setBrowseIndex(browseIndex: number): void {
        this.indexBrowser = browseIndex;
    }

    /**
     * Decrement the Browser Index
     * (number)
     *
     * This decrement the Browser index by one and return the value.
     * However, doesn't go below 0.
     */
    public decrementBrowseIndex(): number {
        let localIndexBrowser: number = this.indexBrowser;
        localIndexBrowser = localIndexBrowser - 1;
        if (localIndexBrowser >= 0)
            this.indexBrowser = localIndexBrowser;
        else
            localIndexBrowser = 0;

        return localIndexBrowser;
    }

    /**
     * Get the History/Stack size
     * (number)
     */
    public getHistorySize(): number {
        return this.actionHistory.length;
    }

    /**
     * Renames the Cube Names in the stack.
     *
     * Purpose: Portal allows cube to be renamed, hence, we need to update the names of the cube in the stack to follow the change.
     *
     * Returns True is success, False is failed
     *
     * @param oldCubeName string The cube's old name (to be replaced)
     * @param newCubeName string The cube's new name (to be set)
     */
    public rename_cube_name(oldCubeName: string, newCubeName: string): boolean {

        //  Check if there is a stack to do rename.
        if (this.actionHistory && this.actionHistory.length > 0) {
            // console.log("Old Name: " + oldCubeName);
            // console.log("New Name: " + newCubeName);

            //  Iterate through the action log
            this.actionHistory.forEach(eachActionItem => {

                //  Check through all action items for the old name
                if (eachActionItem && eachActionItem.targetCube && eachActionItem.actionTarget === oldCubeName) {

                    // console.log(eachActionItem.targetCube);

                    //  Match name: Update both name + THREEJS Mesh name and rename them
                    eachActionItem.actionTarget = newCubeName;
                    eachActionItem.targetCube = this.rename_cube_mesh(newCubeName, eachActionItem.targetCube);
                }
            });

            // console.log(this.actionHistory);
            return true;
        }

        return false;
    }

    /**
     * Push action into the stack/History
     *
     * @param actionType string The Action type (see available constants)
     * @param actionTarget string The target name/label
     * @param targetCube ThreeJS.Mesh The cube mesh handle (note: This is passed-by-ref. Please cloned before passed in the ref)
     */
    public push(actionType: string, actionTarget: string, targetCube: THREE.Mesh | null) {

        //  Get current time
        const currentTime: string = this.getCurrentTime();

        //  Create the ActionItem
        let actionEntry: ActionItem = {actionType: actionType, actionTarget: actionTarget, targetCube: targetCube, actionTime: currentTime};

        if (this.actionHistory === undefined) {
            //  Empty stack

            let localActionHistory: ActionItem[] = [];
            localActionHistory.push(actionEntry);
            this.actionHistory = localActionHistory;
        } else {
            //  Existing stack

            if (this.actionHistory.length > 0) {
                //  Stack already has content.

                if (this.indexBrowser < 0)
                    //  Index browser is -1, means new stack, just push into it.
                    this.actionHistory.push(actionEntry);
                else {
                    //  Push into existing stack, we need to reset the index to the current index:
                    //  If we pushing it in the middle of the stack (indicating, we are going to override old actions), we need to reset
                    //  If detect we are at the end of the stack, we just push into it as per normal.
                    this.resetToBrowseIndex();
                    this.actionHistory.push(actionEntry);
                }
            } else {
                //  Empty stack

                let localActionHistory: ActionItem[] = [];
                localActionHistory.push(actionEntry);
                this.actionHistory = localActionHistory;
            }
        }

        //console.log("actionLogOperator");
        //console.log(actionHistory);
    }

    /**
     * Get the BrowseIndex, ActionItem given the Index Number
     *
     * Returns the tuple [The browseIndex, the ActionItem] or null if not available.
     *
     * @param itemIndex number The index position (pointing to the stack/history)
     */
    public get(itemIndex: number): [number, ActionItem] | null {

        if (this.actionHistory === undefined) {
            return null;
        } else {
            if (itemIndex < 0)
                return [this.actionHistory.length - 1, this.actionHistory[this.actionHistory.length - 1]];
            else
                return [itemIndex, this.actionHistory[itemIndex]];
        }
    }

    /**
     * Get the ActionItem given the Index Number
     *
     * Returns the ActionItem or null if not available.
     *
     * @param itemIndex number The index position (pointing to the stack/history)
     */
    public getActionItem(itemIndex: number): ActionItem | null {

        if (this.actionHistory === undefined) {
            return null;
        } else {
            if (itemIndex < 0)
                return null;
            else {
                let returnResult: [number, ActionItem] = [itemIndex, this.actionHistory[itemIndex]];
                return returnResult[1];
            }
        }
    }

    /**
     * Reset the History/Stack to the Browser Index
     *
     * This will remove all actions in the stack from the Pointer onwards.
     * E.g. Stack have current items: 0,1,2,3,4,5,6
     * Browser Index at 3
     * Calling this function will result the stack to be 0,1,2,3
     *
     * Purpose: Allows the user to now insert a new ActionItem from this point forward.
     *
     */
    public resetToBrowseIndex() {

        //  Only do this when the index browser is more than -1 (there is content in the stack)
        if (this.indexBrowser > -1) {
            if (this.actionHistory === undefined) {
                return;
            } else {
                //console.log("actionHistory.length: " + this.actionHistory.length);
                //console.log("Before indexBrowser: " + this.indexBrowser);

                //  If this stack is empty, reset.
                if (this.actionHistory.length == 0) {
                    this.actionHistory = [];
                    this.indexBrowser = -1;
                } else {

                    //  Store the existing items into new stack up to the current index browser
                    let localActionHistory: ActionItem[] = [];
                    for (let i: number = 0; i < (this.indexBrowser + 1); i++) {
                        let item = this.actionHistory.at(this.indexBrowser);
                        if (item) {

                            //console.log("Item= ");
                            //console.log(item);

                            localActionHistory.push(item);
                        }
                    }
                    //  Assign local stack as new stack
                    this.actionHistory = localActionHistory;

                    //  Reset the index browser, this effective indicates the next Push() will go to the end of the stack.
                    this.indexBrowser = -1;

                    //console.log("actionHistory.length: " + this.actionHistory.length);
                    //console.log("After indexBrowser: " + this.indexBrowser);
                }
            }
        }
    }

    /**
     * (Not Use: For development use.
     * Prints the Stack.
     *
     * This is use as, the stack holds items (passed-by-ref), hence, if you do console.log, output will be the current items,
     * you might not see history. Hence, this function is used.
     */
    // public printLog() {
    //
    //     let length: number = this.actionHistory.length;
    //     console.log("actionHistory.length: " + length);
    //     for (let i: number = 0; i < length; i++) {
    //         let item = this.actionHistory.at(i);
    //         if (item?.targetCube) {
    //             console.log(item.actionType + ". " + item.targetCube.name + ", X: " + item.targetCube.position.x + ", Y: " + item.targetCube.position.y + ", Z: " + item.targetCube.position.z);
    //         } else {
    //             if (item?.actionType)
    //                 console.log(item.actionType);
    //         }
    //     }
    //     console.log("================================================");
    // }


    /**
     * Renames the ThreeJS Mesh Cube
     *
     * @param newCubeName string The new cube name
     * @param currentMeshCube THREE.Mesh The existing handle to the cube.
     * @private Returns the THREE.Mesh with the updated cube name
     */
    private rename_cube_mesh(newCubeName: string, currentMeshCube: THREE.Mesh): THREE.Mesh | null {

        if (newCubeName && currentMeshCube) {
            let newCube = currentMeshCube.clone();
            newCube.name = newCubeName;
            return newCube;
        } else
            return null;
    }
}