import React from "react";
import {BlueprintCard} from "./BlueprintCard";
import {Blueprint} from "../../domain/blueprint/Blueprint";

export interface IBlueprintCardsProps {
    blueprint: Blueprint
}

export function BlueprintCards({blueprint}: IBlueprintCardsProps) {
    return (
        <>
            <div className='flex flex-col gap-y-4'>
                {blueprint.items.map((item, index) =>
                    <BlueprintCard key={index} item={item} />
                )}

            </div>
        </>
    )
}