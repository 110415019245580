import {BatchStepper} from "./BatchStepper";
import React, {useState} from "react";
import {BatchPrototypeContext, IBatchPrototypeContext} from "../Batches";
import {Link} from "react-router-dom";
import {ITaskAttributes} from "../../../../../domain/mturk/MTurkService";
import {ISurveyLinkAttributes, ISurveyLinkProtocol} from "../../../../../domain/mturk/SurveyLinkTask";
import {Blueprint, BlueprintTypeId} from "../../../../../domain/blueprint/Blueprint";
import {GlobalContext, IGlobalContext} from "../../../../../App";

export function BatchPublish() {
    const {mTurkService} = React.useContext(GlobalContext) as IGlobalContext;

    const {batchPrototype, setBatchPrototype} = React.useContext(BatchPrototypeContext) as IBatchPrototypeContext

    const [accessKey, setAccessKey] = useState<string>('');
    const [secretKey, setSecretKey] = useState<string>('');

    function handleAccessKeyChange(e: React.ChangeEvent<HTMLInputElement>) {
        const key: string = e.target.value;
        setAccessKey(key);
    }

    function handleSecretKeyChange(e: React.ChangeEvent<HTMLInputElement>) {
        const key: string = e.target.value;
        setSecretKey(key);
    }

    function handleProductionRadioOnChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value: string = e.target.value;
        const isProduction: boolean = value === 'production';

        setBatchPrototype({...batchPrototype, production: isProduction})
    }

    async function handlePublishClick() {
        const taskAttributes: ITaskAttributes = {
            amt_hit_id: batchPrototype.amt_hit_id!,
            title: batchPrototype.title!,
            assignment_duration_s: batchPrototype.assignmentDuration_s!,
            description: batchPrototype.description!,
            keywords: batchPrototype.keywords!,
            max_assignments: batchPrototype.maxAssignments!,
            reward: batchPrototype.reward!,
            time_to_expire_s: batchPrototype.timeToExpire_s!,
            auto_approval_delay_s: batchPrototype.autoApprovalDelay_s!,
            qualification_requirements: [],
            production: batchPrototype.production!
        }

        console.log("Task Attribute")
        console.log(taskAttributes);
        console.log("Batch Prototype")
        console.log(batchPrototype)

        const blueprint: Blueprint = batchPrototype.blueprint!;

        if (blueprint.typeId === BlueprintTypeId.SurveyLink) {
            const surveyLinkAttributes: ISurveyLinkAttributes = {
                instruction: blueprint.items[0].body!,
                survey_url: blueprint.items[1].body!,
                blueprint_id: blueprint.id
            }

            const surveyLinkProtocol: ISurveyLinkProtocol = {
                task_attributes: taskAttributes,
                survey_link_attributes: surveyLinkAttributes
            }

            await mTurkService.createSurveyLinkHIT(accessKey, secretKey, surveyLinkProtocol);
        }
    }

    return (
        <>
            <BatchStepper step={3}/>

            <div className='flex flex-col'>
                <div className='overflow-x-auto'>
                    <div className='py-2 inline-block min-w-full sm:px-0 lg:px-0'>
                        <div className="overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='bg-white border-b'>
                                <tr>
                                    <th className='text-left text-sm text-gray-900 px-6 py-4 whitespace-nowrap'>Task
                                        Attribute
                                    </th>
                                    <th className='text-left text-sm text-gray-900 px-6 py-4 whitespace-nowrap'>Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Blueprint
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.blueprint?.title}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Target Participants
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.population?.name}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Qualifications
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.qualifications}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Title
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.title}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Description
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.description}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Keywords
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.keywords}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Number of Participants
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.maxAssignments}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Time to Expire (hour)
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.timeToExpire_s ? batchPrototype.timeToExpire_s! / (60 * 60) : ''}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Max Task Duration (minute)
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.assignmentDuration_s ? batchPrototype.assignmentDuration_s! / (60) : ''}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Reward (US$)
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.reward}
                                    </td>
                                </tr>

                                <tr className='bg-gray-100 border-b'>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        Auto Approval Delay (hour)
                                    </td>
                                    <td className="text-left text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {batchPrototype.autoApprovalDelay_s ? batchPrototype.autoApprovalDelay_s! / (60 * 60) : ''}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-6 pt-6 text-gray-500">
                <form className="w-full max-w-lg" autoComplete="on">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="amt-access-key">
                                Access Key
                            </label>
                            <input
                                id="amt-access-key"
                                type="username"
                                value={accessKey}
                                onChange={handleAccessKeyChange}
                                placeholder="Access Key"
                                autoComplete="on"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                   htmlFor="amt-secret-key">
                                Secret Key
                            </label>
                            <input
                                id="amt-secret-key"
                                type="password"
                                value={secretKey}
                                onChange={handleSecretKeyChange}
                                placeholder="Secret Key"
                                autoComplete="on"
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap  py-4">
                        <div className="form-check form-check-inline mx-6">
                            <label className="form-check-label inline-block text-gray-800"
                                   htmlFor="task-target-sandbox">
                                <input
                                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" name="inlineRadioOptions" id="task-target-sandbox" value="sandbox"
                                    defaultChecked={true}
                                    onChange={handleProductionRadioOnChange}
                                />
                                Sandbox
                            </label>
                        </div>
                        <div className="form-check form-check-inline mx-4">

                            <label className="form-check-label inline-block text-gray-800"
                                   htmlFor="task-target-production">
                                <input
                                    className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                    type="radio" name="inlineRadioOptions" id="task-target-production"
                                    value="production"
                                    onChange={handleProductionRadioOnChange}
                                />
                                Production
                            </label>
                        </div>
                    </div>
                </form>
            </div>

            <div className="flex items-center py-6 px-6">
                <span className='px-2'>
                    <Link to='../attributes'>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-1
                            bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                        >
                            <span className="text-sm font-semibold tracking-wide">Back</span>
                        </button>
                    </Link>
                </span>
                <span className='px-2'>
                    <Link to='..'>
                        <button
                            type='button'
                            className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                            bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                            onClick={handlePublishClick}
                        >
                            <span className="text-sm font-semibold tracking-wide">Publish</span>
                        </button>
                    </Link>
                </span>

                <span className='px-4'>
                    <Link to='..'>
                        Cancel
                    </Link>
                </span>
            </div>
        </>
    )
}