import {HITBatch, HITBatchFactory} from "../../domain/mturk/HITBatch";
import {ITaskAttributes} from "../../domain/mturk/MTurkService";
import {Blueprint} from "../../domain/blueprint/Blueprint";
import {User, UserFactory} from "../../domain/user/User";
import {deserializeBlueprint} from "./BlueprintAdapter";
import {HIT} from "../../domain/mturk/HIT";
import {deserializeHIT} from "./HITAdapter"


export function deserializeHITBatch(hitBatchObject: any): HITBatch {
    const hits: HIT[] = hitBatchObject.hits.map(deserializeHIT)
    let taskAttribute: ITaskAttributes

    if (hits.length > 0) {
        const timeToExpire: number = (hits[0].expiration.getTime() - hits[0].creation_time.getTime()) / 1000;

        taskAttribute = {
            amt_hit_id: hits[0].amt_hit_id,
            title: hits[0].title,
            description: hits[0].description,
            keywords: hits[0].keywords,
            max_assignments: hits[0].max_assignments,
            assignment_duration_s: hits[0].assignment_duration_s,
            reward: hits[0].reward,
            time_to_expire_s: timeToExpire,
            auto_approval_delay_s: hits[0].auto_approval_delay_s,
            qualification_requirements: hits[0].qualification_requirements,
            production: hits[0].production,
        };
    } else {
        taskAttribute = {
            amt_hit_id: "default",
            title: "title",
            description: "description",
            keywords: "keywords",
            max_assignments: 1,
            assignment_duration_s: 1,
            reward: 0.01,
            time_to_expire_s: 1,
            auto_approval_delay_s: 1,
            qualification_requirements: [],
            production: false,
        };
    }

    const username: string = hitBatchObject["created_by"]["username"];
    const userId: number = hitBatchObject["created_by"]["user_id"];

    const userFactory = new UserFactory()
    const createdBy: User = userFactory.create(username, userId);
    const blueprint: Blueprint = deserializeBlueprint(hitBatchObject["blueprint"])

    const batchFactory = new HITBatchFactory()

    const batchId = hitBatchObject["hit_batch_id"];
    return batchFactory.create(taskAttribute, blueprint, hits, createdBy, batchId)
}