import {ITaskAttributes} from "./MTurkService";
import {Blueprint} from "../blueprint/Blueprint";
import {HIT} from "./HIT";
import {IUser, User} from "../user/User";
import {getRandomInt} from "../util/util";

export interface IHITBatch {
    batchId: number;
    taskAttributes: ITaskAttributes;
    blueprint: Blueprint;
    hits: HIT[];
    createdBy: IUser;
}

export class HITBatch implements IHITBatch {
    batchId: number;
    taskAttributes: ITaskAttributes;
    blueprint: Blueprint;
    hits: HIT[];
    createdBy: User;

    constructor(batchId: number, taskAttributes: ITaskAttributes, blueprint: Blueprint, hits: HIT[], createdBy: User) {
        this.batchId = batchId;
        this.taskAttributes = taskAttributes;
        this.blueprint = blueprint;
        this.hits = hits;
        this.createdBy = createdBy;
    }

    toJson(): any {
        return {
            "blueprint_id": this.blueprint.id,
            "username": this.createdBy.name
        }
    }
}

export interface IHITBatchFactory {
    create(taskAttribute: ITaskAttributes, blueprint: Blueprint, hits: HIT[], createdBy: IUser): IHITBatch
}

export class HITBatchFactory implements IHITBatchFactory {
    create(taskAttribute: ITaskAttributes, blueprint: Blueprint, hits: HIT[], createdBy: User, batchId?: number): HITBatch {
        const id = batchId ? batchId : getRandomInt();
        return new HITBatch(id, taskAttribute, blueprint, hits, createdBy);
    }
}