import {BlueprintPrototypeCard} from "./BlueprintPrototypeCard";
import React from "react";
import {BlueprintPrototypeContext, IBlueprintPrototypeContext} from "../Blueprints";

export function BlueprintPrototypeCards() {
    const {blueprintPrototype} = React.useContext(BlueprintPrototypeContext) as IBlueprintPrototypeContext

    return (
        <>
            <div className='flex flex-col gap-y-4'>
                {blueprintPrototype.itemRepo.list.map((item) =>
                    <BlueprintPrototypeCard
                        key={item.id}
                        item={item}/>
                )}
            </div>
        </>
    )
}