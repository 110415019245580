import React from "react";
import {ConsoleHeader} from "../ConsoleHeader";
import {Outlet} from "react-router-dom";
import {IBlueprintPrototype} from "../../../../domain/blueprint/BlueprintPrototype";
import {GlobalContext, IGlobalContext} from "../../../../App";
import {MockPrototypeItemRepository} from "../../../../infra/repository/MockPrototypeItemRepository";

export type IBlueprintPrototypeContext = {
    blueprintPrototype: IBlueprintPrototype;
    setBlueprintPrototype: (prototype: IBlueprintPrototype) => void;
};

export const BlueprintPrototypeContext = React.createContext<IBlueprintPrototypeContext | null>(null);

export function Blueprints () {
    const {currentUser} = React.useContext(GlobalContext) as IGlobalContext;

    const itemRepository = new MockPrototypeItemRepository();

    const [blueprintPrototype, setBlueprintPrototype] = React.useState<IBlueprintPrototype>({
            title: '',
            description: '',
            typeId: undefined,
            itemRepo: itemRepository,
            createdBy: currentUser.getCurrentUser()!
        });

    return (
        <>
            <BlueprintPrototypeContext.Provider value={{blueprintPrototype, setBlueprintPrototype}}>
                <ConsoleHeader title='Blueprints' subtitle='' />
                <Outlet />
            </BlueprintPrototypeContext.Provider>
        </>
    )
}