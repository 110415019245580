import {BlueprintTableHeader} from "./BlueprintTableHeader";
import {BlueprintTableRow} from "./BlueprintTableRow";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {GlobalContext, IGlobalContext} from "../../../../../App";
import {Blueprint} from "../../../../../domain/blueprint/Blueprint";


export function BlueprintTable () {
    const {blueprintRepository, currentProject} = React.useContext(GlobalContext) as IGlobalContext;

    const [blueprints, setBlueprints] = React.useState<Blueprint[]>([])

    useEffect( function () {
        if (currentProject) {
            const fetchBlueprints = async () => {
                const _blueprints = await blueprintRepository.getByProject(currentProject.id);
                setBlueprints(_blueprints);
            }
            fetchBlueprints().catch(console.error);
        }
    }, [currentProject, blueprintRepository])

    return (
        <>
            <div className="flex items-center justify-between py-2 px-6">
                <div>
                    <p className="text-left text-sm font-medium text-gray-500">
                        A list of task blueprints.
                    </p>
                </div>
                <div className="">
                    <button
                        type='button'
                        className="inline-flex gap-x-2 items-center py-1 px-4 rounded-md
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1
                            bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded shadow-lg
                            hover:shadow-xl transition duration-200"
                    >
                        <Link to='create'>
                            <span className="text-sm font-semibold tracking-wide">Create a New Blueprint</span>
                        </Link>
                    </button>
                </div>
            </div>
            <div className='flex flex-col'>
                <div className='overflow-x-auto'>
                    <div className='py-2 inline-block min-w-full sm:px-0 lg:px-0'>
                        <div className="overflow-hidden">
                            <table className='min-w-full'>
                                <thead className='bg-white border-b'>
                                <tr>
                                    {/*<BlueprintTableHeader label={'ID'}></BlueprintTableHeader>*/}
                                    <BlueprintTableHeader label={'Title'}></BlueprintTableHeader>
                                    <BlueprintTableHeader label={'Description'}></BlueprintTableHeader>
                                    <BlueprintTableHeader label={'Type'}></BlueprintTableHeader>
                                    <BlueprintTableHeader label={'Created by'}></BlueprintTableHeader>
                                    <BlueprintTableHeader label={''}></BlueprintTableHeader>
                                </tr>
                                </thead>
                                <tbody>
                                {blueprints.map((blueprint) => (
                                    <BlueprintTableRow
                                        key={blueprint.id.toString()}
                                        id={blueprint.id}
                                        title={blueprint.title}
                                        description={blueprint.description}
                                        typeId={blueprint.typeId}
                                        createdBy={blueprint.createdBy.name}
                                    />
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}