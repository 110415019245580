import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {ProjectTable} from "./components/requester/project/ProjectTable";
import {SignIn} from "./components/requester/user/SignIn";
import {SignUp} from "./components/requester/user/SignUp";
import {ChangePasswd} from "./components/requester/user/ChangePasswd";
import {Console} from "./components/requester/console/Console";
import {Blueprints} from "./components/requester/console/blueprint/Blueprints";
import {Batches} from "./components/requester/console/batch/Batches";
import {Reports} from "./components/requester/console/report/Reports";
import {BlueprintCreate} from "./components/requester/console/blueprint/create/BlueprintCreate";
import {BlueprintTable} from "./components/requester/console/blueprint/table/BlueprintTable";
import {BlueprintDesign} from "./components/requester/console/blueprint/create/BlueprintDesign";
import {BlueprintPreview} from "./components/requester/console/blueprint/create/BlueprintPreview";
import {Task} from "./components/task/Task";
import {ReportTable} from "./components/requester/console/report/table/ReportTable";
//  Test: Image Task Templates
import {ImageTask} from "./components/task/ImageTask";
import {ImageRate} from "./components/task/imagerate/ImageRate";
import {ImageCompare} from "./components/task/imagecompare/ImageCompare";
import {ImagePolygon} from "./components/task/imagepolygon/ImagePolygon";
//  Test: PCD Task Templates
import {PCDTask} from "./components/task/PCDTask";
import {PCDViewAndSegment} from "./components/task/pcdViewAndSegment/PCDViewAndSegment";

//  William Added Protected Route:
import {ProtectedRouteProps, ProtectedRoute} from "./ProtectedRoute";
//import {authenticationService} from "./App";

import {BatchTable} from "./components/requester/console/batch/table/BatchTable";
import {BatchCreate} from "./components/requester/console/batch/create/BatchCreate";
import {BatchAttributes} from "./components/requester/console/batch/create/BatchAttributes";
import {BatchPublish} from "./components/requester/console/batch/create/BatchPublish";
import {ReportProgress} from "./components/requester/console/report/progress/ReportProgress";


export function MainRoutes() {
    //  William Added Protected Route:
    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
        //isAuthenticated: authenticationService.isAuthenticated(),
        authenticationPath: "/signin",
    };
    //  Modified Protected Route for Paths that require Authentication
    return (
        <>
            <Routes>
                {/* Default Route: Go to Project List Page. When Login */}
                <Route
                    path='/'
                    element={
                        <ProtectedRoute
                            {...defaultProtectedRouteProps}
                            outlet={<ProjectTable/>}
                        />
                    }/>

                {/* Original: Default Route */}
                {/*<Route path='/' element={<SignIn/>}/>*/}

                {/* Sign In/Up Page */}
                <Route path='/signin' element={<SignIn/>}/>
                <Route path='/signup' element={<SignUp/>}/>

                {/*Under Test*/}
                {/*<Route path='/image' element={ <ProtectedRoute {...defaultProtectedRouteProps} outlet={<ImageTask/>} /> }>*/}
                {/*    <Route path='' element={<ImageRate />} />*/}
                {/*    <Route path='rate' element={<ImageRate />} />*/}
                {/*    <Route path='compare' element={<ImageCompare />} />*/}
                {/*    <Route path='polygon' element={<ImagePolygon />} />*/}
                {/*</Route>*/}

                {/*Under Template Image Test*/}
                <Route path='/image' element={<ImageTask />}>
                    <Route path='' element={<ImageRate />} />
                    <Route path='rate' element={<ImageRate />} />
                    <Route path='compare' element={<ImageCompare />} />
                    <Route path='polygon' element={<ImagePolygon />} />
                </Route>

                {/*Under Template Point-Cloud (PCD) Test*/}
                <Route path='/pcd' element={<PCDTask />}>
                    <Route path='' element={<PCDViewAndSegment />} />
                    <Route path='pcd' element={<PCDViewAndSegment />} />
                </Route>

                {/* Password Change Page: Note: Only can change password after logged in */}
                {/*<Route path='/changepw' element={<ChangePasswd/>} />*/}
                <Route
                    path='/changepw'
                    element={
                        <ProtectedRoute
                            {...defaultProtectedRouteProps}
                            outlet={<ChangePasswd/>}
                        />
                }/>

                {/* Project List Page: Without Protected Route */}
                {/*<Route path='/projects' element={<ProjectTable/>} />*/}

                {/* Project List Page: With Protected Route */}
                <Route
                    path='/projects'
                    element={
                        <ProtectedRoute
                            {...defaultProtectedRouteProps}
                            outlet={<ProjectTable/>}
                        />
                }/>

                {/* All Protected Route Pages: Project, Blueprints, reports, etc */}
                <Route path='/project/:projectId' element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<Console/>}
                    />
                }>
                    <Route path='' element={
                        <ProtectedRoute
                            {...defaultProtectedRouteProps}
                            outlet={<Navigate replace to='blueprints'/>}
                        />
                    }/>
                    <Route path='blueprints' element={
                        <ProtectedRoute
                            {...defaultProtectedRouteProps}
                            outlet={<Blueprints/>}
                        />
                    }>
                        <Route path='' element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={<BlueprintTable/>}
                            />
                        }/>
                        <Route path='create' element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={<BlueprintCreate/>}
                            />
                        }/>
                        <Route path='design' element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={<BlueprintDesign/>}
                            />
                        }/>
                        <Route path='preview' element={
                            <ProtectedRoute
                                {...defaultProtectedRouteProps}
                                outlet={<BlueprintPreview/>}
                            />
                        }/>

                    </Route>
                    <Route path='batches' element={ <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Batches/>} /> }>
                        <Route path='' element={<BatchTable />} />
                        <Route path='create' element={<BatchCreate />} />
                        <Route path='attributes' element={<BatchAttributes />} />
                        <Route path='publish' element={<BatchPublish />} />
                    </Route>
                    <Route path='reports' element={ <ProtectedRoute {...defaultProtectedRouteProps} outlet={<Reports/>} />  }>
                        <Route path='' element={<ReportTable />} />
                        <Route path=':batchId' element={<ReportProgress/>}></Route>
                    </Route>


                </Route>

                <Route path='/task/:projectId/:taskBatchId' element={
                    <ProtectedRoute
                        {...defaultProtectedRouteProps}
                        outlet={<Task/>}
                    />
                }/>
            </Routes>
        </>
    )

    //  Original Kotaro's Code:
    // return (
    //     <>
    //         <Routes>
    //             <Route path='/' element={<SignIn />} />
    //             <Route path='/signin' element={<SignIn />} />
    //             <Route path='/signup' element={<SignUp />} />
    //             <Route path='/projects' element={<ProjectTable />} />
    //             <Route path='/project/:projectId' element={<Console />}>
    //                 <Route path='' element={<Navigate replace to='blueprints' />} />
    //                 <Route path='blueprints' element={<Blueprints />}>
    //                     <Route path='' element={<BlueprintTable />} />
    //                     <Route path='create' element={<BlueprintCreate />} />
    //                     <Route path='design' element={<BlueprintDesign />} />
    //                     <Route path='preview' element={<BlueprintPreview />} />
    //                 </Route>
    //                 <Route path='batches' element={<Batches />} />
    //                 <Route path='reports' element={<Reports />} />
    //             </Route>
    //             <Route path='/task/:projectId/:taskBatchId' element={<Task />} />
    //         </Routes>
    //     </>
    // )
}

